import React from "react";

const Hint = ({ hint, hintStyles }) => {
  return (
    <span
      style={{
        ...hintStyles,
        fontWeight: 400,
        fontSize: "12px",
        marginBottom: "4px",
      }}
    >
      {hint}
    </span>
  );
};

export default Hint;
