import React from "react";
import { Container, ContainerItem, Input } from "../../components";
import { capitalizeString, units } from "../../utils/utils";

const Metric = ({ metric, index, isDoubleMetric }) => {
  return (
    <Container padding="0" column width="345px">
      <ContainerItem padding="0 0 8px 0">
        <Input
          readOnly
          label={isDoubleMetric ? `Metric ${index + 1}` : "Metric"}
          value={metric.name}
        />
      </ContainerItem>
      <ContainerItem padding="8px 0">
        <Input
          readOnly
          label="Is there a per day goal? "
          value={capitalizeString(metric.hasPerDayGoal.toString())}
        />
      </ContainerItem>
      <ContainerItem padding="8px 0">
        <Input
          readOnly
          label="Is there a per week goal? "
          value={capitalizeString(metric.hasWeeklyGoal.toString())}
        />
      </ContainerItem>
      {metric.hasPerDayGoal | metric.hasWeeklyGoal ? (
        <>
          <ContainerItem padding="8px 0">
            <Input
              readOnly
              label={metric.hasWeeklyGoal ? "Per Week goal" : "Per day goal "}
              value={
                metric.name === "Sleep"
                  ? `${Math.floor(metric.perDayGoal / 60)}:${
                      metric.perDayGoal % 60
                    } ${units(
                      metric.name.toLowerCase().replace(" ", "_"),
                      metric.hasPerDayGoal
                    )}`
                  : `${metric.perDayGoal} ${units(
                      metric.name.toLowerCase().replace(" ", "_"),
                      metric.hasPerDayGoal
                    )}`
              }
            />
          </ContainerItem>
          <ContainerItem padding="8px 0">
            <Input
              readOnly
              label="Goal Description "
              value={metric.goalDescription}
            />
          </ContainerItem>
        </>
      ) : null}
      <ContainerItem padding="8px 0">
        <Input
          readOnly
          label="Is there a prize? "
          value={capitalizeString(metric.hasPrize.toString())}
        />
      </ContainerItem>
      {metric.hasPrize ? (
        <ContainerItem padding="8px 0">
          <Input
            readOnly
            label="Prize description "
            value={metric.prizeDescription}
          />
        </ContainerItem>
      ) : null}
    </Container>
  );
};

export default Metric;
