import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentBrand } from "../../redux/auth/authSlice";
import {
  selectBrands,
  updateBrand,
} from "../Brand/tabs/manage-brand/manageBrandSlice";
import {
  Button,
  Container,
  ContainerItem,
  Text,
  ToggleButton,
} from "../../components";
import { api, apiEndpoints } from "../../services/api";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";

const instagramSettings = {
  AUTOPULL: "autoPull",
  AUTOPUBLISH: "autoPublish",
};

const InstagramSettings = () => {
  const currentBrand = useSelector(selectCurrentBrand);
  const brands = useSelector(selectBrands);
  const [brand, setBrand] = useState();
  const [autoPull, setAutoPull] = useState(false);
  const [autoPublish, setAutoPublish] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [buttonText, setButtonText] = useState("Sync Instagram Posts Now");
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const fbClientID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  const fbClientSecret = process.env.REACT_APP_FACEBOOK_CLIENT_SECRET;

  const updateIGSettings = async (data) => {
    console.log(data, "IG DATA");
    if (data) {
      try {
        const response = await api.post(apiEndpoints.igSettings, {
          group_id: brand.group_id,
          ...data,
        });
        return response;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        await api.put(apiEndpoints.igSettings, {
          group_id: brand.group_id,
          publish_posts_to_ig: autoPublish,
          pull_ig_posts: autoPull,
        });

        const newBrand = {
          ...brand,
          pull_ig_posts: autoPull,
          publish_posts_to_ig: autoPublish,
        };

        setBrand(newBrand);
        dispatch(
          showSnackbar({
            message: "Settings updated successfully",
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
        dispatch(updateBrand(newBrand));
      } catch (error) {
        setAutoPublish(brand?.publish_posts_to_ig);
        setAutoPull(brand?.pull_ig_posts);

        dispatch(
          showSnackbar({
            message: typeof error === "string" ? error : JSON.stringify(error),
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const igIntegration = () => {
    if (!brand?.ig_status) {
      window.FB.login(
        (auth) => {
          console.log("callback response", auth);
          if (auth.status === "connected") {
            const access_token = auth.authResponse.accessToken;
            const data = {};

            window.FB.api(
              `/oauth/access_token?grant_type=fb_exchange_token&client_id=${fbClientID}&client_secret=${fbClientSecret}&fb_exchange_token=${access_token}`,
              "GET",
              {},
              function (longLivedAuth) {
                console.log("longLivedAuth", longLivedAuth);
                data.ig_access_token = longLivedAuth.access_token;
                const expiry = longLivedAuth.expires_in;

                const date = new Date();
                date.setUTCSeconds(date.getUTCSeconds() + expiry);

                data.ig_access_token_expiry_time = date.toISOString();
                window.FB.api("/me/accounts", "GET", {}, function (profile) {
                  console.log("response", profile);
                  data.ig_linked_fb_id = profile.data[0].id;

                  window.FB.api(
                    `/${data.ig_linked_fb_id}?fields=instagram_business_account`,
                    "GET",
                    {},
                    function (ig) {
                      console.log("ig", ig);
                      data.ig_id = ig.instagram_business_account.id;

                      updateIGSettings(data)
                        .then((brand) => {
                          setBrand(brand);
                          setAutoPublish(brand.publish_posts_to_ig);
                          setAutoPull(brand.pull_ig_posts);
                          dispatch(updateBrand(brand));
                          dispatch(
                            showSnackbar({
                              message: "Instagram linked successfully",
                              type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
                            })
                          );
                        })
                        .catch((error) => {
                          dispatch(
                            showSnackbar({
                              message:
                                typeof error === "string"
                                  ? error
                                  : JSON.stringify(error),
                              type: SNACKBAR_TYPES.SNACKBAR_ERROR,
                            })
                          );
                        });
                    }
                  );
                });
              }
            );
          } else {
            dispatch(
              showSnackbar({
                message: "Unable to link Instagram",
                type: SNACKBAR_TYPES.SNACKBAR_ERROR,
              })
            );
          }
        },
        {
          scope:
            "public_profile,email,instagram_basic,pages_show_list,business_management,instagram_content_publish,pages_read_engagement",
        }
      );
    }
  };

  const syncInsytagramPosts = () => {
    if (brand?.ig_status) {
      setSyncing((prev) => true);
      dispatch(
        showSnackbar({
          message: "Syncing Instagram posts",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      api
        .get(apiEndpoints.igSync(brand.group_id))
        .then(() => {
          dispatch(
            showSnackbar({
              message: "Synced Instagram posts successfully",
              type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
            })
          );
          setButtonText("Synced Instagram posts successfully");
        })
        .catch((error) => {
          setError(true);
          dispatch(
            showSnackbar({
              message:
                typeof error === "string" ? error : JSON.stringify(error),
              type: SNACKBAR_TYPES.SNACKBAR_ERROR,
            })
          );
          setButtonText("Unable to sync Instagram posts, Try again later");
        })
        .finally(() => {
          setSyncing((prev) => false);
        });
    }
  };

  useEffect(() => {
    if (currentBrand) {
      const b = brands.find(
        (b) => b.group_id === currentBrand && b.group_type === "BRAND"
      );
      if (b) {
        setBrand(b);
        setAutoPublish(b.publish_posts_to_ig);
        setAutoPull(b.pull_ig_posts);
      }
    }
  }, [currentBrand]);

  useEffect(() => {
    if (
      brand &&
      (brand?.publish_posts_to_ig !== autoPublish ||
        brand?.pull_ig_posts !== autoPull)
    ) {
      updateIGSettings();
    }
  }, [autoPublish, autoPull]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setButtonText("Sync Instagram Posts Now");
        setError(false);
      }, 5000);
    }
  }, [error]);

  if (brand?.ig_status) {
    return (
      <Container width="100%" margin="24px 0 0 0" column>
        <ContainerItem padding={0}>
          <Text
            content="Instagram Settings"
            sx={{ fontSize: "24px", fontWeight: 700 }}
          />
        </ContainerItem>
        <ContainerItem margin="32px 0 0 0">
          <ToggleButton
            name={instagramSettings.AUTOPULL}
            label="Auto Pull New Instagram Posts as Community Posts"
            checked={autoPull}
            key={instagramSettings.AUTOPULL}
            onChange={(checked) => {
              setAutoPull(checked);
            }}
          />
        </ContainerItem>
        <ContainerItem>
          <ToggleButton
            name={instagramSettings.AUTOPUBLISH}
            label="Add Community Posts to Instagram"
            checked={autoPublish}
            key={instagramSettings.AUTOPUBLISH}
            onChange={(checked) => {
              setAutoPublish(checked);
            }}
          />
        </ContainerItem>
        <ContainerItem>
          <Button
            label={buttonText}
            variant="outlined"
            disabled={error || syncing}
            isLoading={syncing}
            styles={{ width: "auto", padding: "2px 12px", margin: "auto" }}
            startIcon={
              <img
                width="20px"
                height="20px"
                src={require("../../assets/instagram.jpg")}
                alt="Google"
              />
            }
            onClick={() => {
              if (!error || !syncing) {
                syncInsytagramPosts();
              }
            }}
          />
        </ContainerItem>
      </Container>
    );
  }

  return (
    <Container width="100%" margin="40px 0 16px 0">
      <ContainerItem width="inherit">
        <Text
          content="Link Instagram Account"
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            justifyContent: "center",
          }}
        />
      </ContainerItem>
      <ContainerItem
        width="inherit"
        margin="40px 0 0 0"
        align="center"
        flex
        column
      >
        <Text
          content="Linking your Instagram account helps you to push posts from Admin Portal as a Instagram post "
          sx={{
            fontSize: "20px",
            width: "600px",
            fontWeight: 400,
            justifyContent: "center",
            textAlign: "center",
          }}
        />
        <Text
          content="It will also enable you to pull new Instagram posts as community posts Fitlvl users can see in the news feed "
          sx={{
            fontSize: "20px",
            width: "600px",
            marginTop: "16px",
            fontWeight: 400,
            textAlign: "center",
            justifyContent: "center",
          }}
        />
      </ContainerItem>

      <ContainerItem margin="40px" justify="center" width="inherit">
        <Button
          label="Link your Instagram"
          variant="outlined"
          styles={{ width: "240px", padding: "4px 12px", margin: "auto" }}
          startIcon={
            <img
              width="20px"
              height="20px"
              src={require("../../assets/instagram.jpg")}
              alt="Google"
            />
          }
          onClick={igIntegration}
        />
      </ContainerItem>
    </Container>
  );
};

export default React.memo(InstagramSettings);
