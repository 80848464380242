import { Divider as DividerContainer } from "@mui/material";
import * as React from "react";
import { palette } from "../../theme";

const Divider = ({ vertical, margin, color }) => {
  const orientation = vertical ? "vertical" : "horizontal";
  const width = vertical ? "1px" : "100%";

  return (
    <DividerContainer
      orientation={orientation}
      sx={{
        m: margin ? margin : "0",
        width: width,
        borderColor: color ? color : palette.border.lightGrey,
      }}
      variant={vertical ? "inset" : "fullWidth"}
    />
  );
};

export default Divider;
