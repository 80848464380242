import { FormControl, Menu, MenuItem, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { palette } from "../../theme";
import Label from "./label";
import Hint from "./hint";
import Text from "./text";

const Input = ({
  required,
  name,
  label,
  labelColor,
  labelFontSize,
  labelFontWeight,
  value,
  onChange,
  type,
  endIcon,
  sx,
  multiline,
  minRows,
  hint,
  hintStyles,
  onBlur,
  error,
  characterLimit,
  readOnly,
  disabled,
  startIcon,
  ...rest
}) => {
  const [limit, setLimit] = useState(characterLimit - value?.length);

  return (
    <FormControl error={error} fullWidth>
      {label ? (
        <Label
          required={required}
          label={label}
          labelColor={labelColor}
          fontSize={labelFontSize}
          fontWeight={labelFontWeight}
        />
      ) : null}
      {readOnly ? (
        <Text
          content={!value || value === "" ? "--" : value}
          sx={{ fontWeight: 400, fontSize: "16px", color: palette.text.black }}
        />
      ) : (
        <>
          {hint ? <Hint hint={hint} hintStyles={hintStyles} /> : null}
          <OutlinedInput
            disabled={disabled}
            name={name}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.primary.main,
                border: `1px solid ${palette.primary.main}`,
              },
              backgroundColor: palette.background.white,
              color: labelColor,
              height: multiline ? "auto" : "47px",
              width: "100%",
              borderRadius: "10px",
              ...sx,
            }}
            multiline={multiline}
            minRows={minRows ?? 3}
            type={type}
            value={value}
            endAdornment={endIcon}
            startAdornment={startIcon}
            onPaste={(e) => {
              if (characterLimit) {
                const pastedText = e.clipboardData.getData("text/plain");
                if (pastedText.length + value.length > characterLimit) {
                  // Prevent the default paste behavior
                  e.preventDefault();
                }
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              if (
                characterLimit &&
                (limit > 0 || e.target.value.length) <= characterLimit
              ) {
                setLimit(characterLimit - String(e.target.value).length);
                onChange(e);
              } else if (characterLimit === undefined) {
                onChange(e);
              }
            }}
            onBlur={onBlur}
            {...rest}
          />
          {characterLimit ? (
            <div
              style={{
                position: "relative",
                textAlign: "right",
                top: "-32px",
                right: "12px",
                fontSize: "12px",
                fontWeight: "400",
                color: palette.text.purple,
              }}
            >
              characters remaining: {limit}
            </div>
          ) : null}
          {error ? (
            <Text
              content={error}
              sx={{
                fontWeight: 400,
                fontSize: "10px",
                color: palette.text.error,
                marginTop: "8px",
              }}
            />
          ) : null}
        </>
      )}
    </FormControl>
  );
};

export default React.memo(Input);
