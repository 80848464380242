import { createSlice } from "@reduxjs/toolkit";
import { SNACKBAR_TYPES } from "./snackbarTypes";

const initialState = {
  snackbar: { message: "", type: "success", open: false },
};

const snackBarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.snackbar = {
        message:
          typeof action.payload.message === "string"
            ? action.payload.message
            : JSON.stringify(action.payload.message),
        type: action.payload.type,
        open: true,
      };
    },
    clearSnackbar: (state) => {
      state.snackbar = initialState;
    },
  },
});

export const { showSnackbar, clearSnackbar } = snackBarSlice.actions;

export const selectSnackbarMessage = (state) => state.snackbar.message;
export const selectSnackbarType = (state) => state.snackbar.type;
export const selectSnackbarOpen = (state) => state.snackbar.open;
export const selectSnackbar = (state) => state.snackbar.snackbar;

export default snackBarSlice.reducer;
