import React from "react";
import { useSearchParams } from "react-router-dom";

import { Tabbedpage } from "../../components";
import CreatePost from "./tabs/createPost";
import ManagePosts from "./tabs/managePosts";
import CreatePostV2 from "./tabs/createPostV2";
import { useSelector } from "react-redux";
import { selectPostSelected } from "./postSlice";

const Posts = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const selectedPost = useSelector(selectPostSelected);
  const isEditing = Boolean(selectedPost);

  const tabs = [
    {
      title: `${isEditing ? "Edit" : "Create a"} Post`,
      id: "create",
      component: CreatePostV2,
    },
    { title: "Manage Posts", id: "manage", component: ManagePosts },
  ];
  return (
    <div>
      <Tabbedpage
        activeTab={tab === "create" || tab === null ? 0 : 1}
        tabs={tabs}
      />
    </div>
  );
};

export default Posts;
