import React from "react";
import { TableFooter as MuiTableFooter, TablePagination } from "@mui/material";

const TableFooter = ({
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  totalCount,
  onPageChange,
  rowsPerPageOptions,
}) => {
  return (
    <MuiTableFooter sx={{ width: "max-content", display: "block" }}>
      <TablePagination
        count={totalCount ?? rows.length}
        sx={{ width: "100%" }}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={(event, newPage) => {
          handleChangePage(event, newPage);
          if (onPageChange) {
            if (newPage * rowsPerPage >= rows.length) {
              onPageChange(newPage, rowsPerPage);
            }
          }
        }}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MuiTableFooter>
  );
};

export default TableFooter;
