import { Box } from "@mui/material";
import React from "react";
import Text from "../common/text";
import { palette } from "../../theme";

const CardHeader = ({
  backgroundColor,
  title,
  titleColor,
  subtitle,
  actions,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Text
        content={title}
        sx={{
          fontWeight: "600",
          fontSize: "20px",
          padding: "8px 24px",
          color: titleColor ?? palette.text.black,
        }}
      />
      <>
        {subtitle && (
          <Text
            content={subtitle}
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              padding: "8px 24px",
              color: titleColor ?? palette.text.black,
            }}
          />
        )}
        {actions && actions}
      </>
    </Box>
  );
};

export default CardHeader;
