import React from "react";
import { Chip as MuiChip } from "@mui/material";

import { palette } from "../../theme";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";

const Chip = ({ item, onDelete }) => {
  return (
    <MuiChip
      sx={{
        marginRight: "6px",
        width: "fit-content",
        backgroundColor: palette.background.lightBlue,
        textTransform: "uppercase",
        fontWeight: "700",
        fontSize: "10px",
        color: palette.primary.main,
        my: "6px",
      }}
      key={item.value}
      tabIndex={-1}
      label={item.label}
      deleteIcon={<CloseIcon />}
      onDelete={onDelete}
    />
  );
};

export default Chip;
