import React from "react";
import { Button as MuiButton, Box } from "@mui/material";
import { palette } from "../../theme";
import Loader from "./loader";
import Text from "./text";

const Button = ({
  disabled,
  variant,
  onClick,
  label,
  styles,
  isLoading,
  startIcon,
  endIcon,
  type,
  children,
  required,
}) => {
  const buttonStyles = {
    contained: {
      backgroundColor: palette.primary.main,
      color: palette.text.white,
    },
    outlined: {
      color: palette.primary.main,
      backgroundColor: palette.text.white,
    },
  };
  return (
    <Box sx={{ display: "flex" }}>
      <MuiButton
        variant={variant}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
        sx={{
          color: palette.secondary.main,
          ...buttonStyles[variant],
          width: "100%",
          height: "44px",
          display: "flex",
          alignItems: "center",
          borderRadius: "30px",
          paddingY: isLoading ? "2px" : 0,
          ...styles,
        }}
        disableRipple={true}
        disabled={disabled}
        type={type}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {isLoading ? (
          <Loader />
        ) : children ? (
          children
        ) : (
          <Text
            sx={{
              fontWeight: styles?.["fontWeight"] ?? 700,
              fontSize: styles?.["fontSize"] ?? "16px",
              textTransform: "capitalize",
            }}
            content={label}
          />
        )}
      </MuiButton>
      {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
    </Box>
  );
};

export default Button;
