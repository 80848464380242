import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OutlinedInput } from "@mui/material";
import { palette } from "../../theme";
import Label from "./label";
import Text from "./text";
import moment from "moment";

const InputDate = ({
  required,
  name,
  minDate,
  maxDate,
  handleDate,
  value,
  label,
  readOnly,
  labelStyles,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleDate(date);
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <div>
      {label ? (
        <Label {...labelStyles} label={label} required={required} />
      ) : null}
      {readOnly ? (
        <Text
          content={moment(value).format("MMM D, YYYY")}
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            color: palette.text.black,
            marginTop: "4px",
          }}
        />
      ) : (
        <DatePicker
          name={name}
          selected={selectedDate ? moment(selectedDate).toDate() : null}
          onChange={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat="EEEE, MMMM d, yyyy"
          customInput={
            <OutlinedInput
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: palette.primary.main,
                  border: `1px solid ${palette.primary.main}`,
                },
                backgroundColor: palette.background.white,
                color: palette.text.black,
                height: "47px",
                width: "100%",
                borderRadius: "10px",
                marginTop: "8px",
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default React.memo(InputDate);
