import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUser,
  selectCurrentBrand,
  selectIsBrandAdmin,
  setCurrentBrand,
  setIsBrandAdmin,
} from "./authSlice";

import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { getFromLocalStorage, isTokenExpired, logout } from "../../utils/utils";
import {
  fetchUsers,
  selectManageUsersIsLoading,
} from "../../pages/manage-users/manageUsersSlice";
import {
  fetchCurrentChallenges,
  fetchDeletedChallenges,
  fetchPastChallenges,
  selectIsCurrentChallengesLoading,
  selectIsDeletedChallengesLoading,
  selectIsPastChallengesLoading,
} from "../../pages/challenges/tabs/manage-challenges/manageChallengesSlice";
import { Loader } from "../../components";
import { fetchPosts, selectIsPostsLoading } from "../../pages/posts/postSlice";
import {
  fetchBrands,
  selectBrands,
  selectIsBrandsLoading,
} from "../../pages/Brand/tabs/manage-brand/manageBrandSlice";

const RequireAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const access = getFromLocalStorage("access");
  const refresh = getFromLocalStorage("refresh");
  const accessExpiry = getFromLocalStorage("accessExpiry");
  const refreshExpiry = getFromLocalStorage("refreshExpiry");
  const userType = getFromLocalStorage("userType");
  const userName = getFromLocalStorage("userName");
  const isLoggedIn = getFromLocalStorage("isLoggedIn");
  const currentBrand =
    useSelector(selectCurrentBrand) ?? getFromLocalStorage("currentBrand");
  const tokenExpired = isTokenExpired(refresh);
  const brands = useSelector(selectBrands);

  const isUsersLoading = useSelector(selectManageUsersIsLoading);
  const isCurrentChallengesLoading = useSelector(
    selectIsCurrentChallengesLoading
  );
  const isPastChallengesLoading = useSelector(selectIsPastChallengesLoading);
  const isDeletedChallengesLoading = useSelector(
    selectIsDeletedChallengesLoading
  );
  const isBrandsLoading = useSelector(selectIsBrandsLoading);
  const isPostsLoading = useSelector(selectIsPostsLoading);

  const [apiCallsComplete, setApiCallsComplete] = useState(false);
  const isBrandAdmin = userType === "brand_admin";

  const isLoading =
    isUsersLoading ||
    isCurrentChallengesLoading ||
    isPastChallengesLoading ||
    isDeletedChallengesLoading ||
    isBrandsLoading;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setIsBrandAdmin(isBrandAdmin));
      if (currentBrand && isBrandAdmin) {
        dispatch(setCurrentBrand(currentBrand));
      }
      if (isLoggedIn) {
        try {
          dispatch(
            saveUser({
              access,
              refresh,
              refreshExpiry,
              accessExpiry,
              userName,
              userType,
              isLoggedIn,
            })
          );

          if (isBrandAdmin) {
            if (!brands.length) {
              await dispatch(fetchBrands());
            }
            if (currentBrand) {
              await Promise.all([
                dispatch(fetchCurrentChallenges(isBrandAdmin && currentBrand)),
                dispatch(fetchPastChallenges(isBrandAdmin && currentBrand)),
                dispatch(fetchDeletedChallenges(isBrandAdmin && currentBrand)),
                // dispatch(fetchPosts()),
              ]);
            }
          } else {
            await Promise.all([
              dispatch(fetchBrands()),
              // dispatch(fetchUsers(isBrandAdmin && currentBrand)),
              dispatch(fetchCurrentChallenges(isBrandAdmin && currentBrand)),
              dispatch(fetchPastChallenges(isBrandAdmin && currentBrand)),
              dispatch(fetchDeletedChallenges(isBrandAdmin && currentBrand)),
              // dispatch(fetchPosts()),
            ]);
          }

          // All API calls are completed at this point
          setApiCallsComplete(true);
        } catch (error) {
          // Handle errors if needed
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [dispatch, isLoggedIn, currentBrand]);

  useEffect(() => {
    if (!refresh || isLoggedIn === "false" || !isLoggedIn) {
      navigate("/", {
        state: { redirectTo: `${location.pathname}${location.search}` },
      });
    }
  }, [refresh, isLoggedIn]);

  if (refresh && tokenExpired) {
    logout();
  }

  return isLoading || !apiCallsComplete ? (
    <div
      style={{
        position: "fixed",
        top: "49%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Loader />
    </div>
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default RequireAuth;
