import { Box, Card as CardContainer } from "@mui/material";
import React from "react";
import CardHeader from "./header";
import CardContent from "./body";

const Card = ({
  height,
  headerColor,
  title,
  titleColor,
  children,
  subtitle,
  actions,
  contentPadding,
  width,
}) => {
  const cardHeight = height ? height : "auto";
  return (
    <CardContainer
      sx={{
        borderRadius: "10px",
        maxHeight: cardHeight,
        width: width ?? "986px",
      }}
    >
      <CardHeader
        backgroundColor={headerColor}
        title={title}
        titleColor={titleColor}
        subtitle={subtitle}
        actions={actions}
      />
      <CardContent height={cardHeight} contentPadding={contentPadding}>
        {children}
      </CardContent>
    </CardContainer>
  );
};

export default Card;
