import axios from "axios";
import {
  getFromLocalStorage,
  isTokenExpired,
  logout,
  saveToLocalStorage,
} from "../../utils/utils";
const { CancelToken } = axios;

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
const REFRESH_TOKEN_URL = `${API_BASE_URL}/user/login/refresh/`;
const cancelTokenSource = CancelToken.source();

class ApiService {
  constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.api.interceptors.request.use(async (config) => {
      let bearerToken = await getFromLocalStorage("access");
      const tokenExpired = isTokenExpired(bearerToken);

      if (tokenExpired) {
        try {
          bearerToken = await this.refreshAccessToken();
        } catch (error) {
          logout();
          // logout();
          cancelTokenSource.cancel(
            "Request canceled due to token refresh error"
          ); // Cancel the API call
        }
      }
      if (bearerToken) {
        config.headers["Authorization"] = `Bearer ${bearerToken}`;
      }
      return config;
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          401 === error?.response?.status ||
          403 === error?.response?.status
        ) {
          logout();
        }
        if (error.response?.status === 500) {
          throw new Error("Unknown Error. Please try again later");
        }
        throw error.response.data;
      }
    );
  }

  async get(endpoint) {
    try {
      const response = await this.api.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async post(endpoint, data, config, dispatch) {
    try {
      const response = await this.api.post(endpoint, data, { ...config });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async put(endpoint, data, config, dispatch) {
    try {
      const response = await this.api.put(endpoint, data, { ...config });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(endpoint, body, dispatch) {
    try {
      const response = await this.api.delete(endpoint, { data: body });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  getHeaders() {
    const token = this.state.user.access;
    return { Authorization: `Bearer ${token}` };
  }

  async refreshAccessToken() {
    try {
      const refreshToken = getFromLocalStorage("refresh");
      if (!refreshToken) {
        throw new Error("No refresh token found");
      }
      const response = await axios.post(REFRESH_TOKEN_URL, {
        refresh: refreshToken,
      });
      if (response.status === 401) {
        logout();
      } else {
        const { access } = response.data;
        saveToLocalStorage("access", access);
        return access;
      }
    } catch (error) {
      // logout();

      throw error;
    }
  }
}

const api = new ApiService();
export default api;
