export const navOptions = [
  {
    id: "dashboard",
    label: "Home",
    to: "/dashboard",
  },
  {
    id: "challenges",
    label: "Challenges",
    to: "/challenges?tab=create",
  },
  {
    id: "brand",
    label: "Brands",
    to: "/brand?tab=create",
  },
  {
    id: "posts",
    label: "Posts",
    to: "/posts",
  },
  {
    id: "announcements",
    label: "Announcements",
    to: "/announcements",
  },
  {
    id: "notifications",
    label: "Notifications",
    to: "/notifications",
  },
  {
    id: "manage-users",
    label: "Manage Users",
    to: "/manage-users",
  },
  {
    id: "firebase-analytics",
    label: "Firebase Analytics",
    to: "/firebase-analytics",
  },
  {
    id: "instagram-settings",
    label: "Instagram Settings",
    to: "/instagram-settings",
  },
];
