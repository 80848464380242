import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ref, onValue } from "firebase/database";
import { Reorder } from "framer-motion";
import QRCode from "react-qr-code";

import "./carousel.css";
import Header from "./header";
import { Avatar, Button, Input, Loader, Text } from "../../components";
import { database } from "../../services/firebase";
import { palette } from "../../theme";
import RankedCard from "./rankedCard";
import { api, apiEndpoints } from "../../services/api";

const regex =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

const SchnucksLeaderboard = () => {
  const xl = useMediaQuery("(min-width:1660px)");
  const { challengeId } = useParams();
  const [challenge, setChallenge] = useState();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [animationDuration, setAnimationDuration] = useState(0);
  const [stores, setStores] = useState([]); // [store1, store2, store3
  const [selectedStore, setSelectedStore] = useState(null); // store]{}
  const [searchInput, setSearchInput] = useState("");
  const has2Participants = leaderboardData.length > 1;
  const has3Participants = leaderboardData.length > 2;

  const metric = challenge?.challenge_metrics
    ?.split(",")[0]
    .replace("_", "")
    .toUpperCase();

  const fetchChallenge = async (id) => {
    setIsLoading((state) => !state);
    const response = await fetch(
      `${API_BASE_URL}/challenges/leaderboard?challenge_id=${id}`
    );

    const data = await response.json();

    setChallenge(data);
    setIsLoading((state) => !state);
  };

  const activateListener = () => {
    console.log("activating listener");
    const metrics = challenge.challenge_metrics.split(",");

    if (challenge) {
      const leaderboardRef = ref(
        database,
        `challenges/challenge_${challenge.challenge_id}_${metrics[0]}`
      );

      onValue(leaderboardRef, (snapshot) => {
        let data = snapshot.val();
        console.log("Value changed");
        if (data) {
          data = data.data;
          const rawData = Object.keys(data).map((k) => {
            const user_id = k.split("_")[1];
            return {
              user_id,
              ...data[k],
            };
          });

          setLeaderboardData(rawData.sort((a, b) => a.rank - b.rank));
        } else {
          setLeaderboardData([]);
        }
        setIsLoading(false);
      });
    }
  };

  const fetchStores = async (ch) => {
    const response = await fetch(
      `${API_BASE_URL}/groups/getGroupStores?group_id=${ch.group.group_id}`
    );
    const data = await response.json();
    // setStores(data.data.data);
    console.log(data);
    setStores(data);
  };

  const fetchStoreParticipants = async (store) => {
    const response = await fetch(
      `${API_BASE_URL}/groups/storeParticipants?challenge_id=${challengeId}&store_id=${store.store_internal_id}`
    );
    const data = await response.json();
    setLeaderboardData(data);
  };

  useEffect(() => {
    if (challengeId) {
      // fetchData();
      fetchChallenge(challengeId);
    }
  }, [challengeId]);

  useEffect(() => {
    if (challenge) {
      fetchStores(challenge);

      // activateListener();
    }
  }, [challenge]);

  useEffect(() => {
    if (selectedStore) {
      console.log("Selected store", selectedStore);
      fetchStoreParticipants(selectedStore);
    }
  }, [selectedStore]);

  useEffect(() => {
    // Calculate the animation duration based on the number of items in the list
    const numItems = leaderboardData.slice(3, leaderboardData.length).length;
    const animationSpeed = 1; // Adjust the desired speed factor

    const calculatedDuration = numItems * animationSpeed;
    setAnimationDuration(calculatedDuration + "s");
  }, [leaderboardData]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        background: "#F8F6EE",
        height: "100vh",
      }}
    >
      <Header
        logo={challenge?.group?.group_logo}
        title={challenge?.challenge_name}
        groupName={challenge?.group.group_name}
        startDate={challenge?.start_date}
        endDate={challenge?.end_date}
      />
      {selectedStore ? (
        <Box>
          <Box
            onClick={() => setSelectedStore(null)}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text
              content={selectedStore.store_name}
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                marginTop: "20px",
                color: palette.text.hotPink,
              }}
            />
            <Text
              content="Change Location"
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            />
          </Box>
          {leaderboardData.length ? (
            <>
              <Box
                sx={{
                  height: "84vh",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: "20px",
                  overflowY: "hidden",
                }}
              >
                <Reorder.Group
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginBottom: "36px",
                    marginTop: "200px",
                    minWidth: "500px",
                    width: "62%",
                    maxWidth: "860px",
                    marginLeft: "-22px",
                  }}
                  values={leaderboardData}
                  onReorder={setLeaderboardData}
                >
                  <Reorder.Item
                    style={{ width: "40%" }}
                    as="div"
                    key={has2Participants ? leaderboardData[1].user_id : 2}
                    value={has2Participants ? leaderboardData[1].rank : 0}
                  >
                    <RankedCard
                      data={has2Participants ? leaderboardData[1] : {}}
                      rank={2}
                      height="200px"
                      zIndex={1}
                      margin="0 -16px 0 0"
                      metric={metric.toLowerCase()}
                    />
                  </Reorder.Item>
                  <Reorder.Item
                    style={{ width: "40%" }}
                    as="div"
                    key={leaderboardData[0].user_id}
                    value={leaderboardData[0].rank}
                  >
                    <RankedCard
                      rank={1}
                      data={leaderboardData[0]}
                      height="300px"
                      zIndex={1000}
                      metric={metric.toLowerCase()}
                    />
                  </Reorder.Item>
                  <Reorder.Item
                    style={{ width: "40%" }}
                    as="div"
                    key={has3Participants ? leaderboardData[2]?.user_id : 3}
                    value={has3Participants ? leaderboardData[2]?.rank : 0}
                  >
                    <RankedCard
                      rank={3}
                      data={has3Participants ? leaderboardData[2] : {}}
                      height="200px"
                      zIndex={1}
                      margin="0 0 0 -16px"
                      metric={metric.toLowerCase()}
                    />
                  </Reorder.Item>
                </Reorder.Group>

                <Box sx={{ width: "860px", height: "340px" }}>
                  <div className="marquee-container">
                    <div
                      className="marquee-content"
                      style={{ animationDuration: animationDuration }}
                    >
                      {leaderboardData
                        .slice(3, leaderboardData.length)
                        .map((l) => {
                          return (
                            <React.Fragment key={l.user_id}>
                              <Box
                                style={{ display: "flex" }}
                                key={l.user_id}
                                sx={{
                                  display: "flex",
                                  padding: "0 36px",
                                  height: "64px",
                                  width: "860px",
                                  borderRadius: "4px",
                                  background: palette.background.white,
                                  boxShadow: `0px 1px 20px 0px ${palette.background.grey}`,
                                  marginBottom: "8px",
                                  alignItems: "center",
                                  boxSizing: "border-box",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Text
                                    content={l.rank}
                                    sx={{
                                      color: palette.primary.main,
                                      fontWeight: "700",
                                      fontSize: "36px",
                                      marginRight: "40px",
                                      fontFamily: "MicroExtend Bold",
                                    }}
                                  />
                                  <Avatar
                                    noBorder
                                    profile_image={l.profile_pic}
                                    content={l.username}
                                    sx={{
                                      height: "59px",
                                      width: "59px",
                                      margin: 0,
                                    }}
                                  />
                                  <Text
                                    content={l.username}
                                    sx={{
                                      marginLeft: "40px",
                                      fontSize: "32px",
                                      fontWeight: 600,
                                      fontFamily: "Work sans Semibold",
                                    }}
                                  />
                                </Box>
                                <Text
                                  content={`${Math.floor(
                                    l.total_score
                                  ).toLocaleString("en-us")} ${metric}`}
                                  sx={{
                                    color: palette.text.gray,
                                    fontWeight: "600",
                                    fontSize: "24px",
                                    letterSpacing: "2px",
                                    fontFamily: "Work sans Semibold",
                                  }}
                                />
                              </Box>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </Box>
              </Box>

              {/* <Box
                sx={{
                  position: "fixed",
                  boxShadow: "0px 1px 20px 0px #D9D9D9",
                  backgroundColor: palette.background.white,
                  width: xl ? "300px" : "160px",
                  height: xl ? "456px" : "160px",
                  alignItems: "center",
                  padding: "12px",
                  top: "300px",
                  left: xl ? "80vw" : "84vw",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {xl && (
                  <>
                    <Text
                      content="Join the challenge today!"
                      sx={{
                        fontSize: "30px",
                        fontWeight: 700,
                        color: palette.text.black,
                        textAlign: "center",
                        padding: "16px 24px",
                        fontFamily: "Work sans bold",
                      }}
                    />
                    <Text
                      content="Simply scan this QR code with your phone’s camera to get started"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: palette.text.black,
                        textAlign: "center",
                        padding: "16px",
                        fontFamily: "Work sans Semibold",
                      }}
                    />
                  </>
                )}
                {challenge?.challenge_referral_link && (
                  <QRCode value={challenge?.challenge_referral_link} />
                )}
              </Box> */}
            </>
          ) : (
            <Text
              content="No Participants Yet!"
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                margin: "40vh 0 0 44vw",
              }}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            margin: "40px 0 0 40vw",
          }}
        >
          <Text
            content="Please select one Store to view the Leaderboard"
            sx={{ fontSize: "24px", fontWeight: 700, textAlign: "center" }}
          />

          <Input
            name="search"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value.toLowerCase());
            }}
            placeholder="Search for store name or store id"
            sx={{ width: "480px", margin: "20px 0 0 0" }}
          />
          <Box
            sx={{
              overflow: "scroll",
              width: "480px",
              height: "auto",
              maxHeight: "70vh",
              mt: "40px",
              borderRadius: "10px",
            }}
          >
            {stores
              .filter(
                (s) =>
                  s.store_name.toLowerCase().includes(searchInput) ||
                  s.store_internal_id.toLowerCase().includes(searchInput)
              )
              .map((store) => (
                <Box
                  key={store.store_id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "80px",
                    width: "auto",
                    margin: "12px 0",
                    cursor: "pointer",
                    padding: "0 12px",
                  }}
                >
                  <Text
                    content={store.store_name}
                    sx={{
                      fontSize: "16px",
                      width: "240px", // "auto" or "100%
                      fontWeight: 700,
                      color: palette.text.black,
                      fontFamily: "Work sans Bold",
                    }}
                  />
                  <Button
                    label="View Leaderboard"
                    variant="outlined"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: palette.text.black,
                      fontFamily: "Work sans Bold",
                      padding: "8px 16px",
                    }}
                    onClick={() => {
                      setSelectedStore(store);
                    }}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SchnucksLeaderboard;
