import { Tab, Tabs } from "@mui/material";
import React from "react";
import { palette } from "../../theme";

const TabNavigation = ({ tabs, changeTab, currentTab, styles, tabWidth }) => {
  return (
    <div>
      <Tabs
        variant="standard"
        value={currentTab}
        onChange={(_, newValue) => {
          changeTab(Number(newValue));
        }}
        sx={{}}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              disableRipple
              label={tab.title}
              id={tab.id}
              sx={{
                width: "auto",
                alignItems: "flex-start",
                color: palette.grey.medium,
                fontSize: "32px",
                fontWeight: 700,
                textTransform: "unset",
                p: "12px 0 6px 6px",
                mr: "32px",
                "&.Mui-selected": {
                  color: palette.text.black,
                  borderBottom: `5px solid ${palette.border.violet}`,
                },
                "&.MuiTab-root": {
                  boxSizing: "border-box",
                },
                ...styles,
              }}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabNavigation;
