import PropTypes from "prop-types";
import { Box } from "@mui/material";
import React from "react";
import { palette } from "../../theme";

const Container = (props) => {
  const align = props.align ? props.align : "flex-start";
  const direction = props.column ? "column" : "row";
  const height = props.height ? props.height : "auto";
  const justify = props.justify ? props.justify : "flex-start";
  const margin = props.margin !== undefined ? props.margin : 0;
  const maxWidth = props.maxWidth ? props.maxWidth : "none";
  const minWidth = props.minWidth ? props.minWidth : "none";
  const overflow = props.overflow ? props.overflow : "unset";
  const padding = props.padding !== undefined ? props.padding : 1;
  const position = props.position ? props.position : "none";
  const width = props.width ? props.width : "auto";
  const wrap = props.nowrap
    ? "nowrap"
    : props.wrapReverse
    ? "wrap-reverse"
    : "wrap";
  const borderRadius = props.borderRadius ? props.borderRadius : "0px";

  return (
    <Box
      data-cy={props.dataCy}
      id={props.id}
      onClick={(e) => {
        if (props.onClick) props.onClick(e);
      }}
      sx={{
        alignItems: align,
        border: props.border ? `1px solid ${palette.border.lightGrey}` : "0px",
        display: "flex",
        flexDirection: direction,
        flexWrap: wrap,
        gap: props.gap ? 2 : 0,
        height: height,
        justifyContent: justify,
        m: margin,
        maxWidth: maxWidth,
        minWidth: minWidth,
        opacity: props.transparent ? "40%" : "100%",
        overflow: overflow,
        p: padding,
        position: position,
        width: width,
        borderRadius: borderRadius,
        boxSizing: "border-box",
      }}
    >
      {props.children}
    </Box>
  );
};

Container.propTypes = {
  align: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-evenly",
    "stretch",
  ]),
  border: PropTypes.bool,
  column: PropTypes.bool,
  dataCy: PropTypes.string,
  gap: PropTypes.bool,
  height: PropTypes.string,
  id: PropTypes.string,
  justify: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-evenly",
    "space-around",
  ]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  nowrap: PropTypes.bool,
  onClick: PropTypes.func,
  overflow: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.string,
  transparent: PropTypes.bool,
  width: PropTypes.string,
  wrapReverse: PropTypes.bool,
  children: PropTypes.node,
  borderRadius: PropTypes.string,
};

export default Container;
