import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiEndpoints } from "../../services/api";

export const fetchUsers = createAsyncThunk(
  "manageUsers/fetchUsers",
  async (groupId) => {
    const response = await api.get(apiEndpoints.getUsersList(null, groupId));
    return response;
  }
);

const initialState = {
  isLoading: false,
  error: null,
  usersList: [],
  selectedUser: {},
};

const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    saveUsers: (state, action) => {
      state.usersList = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersList = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  saveUsers,
  setSelectedUser,
  setLoading: setManageUsersLoading,
  logout: manageUsersLogout,
} = manageUsersSlice.actions;

export const selectUsersList = (state) => state.manageUsers.usersList;
export const selectUser = (state) => state.manageUsers.selectedUser;
export const selectManageUsersIsLoading = (state) =>
  state.manageUsers.isLoading;

export default manageUsersSlice.reducer;
