import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  Input,
  Select,
  ToggleButton,
  Divider,
  Loader,
  Text,
} from "../../components";
import { palette } from "../../theme";
import Chip from "../../components/common/chip";
import { useSelector } from "react-redux";
import { selectBrands } from "../Brand/tabs/manage-brand/manageBrandSlice";
import { api, apiEndpoints } from "../../services/api";
import {
  selectCurrentBrand,
  selectIsBrandAdmin,
} from "../../redux/auth/authSlice";

const BrandSelection = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  readOnly,
  clearLocations,
  setClearLocations,
}) => {
  const brands = useSelector(selectBrands);
  const isBrandAdmin = useSelector(selectIsBrandAdmin);
  const currentBrand = useSelector(selectCurrentBrand);
  let brandOptions = [];
  if (isBrandAdmin) {
    const brand = brands.find((b) => b.group_id === currentBrand);
    brandOptions = [{ label: brand.group_name, value: brand.group_id }];
  } else {
    brandOptions = brands.map((b) => {
      return { label: b.group_name, value: b.group_id };
    });
  }
  const [brandSelected, setBrandSelected] = useState(currentBrand);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAllLocations, setSelectAllLocations] = useState(true);
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [standaloneBrand, setStandaloneBrand] = useState(false);

  const handleLocationCheck = (event) => {
    const { value, checked, name } = event.target;
    if (checked) {
      setSelectedLocations([...selectedLocations, { label: name, value }]);
    } else {
      setSelectedLocations(
        selectedLocations.filter((item) => item.value !== value)
      );
    }
  };

  const Location = ({ location, handleLocationCheck, selectedLocations }) => {
    const isChecked = selectedLocations.some(
      (loc) => loc.value === location.value
    );
    return (
      <FormControlLabel
        sx={{
          backgroundColor: palette.background.grey4,
          padding: "8px",
          margin: "0 0 16px 0",
        }}
        control={
          <Checkbox
            name={location.label}
            color="primary"
            value={location.value}
            checked={isChecked}
            onChange={handleLocationCheck}
          />
        }
        label={location.label}
      />
    );
  };

  const fetchBrandLocations = async () => {
    const brandLocations = await api.get(
      apiEndpoints.getBrandLocations(brandSelected)
    );
    setLocations(
      brandLocations
        ? brandLocations.map((l) => {
            return { label: l.group_name, value: l.group_id };
          })
        : []
    );
    setStandaloneBrand(!Boolean(brandLocations.length));
  };

  useEffect(() => {
    if (brandSelected) {
      fetchBrandLocations();
    }
  }, [brandSelected]);

  useEffect(() => {
    if (isBrandAdmin && currentBrand) {
      setFieldValue("brand", currentBrand);
    }
  }, [isBrandAdmin, currentBrand]);

  useEffect(() => {
    if (clearLocations) {
      setSelectedLocations([]);
      setSelectAllLocations(true);
      setClearLocations(false);
    }
  }, [clearLocations]);

  return (
    <Box sx={{ width: "100%" }}>
      <Container width="345px" padding="0">
        <ContainerItem flex padding="0" width="100%">
          <Select
            required
            viewOnly={isBrandAdmin}
            name="brand"
            label="Select Brand?"
            value={values.brand}
            options={brandOptions}
            onChange={(e) => {
              setFieldValue("selectAllLocations", true);
              setFieldValue("locations", "");
              handleChange(e);
              setBrandSelected(e.target.value);
              setSelectedLocations([]);
            }}
            onBlur={handleBlur}
            readOnly={readOnly}
            error={touched.brand && errors.brand ? errors.brand : null}
          />
        </ContainerItem>
        <ContainerItem
          padding={!(standaloneBrand || brandSelected) ? "0" : "16px 0 0 0"}
          width="100%"
        >
          {standaloneBrand ? (
            <Text content="This brand doesn't have any locations. This is a STANDALONE Brand" />
          ) : brandSelected ? (
            <ToggleButton
              name="selectAllLocations"
              checked={values.selectAllLocations}
              label="Target all Brand locations?"
              onChange={(value) => {
                setFieldValue("selectAllLocations", value);
                setSelectAllLocations(value);
              }}
              readOnly={readOnly}
            />
          ) : null}
        </ContainerItem>
        {!selectAllLocations && (
          <ContainerItem padding="16px 0 0 0" width="100%">
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowLocationsModal(true);
              }}
            >
              <Input
                required={!selectAllLocations && !selectedLocations.length}
                name="locations"
                label="Select Location"
                readOnly={readOnly}
                value=""
                onChange={() => {
                  // do nothing
                }}
                sx={{
                  height: "auto",
                  ".MuiOutlinedInput-input": {
                    width: 0,
                  },
                }}
                startIcon={
                  selectedLocations.length ? (
                    <Box>
                      {selectedLocations.map((item, index) => (
                        <Chip
                          item={item}
                          onDelete={() => {
                            const locs = [...selectedLocations];
                            const updatedLocs = locs.filter(
                              (loc) => loc.value !== item.value
                            );
                            setSelectedLocations(updatedLocs);
                            setFieldValue("locations", updatedLocs.join(";"));
                          }}
                        />
                      ))}
                    </Box>
                  ) : null
                }
              />
            </Box>
          </ContainerItem>
        )}
      </Container>
      <Divider color={palette.border.black} margin="32px 0 16px 0" />

      <Dialog
        width="464px"
        title="Location"
        isOpen={showLocationsModal}
        handleClose={() => {
          setShowLocationsModal(false);
        }}
        bottomActions={
          <Container width="100%" padding="0" align="center" justify="left">
            <Button
              label="Add"
              styles={{ width: "120px", textAlign: "center" }}
              variant="contained"
              onClick={() => {
                if (selectedLocations.length === locations.length) {
                  setSelectAllLocations(true);
                  setFieldValue("selectAllLocations", true);
                  setFieldValue("locations", "");
                  setSelectedLocations([]);
                } else {
                  setFieldValue(
                    "locations",
                    selectedLocations.map((l) => l.value).join(";")
                  );
                }
                setShowLocationsModal(false);
              }}
            />
            <Button
              label="Cancel"
              styles={{
                width: "120px",
                textAlign: "center",
                marginLeft: "16px",
              }}
              variant="outlined"
              onClick={() => {
                setShowLocationsModal(false);
                setSelectedLocations([]);
              }}
            />
          </Container>
        }
      >
        <Box sx={{ width: "90%", margin: "16px 0 0 20px" }}>
          <Input
            placeholder="Search..."
            startIcon={<SearchIcon sx={{ marginRight: "4px" }} />}
            sx={{ backgroundColor: palette.background.grey3 }}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Box>

        <Divider color={palette.border.lightGrey} margin="32px 0" />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0 24px",
            height: "200px",
            overflowY: "scroll",
          }}
        >
          {locations
            .filter((loc) =>
              loc.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((location) => {
              return (
                <Location
                  key={location.label}
                  location={location}
                  handleLocationCheck={handleLocationCheck}
                  selectedLocations={selectedLocations}
                />
              );
            })}
        </Box>
      </Dialog>
    </Box>
  );
};

export default React.memo(BrandSelection);
