import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";

import Dialog from "./dialog";
import Container from "./common/container";
import Button from "./common/button";
import {
  selectOpenUpdatePasswordModal,
  setUpdatePasswordModal,
} from "../redux/auth/authSlice";
import ContainerItem from "./common/containerItem";
import Input from "./common/input";
import { showSnackbar } from "./snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "./snackbar/snackbarTypes";
import { palette } from "../theme";

const PasswordField = ({
  name,
  label,
  value,
  setFieldValue,
  onBlur,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value.trim());
  };

  return (
    <ContainerItem width="100%">
      <Input
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={showPassword ? "text" : "password"}
        error={error}
        endIcon={
          <InputAdornment position="end">
            <IconButton
              sx={{ color: palette.primary.main }}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </ContainerItem>
  );
};

const UpdatePassword = () => {
  const openModal = useSelector(selectOpenUpdatePasswordModal);
  const dispatch = useDispatch();
  const auth = getAuth();

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.oldPassword) {
      errors.oldPassword = "Field is required";
    }
    if (values.password.length < 6) {
      errors.password = "Minimum password length is 6";
    }
    if (!values.password) {
      errors.password = "Field is required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Field is required";
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password doesn't match";
    }

    if (values.oldPassword === values.password) {
      errors.password = "New Password cannot be same as Old password";
    }

    return errors;
  };

  const onSubmit = async (values, { setFieldError }) => {
    const user = auth.currentUser;
    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        values.oldPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, values.password);
      dispatch(
        showSnackbar({
          message: "Password Updated Successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      dispatch(setUpdatePasswordModal(false));
    } catch (e) {
      if (e.code === "auth/wrong-password") {
        dispatch(
          showSnackbar({
            message: "Incorrect Old Password",
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
        setFieldError("oldPassword", "Incorrect Password");
      } else {
        dispatch(
          showSnackbar({
            message: "Unable to Update Password",
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const handleClose = () => {
    // setOpen(false);
    dispatch(setUpdatePasswordModal(false));
  };

  return (
    <Dialog
      isOpen={openModal}
      handleClose={handleClose}
      title="Update Password"
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
        isInitialValid={false}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          isSubmitting,
          setFieldValue,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <ContainerItem width="100%">
                <PasswordField
                  name="oldPassword"
                  label="Old Password"
                  value={values.oldPassword}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  error={
                    touched.oldPassword && errors.oldPassword
                      ? errors.oldPassword
                      : null
                  }
                />
              </ContainerItem>
              <ContainerItem width="100%">
                <PasswordField
                  name="password"
                  label="New Password"
                  value={values.password}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  error={
                    touched.password && errors.password ? errors.password : null
                  }
                />
              </ContainerItem>
              <ContainerItem width="100%">
                <PasswordField
                  name="confirmPassword"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  error={
                    touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : null
                  }
                />
              </ContainerItem>
            </Container>
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                label="Cancel"
                styles={{
                  width: "auto",
                  textAlign: "center",
                  marginRight: "16px",
                }}
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                disabled={!isValid}
                isLoading={isSubmitting}
                label="Update"
                styles={{ width: "auto", textAlign: "center" }}
                variant="contained"
                type="submit"
              />
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdatePassword;
