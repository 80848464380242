import React from "react";

const ReportBuilder = () => {
  return (
    <div>
      <span>This is ReportBuilder Page</span>
    </div>
  );
};

export default ReportBuilder;
