import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

import { api, apiEndpoints } from "../../services/api";
import { Input, Label, Table, Loader, Button, Dialog } from "../../components";
import { useLocation } from "react-router-dom";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";
import {
  saveUsers,
  selectUsersList,
  setSelectedUser,
} from "./manageUsersSlice";
import { downloadAsCSVData } from "../../utils/utils";
import {
  selectCurrentBrand,
  selectIsBrandAdmin,
} from "../../redux/auth/authSlice";
import moment from "moment";
import { useCallback } from "react";

const EXPORT_TYPES = {
  ALL: "all",
  CAMPAIGN_USERS: "campaignUsers",
  CLAIMS: "claims",
};

const ManageUsers = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const account_type = queryParams.get("account_type");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxUsers = useSelector(selectUsersList);
  const isAdmin = useSelector(selectIsBrandAdmin);
  const currentBrand = useSelector(selectCurrentBrand);

  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setsearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [isLoading, setIsLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState({
    [EXPORT_TYPES.ALL]: false,
    [EXPORT_TYPES.CAMPAIGN_USERS]: false,
    [EXPORT_TYPES.CLAIMS]: false,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fitbitResponse, setFitbitResponse] = useState({});
  const searchRef = React.useRef(null);
  searchRef.current = searchTerm;
  // let timer = null;

  const columns = [
    { label: "User Id", id: "user_id" },
    { label: "User name", id: "username" },
    { label: "First name", id: "first_name" },
    { label: "Last name", id: "last_name" },
    { label: "Email", id: "email" },
    { label: "Is Active", id: "is_active" },
    { label: "Device", id: "device" },
    { label: "OS Type", id: "platform" },
    {
      label: "Date Joined",
      id: "date_joined",
      format: (row) => moment(row.date_joined).format("DD-MM-YYYY HH:mm:ss"),
    },
    { label: "Mode of Authetication", id: "login_source" },
    { label: "Age Start", id: "age_start" },
    { label: "Age End", id: "age_end" },
    { label: "Device", id: "device" },
    { label: "Is Peloton", id: "is_peleton" },
    { label: "Is Mindbody", id: "is_mindbody" },
    { label: "km/miles", id: "distance_in_km" },
    { label: "Gender", id: "gender" },
    { label: "Timezone", id: "timezone" },
    { label: "Phone Number", id: "phone_number" },
    { label: "Referral Code", id: "referral_code" },
    { label: "User Type", id: "user_type" },
    { label: "How often do Exercise", id: "how_often_do_exercise" },
    {
      label: "Last device shift",
      id: "last_device_shift",
      format: (row) =>
        moment(row.last_device_shift).format("DD-MM-YYYY HH:mm:ss"),
    },
  ];

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onSearch = (e) => {
    setsearchTerm(e.target.value);

    // const searchString = e.target.value;
    // const filteredData = users.filter((value) => {
    //   if (searchTerm === "") {
    //     return value;
    //   } else if (
    //     value.email.toLowerCase().includes(searchString.toLowerCase()) ||
    //     value.username.toLowerCase().includes(searchString.toLowerCase()) ||
    //     value.last_name.toLowerCase().includes(searchString.toLowerCase()) ||
    //     value.first_name.toLowerCase().includes(searchString.toLowerCase()) ||
    //     `${value.first_name} ${value.last_name}`
    //       .toLowerCase()
    //       .includes(searchString.toLowerCase())
    //   ) {
    //     return value;
    //   }
    // });
    // setFilteredUsers(filteredData);
  };

  const fetchUsersList = useCallback(
    async (offset, limit, freshStart = false) => {
      setIsLoading((state) => !state);

      const response = await api.get(
        apiEndpoints.getUsersList(
          account_type ? account_type : null,
          isAdmin && currentBrand,
          searchRef.current,
          offset,
          limit
        )
      );
      setTotalCount(response.total_count);

      setUsers(freshStart ? response.data : users.concat(response.data));
      setIsLoading((state) => !state);
      return response.data;
    },
    [account_type, isAdmin, currentBrand, users]
  );

  const onPageChange = (page, rowsPerPage, freshStart) => {
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    fetchUsersList(offset, limit, freshStart);
  };

  const sendResetPasswordEmail = (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Reset Password Email sent successfully",
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        dispatch(
          showSnackbar({
            message: errorMessage,
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );

        // ..
      });
  };

  const deleteUser = async (email) => {
    try {
      const response = await api.delete(apiEndpoints.deleteUser(email));

      const newUsers = users.filter((user) => user.email !== email);
      setUsers(newUsers);

      dispatch(
        showSnackbar({
          message: "User deleted successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const recalculateChallengeScores = async (email) => {
    dispatch(
      showSnackbar({
        message: "Recalculating scores...",
        type: SNACKBAR_TYPES.SNACKBAR_INFO,
      })
    );

    try {
      const response = await api.post(
        apiEndpoints.recalculateAllChallengeScore(email)
      );
      dispatch(
        showSnackbar({
          message: "Scores recalculated successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const fitbitSync = async (email) => {
    dispatch(
      showSnackbar({
        message: "Syncing Fitbit...",
        type: SNACKBAR_TYPES.SNACKBAR_INFO,
      })
    );
    try {
      const response = await api.get(apiEndpoints.syncFitbit(email));

      setFitbitResponse(response);
      setDialogOpen(true);
      dispatch(
        showSnackbar({
          message: "Fitbit Synced successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleExportCSV = async (exportType) => {
    setExportLoading({ ...exportLoading, [exportType]: true });
    if (exportType === EXPORT_TYPES.ALL) {
      const users = await fetchUsersList(0, totalCount, true);

      downloadAsCSVData(users, "All Users");
    }
    if (exportType === EXPORT_TYPES.CAMPAIGN_USERS) {
      const data = await api.get(apiEndpoints.campaignUsers);
      downloadAsCSVData(data, "Campaign Users");
    }
    if (exportType === EXPORT_TYPES.CLAIMS) {
      const data = await api.get(apiEndpoints.claims);
      downloadAsCSVData(data, "Claims");
    }
    setExportLoading({ ...exportLoading, [exportType]: false });
  };

  useEffect(() => {
    fetchUsersList(0, 10, true);
  }, []);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchUsersList(0, 10, true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // const all_columns = [;
  return (
    <Box>
      {isLoading && <Loader />}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: "24px",
            width: "fit-content",
          }}
        >
          <Label id="demo-simple-select-label" label="Search" />
          <Input
            onChange={onSearch}
            sx={{
              width: 200,
              marginLeft: "16px",
            }}
            value={searchTerm}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            isLoading={exportLoading[EXPORT_TYPES.ALL]}
            styles={{ margin: "0 16px", fontWeight: 700, fontSize: "16px" }}
            variant="outlined"
            onClick={() => {
              handleExportCSV(EXPORT_TYPES.ALL);
            }}
            label="Export All Users"
          />
          {!isAdmin && (
            <>
              <Button
                isLoading={exportLoading[EXPORT_TYPES.CAMPAIGN_USERS]}
                styles={{ margin: "0 32px", fontWeight: 700, fontSize: "16px" }}
                variant="outlined"
                onClick={() => {
                  handleExportCSV(EXPORT_TYPES.CAMPAIGN_USERS);
                }}
                label="Export Campaign Users"
              />
              <Button
                isLoading={exportLoading[EXPORT_TYPES.CLAIMS]}
                styles={{ margin: "0 40px", fontWeight: 700, fontSize: "16px" }}
                variant="outlined"
                onClick={() => {
                  handleExportCSV(EXPORT_TYPES.CLAIMS);
                }}
                label="Export Claims"
              />
            </>
          )}
        </Box>
      </div>
      <Table
        defaultSortColumn="username"
        defaultSortOrder="asc"
        clickable
        columns={columns}
        onClick={(row) => {
          dispatch(setSelectedUser(row));
          navigate(`/manage-users/${row.user_id}`);
        }}
        rows={[...filteredUsers]}
        totalCount={totalCount}
        menuOptions={[
          {
            label: "Send Reset Password mail",
            onClick: (row) => {
              sendResetPasswordEmail(row.email);
            },
          },
          {
            label: "Delete User",
            onClick: (row) => {
              deleteUser(row.email);
            },
          },
          {
            label: "Recalculate Challenge Scores",
            onClick: (row) => {
              recalculateChallengeScores(row.email);
            },
          },
          {
            label: "Sync Fitbit",
            disable: (row) => row.device !== "fitbit",
            onClick: (row) => {
              fitbitSync(row.email);
            },
          },
        ]}
        searchIncluded
        onPageChange={onPageChange}
      />

      <Dialog
        width="auto"
        height="500px"
        isOpen={dialogOpen}
        handleClose={handleDialogClose}
      >
        <Box sx={{ padding: "20px" }}>
          <pre>{JSON.stringify(fitbitResponse, null, 2)}</pre>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ManageUsers;
