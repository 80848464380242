import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
import React from "react";

import Label from "./label";
import { palette } from "../../theme";

const RadioGroup = ({ label, options, handleChange }) => {
  if (!options || !options.length) {
    return null;
  }
  return (
    <FormControl>
      <Label label={label} />
      <MuiRadioGroup
        onChange={(e) => {
          if (handleChange) {
            handleChange(e);
          }
        }}
      >
        {options?.map((o) => {
          return (
            <FormControlLabel
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: palette.text.black,
                "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
                  color: palette.border.mediumGrey,
                },
                "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                  color: palette.background.green,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 30,
                },
              }}
              value={o.value}
              control={<Radio checked={false} />}
              label={o.label}
            />
          );
        })}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default React.memo(RadioGroup);
