import { CircularProgress } from "@mui/material";
import React from "react";
import { palette } from "../../theme";

const Loader = ({ color }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        textAlign: "center",
      }}
    >
      <CircularProgress sx={{ color: color ?? palette.background.purple }} />
    </div>
  );
};

export default Loader;
