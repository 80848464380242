import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Step1 from "./step1";
import Step2 from "./step2";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedChallange,
  selectSelectedChallange,
} from "../manage-challenges/manageChallengesSlice";
import moment from "moment";

const CreateChallenge = () => {
  const dispatch = useDispatch();
  let editableChallenge = useSelector(selectSelectedChallange);

  const [isEditing, setIsEditing] = useState(false);
  const [editableFields, setEditableFields] = useState([]);
  const [globalEdit, setGlobalEdit] = useState(false);
  const [hasImported, setHasImported] = useState(false);
  const [challenge, setChallenge] = useState();
  const [step1Data, setStep1Data] = useState({
    default_challenge: false,
    is_private: false,
    selectAllLocations: true,
  });
  const [selectedBannerImage, setSelectedBannerImage] = useState(null);
  const [previewBannerImage, setPreviewBannerImage] = useState(null);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [clearLocations, setClearLocations] = useState(false);
  const [isWeeklyChallenge, setIsWeeklyChallenge] = useState(false);

  const handleStep1Data = (data) => {
    setStep1Data((prevData) => {
      return { ...prevData, ...data };
    });
  };

  useEffect(() => {
    if (editableChallenge && Object.keys(editableChallenge).length) {
      const ch = {
        ...editableChallenge,
        start_date: editableChallenge.start_date?.slice(0, 10),
        end_date: editableChallenge.end_date?.slice(0, 10),
        challenge_type:
          editableChallenge?.challenge_metadata[
            Object.keys(editableChallenge?.challenge_metadata)[0]
          ].challenge_type,
      };
      const isWeekChallenge = (
        editableChallenge?.challenge_metadata[
          Object.keys(editableChallenge?.challenge_metadata)[0]
        ]?.min_count
          ? Object.keys(
              editableChallenge?.challenge_metadata[
                Object.keys(editableChallenge?.challenge_metadata)[0]
              ]?.min_count
            )
          : []
      ).includes("per_week");

      setIsWeeklyChallenge(isWeekChallenge);
      setChallenge(ch);
      setIsEditing(true);
      setStep1Data(ch);
      const fields = ["challenge_name", "end_date"];
      if (moment(editableChallenge.start_date?.slice(0, 10)) > moment.utc()) {
        setGlobalEdit(true);
      }
    }
  }, [editableChallenge]);

  useEffect(() => {
    return () => {
      if (editableChallenge) {
        dispatch(clearSelectedChallange());
      }
    };
  }, []);

  return (
    <Box style={{ mb: "16px" }}>
      <Step1
        globalEdit={globalEdit}
        isEditing={isEditing}
        challenge={challenge}
        setPreviewBannerImage={setPreviewBannerImage}
        setSelectedBannerImage={setSelectedBannerImage}
        handleStep1Data={handleStep1Data}
        selectedBannerImage={selectedBannerImage}
        previewBannerImage={previewBannerImage}
        setIsStep1Valid={setIsStep1Valid}
        onImport={(challenge) => {
          const ch = {
            challenge_name: challenge?.challenge_name,
            goal_description: challenge?.goal_description,
            prize_description: challenge?.prize_description,
            default_challenge: challenge?.default_challenge,
            challenge_metadata: challenge?.challenge_metadata,
            start_date: null,
            end_date: null,
            challenge_type:
              challenge?.challenge_metadata[
                Object.keys(challenge?.challenge_metadata)[0]
              ].challenge_type,
            challenge_image_url: challenge.challenge_image,
            challenge_image: challenge.challenge_image,
            is_private: false,
          };
          delete ch?.participants;
          setHasImported(true);
          setChallenge(ch);
          setStep1Data(ch);
          setIsWeeklyChallenge(
            Object.keys(
              challenge?.challenge_metadata[
                Object.keys(challenge?.challenge_metadata)[0]
              ]?.min_count || {}
            ).includes("per_week")
          );
        }}
        clearLocations={clearLocations}
        isWeeklyChallenge={isWeeklyChallenge}
        setClearLocations={setClearLocations}
      />
      <Step2
        hasImported={hasImported}
        isEditing={isEditing}
        globalEdit={globalEdit}
        challenge={challenge}
        previewBanner={previewBannerImage}
        selectedBanner={selectedBannerImage}
        step1Data={step1Data}
        setClearLocations={setClearLocations}
        isStep1Valid={isStep1Valid}
        setIsWeeklyChallenge={(value) => {
          setIsWeeklyChallenge(value);
        }}
      />
    </Box>
  );
};

export default CreateChallenge;
