import React from "react";
import { useNavigate } from "react-router-dom";

import { Loader, Table } from "../../../components";

const ParticipantsTable = ({
  metrics,
  tableColumns,
  data,
  deleteParticipant,
  totalCount,
  isTableLoading,
  fromDate,
  toDate,
  challengeName,
  readOnly = false,
}) => {
  const navigate = useNavigate();

  if (isTableLoading) {
    return <Loader />;
  }
  return (
    <Table
      isLoading={isTableLoading}
      columns={tableColumns}
      rows={data}
      defaultSortColumn={tableColumns[1].id}
      defaultSortOrder="desc"
      rowsPerPageOptions={[10, 25, 50]}
      pageCount={10}
      clickable
      totalCount={totalCount}
      onClick={(row) => {
        if (!readOnly) {
          navigate(
            `/manage-users/${row.user_id}?metric=${metrics.join(
              ","
            )}&fromDate=${fromDate}&toDate=${toDate}&challengeName=${challengeName}`
          );
        }
      }}
      readOnly={readOnly}
      menuOptions={
        !readOnly && [
          {
            label: "Remove",
            onClick: (row) => {
              deleteParticipant(row.username);
            },
          },
        ]
      }
    />
  );
};

export default ParticipantsTable;
