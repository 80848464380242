import React, { useEffect, useRef, useState } from "react";
import { api, apiEndpoints } from "../../../services/api";
import { Button, Container, Dialog, Loader, Text } from "../../../components";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { palette } from "../../../theme";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../components/snackbar/snackbarTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import PostCardV2 from "./common/postCardV2";
import { selectCurrentBrand } from "../../../redux/auth/authSlice";
import CommentsModal from "./common/commentsModal";

const ManagePosts = () => {
  const scrollRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePostId, setPostDeleteId] = useState();
  const [isUpdating, setIsUpdating] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [openCommentsDrawer, setOpenCommentsDrawer] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);

  const limit = 10;

  const dispatch = useDispatch();
  let currentBrand = useSelector(selectCurrentBrand);
  const fitlvlBrand = process.env.REACT_APP_FITLVL_BRAND;

  currentBrand = currentBrand === fitlvlBrand ? null : currentBrand;

  const fetchPosts = async (zeroPage) => {
    setIsLoading((prevIsLoading) => !prevIsLoading);
    try {
      const newPosts = await api.get(
        apiEndpoints.getPosts(limit, zeroPage ? 1 : page, currentBrand)
      );
      if (zeroPage) {
        setPosts(newPosts.results);
        setPage(2);
      } else if (newPosts.results.length) {
        setPosts([...posts, ...newPosts.results]);
        if (newPosts.hasNext) {
          setPage((page) => page + 1);
        } else {
          setHasMore(false);
        }
      }
    } catch (e) {
      console.log(e);
      dispatch(
        showSnackbar({
          message: typeof e === "string" ? e : JSON.stringify(e),
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
    setIsLoading((state) => !state);
  };

  const reFetchPosts = () => {
    setHasMore(true);
    setPage(() => 1);
    setPosts(() => []);
    fetchPosts(true);
  };

  const onDelete = async () => {
    setIsUpdating((state) => !state);
    try {
      await api.delete(
        apiEndpoints.deletePosts(deletePostId.postId, deletePostId.feedId)
      );
      dispatch(
        showSnackbar({
          message: "Post/Poll deleted Successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      // dispatch(fetchPosts());
      const index = posts.findIndex((item) => item.id === deletePostId?.postId);
      if (index > -1) {
        posts.splice(index, 1);
        setPosts(posts);
      }
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
    setShowDeleteModal(false);
    setIsUpdating((state) => !state);
  };

  const handlePinning = async (type, id) => {
    setIsUpdating((state) => !state);
    let success = false;
    if (type === "pin") {
      try {
        await api.post(apiEndpoints.pinPost(), { post_id: id });
        dispatch(
          showSnackbar({
            message: "Post/Poll pinned Successfully",
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
        success = true;
      } catch (e) {
        dispatch(
          showSnackbar({
            message: e,
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
    } else {
      try {
        await api.delete(apiEndpoints.pinPost(id));
        dispatch(
          showSnackbar({
            message: "Post/Poll unpinned Successfully",
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
        success = true;
      } catch (e) {
        dispatch(
          showSnackbar({
            message: e,
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
    }
    setIsUpdating((state) => !state);
    if (success) {
      reFetchPosts();
      window.scrollTo(0, 0);
    }
  };

  const handleOnDeleteClicked = (show, id, feedId) => {
    if (show) {
      setShowDeleteModal(true);
      setPostDeleteId({ postId: id, feedId });
    } else {
      setPostDeleteId(undefined);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (currentBrand) {
      reFetchPosts();
    }
  }, [currentBrand]);

  return (
    <Box ref={scrollRef}>
      <InfiniteScroll
        dataLength={posts.length}
        hasMore={hasMore}
        next={fetchPosts}
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {posts.map((post, index) => {
          const isPoll = post.type === "poll";
          const isChallenge = post.type === "challenge";
          const isVideo = post.type === "video";
          const values = {
            id: post.id,
            type: post.type,
            profileImage: post.actor?.data?.profileImage,
            counts: post.reaction_counts,
            pinned: post.pinned,
            profileName: post.actor?.data?.name,
            brand: post.actor?.id,
            caption: post.caption || post.verb,
          };

          if (post?.ig_post) {
            values.igPost = post.ig_post;
          }

          if (post?.ig_post_link) {
            values.igPostLink = post.ig_post_link;
          }

          if (values.type === "image" || values.type === "image with text") {
            values.type = "post";
            if (post.navigation_details) {
              const navParts =
                post.navigation_details?.navigation_path?.split("/");
              if (navParts.length >= 3) {
                if (navParts[1] === "challenges") {
                  values.tabDestination = `/${navParts[1]}`;
                  values.detailDestination = navParts[2];
                }
                if (navParts[1] === "weblink") {
                  values.tabDestination = "/weblink";
                  values.detailDestination =
                    post.navigation_details.navigation_path.replace(
                      "/weblink/",
                      ""
                    );
                }
                if (navParts[1] === "discover") {
                  values.tabDestination = "/";
                  values.detailDestination =
                    post.navigation_details?.navigation_path;
                }
              }
              if (
                !["challenges", "discover", "weblink"].includes(navParts[1])
              ) {
                values.tabDestination = "/";
                values.detailDestination =
                  post.navigation_details?.navigation_path;
              }
              // values.tabDestination = `/${
              //   post.navigation_details?.navigation_path?.split("/")[1]
              // }`;
              // values.detailDestination =
              //   post.navigation_details?.navigation_path?.split("/")[2];
              values.buttonText = post.navigation_details?.button_text;
            }
          }

          if (isPoll) {
            const totalVotes = Object.values(post.reaction_counts).reduce(
              (a, b) => a + b,
              0
            );
            values.options = post.options.map((o, index) => {
              return {
                id: index,
                text: o.displayOption,
                votes: post.reaction_counts[o.kind] ?? 0,
                votesPercent: totalVotes
                  ? Math.round(
                      ((post.reaction_counts[o.kind] ?? 0) * 100) / totalVotes
                    )
                  : 0,
                kind: o.kind,
              };
            });
            values.totalVotes = totalVotes;
            values.startDate = new Date(post.start_date).setHours(0, 0, 0, 0);
            values.endDate = new Date(post.end_date).setHours(0, 0, 0, 0);
          } else if (isChallenge) {
            values.challenge = post.challenge;
          } else if (isVideo) {
            values.video = post.attachments?.videos[0];
          } else {
            values.banner = post.attachments?.images[0];
          }

          return (
            <Box
              key={values.id + index}
              sx={{
                mr: "48px",
                mb: "60px",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1);",
                borderRadius: "10px",
                height: "fit-content",
                width: "294px",
              }}
            >
              <PostCardV2
                key={post.id}
                values={values}
                previewBannerImage={values.banner}
                previewVideo={values.video}
                readOnly
                onDelete={handleOnDeleteClicked}
                handlePinning={handlePinning}
                isUpdating={isUpdating}
                openComments={(id) => {
                  setOpenCommentsDrawer(true);
                  setSelectedPostId(values);
                }}
              />
            </Box>
          );
        })}
      </InfiniteScroll>
      {isLoading && <Loader />}

      {selectedPostId && (
        <CommentsModal
          post={selectedPostId}
          open={openCommentsDrawer}
          onClose={() => {
            setOpenCommentsDrawer(false);
            setSelectedPostId(null);
            reFetchPosts();
          }}
        />
      )}

      <Dialog
        isOpen={showDeleteModal}
        bottomActions={
          <Container padding="0" align="center">
            <Button
              label="Cancel"
              styles={{
                width: "auto",
                textAlign: "center",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={async () => {
                setShowDeleteModal(false);
              }}
            />

            <Button
              isLoading={isUpdating}
              label="Delete"
              styles={{ width: "auto", textAlign: "center" }}
              variant="contained"
              onClick={async () => {
                await onDelete();
              }}
            />
          </Container>
        }
      >
        <Container justify="space-around" padding="16px 0" margin="auto">
          <Text
            content={"Are you sure you want to delete the Post?"}
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              color: palette.text.black,
            }}
          />
        </Container>
      </Dialog>
    </Box>
  );
};

export default React.memo(ManagePosts);
