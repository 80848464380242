import { createTheme } from "@mui/material";
import "../fonts/font.css";
export const palette = {
  primary: {
    main: "#530AEA",
  },
  secondary: {
    main: "#FE7300",
  },
  border: {
    violet: "#530AEA",
    lightGrey: "#E7E6E6",
    black: "#231F20",
    mediumGrey: "#918F8F",
  },
  text: {
    primary: "#656263",
    secondary: "#231F20",
    white: "#F5F5F5",
    black: "#231F20",
    hotPink: "#E00E8C",
    purple: "#AF09D8",
    error: "#FF1A1A",
    blue: "#2068F5",
    darkGray: "#656263",
    lightGray: "#C8C8C8",
    gray: "#667080",
    trueBlack: "#000",
  },
  background: {
    primary: "#FAFBFD",
    secondary: "#530AEA",
    white: "#FFF",
    purple: "#AF09D8",
    hotPink: "#E00E8C",
    lime: "#B8EC49",
    yellow: "#FFE143",
    skyBlue: "#67DFFA",
    darkPlum: "#3D164B",
    grey: "#D9D9D9",
    darkGrey: "#656263",
    lightGrey: "#E7E6E6",
    grey2: "#939393",
    green: "#05DB7A",
    pollYellow: "#FFC444",
    pollLightPink: "#F0CCDC",
    crimson: "#E53535",
    grey3: "#F1F2F6",
    grey4: "#F9FAFC",
    lightBlue: "#E3F3FF",
    intenseRed: "#FC3357",
    blue: "#2068F5",
    orange: "#F17D3B",
    navigationBackground: "#EDEDED",
  },
  error: {
    main: "#EE4B2B",
  },
  grey: {
    medium: "#918F8F",
  },
};
const baseTheme = {
  typography: { fontFamily: "Open Sans, Arial, sans-serif" },
  palette,
};
export const theme = createTheme(baseTheme);
