import { FormControl, MenuItem, Select as MuiSelect } from "@mui/material";
import React from "react";
import { palette } from "../../theme";
import Label from "./label";
import Text from "./text";
import { capitalizeString } from "../../utils/utils";

const Select = ({
  required,
  label,
  labelColor,
  options,
  name,
  value,
  onChange,
  onBlur,
  error,
  readOnly,
  placeholder,
  multiple,
  renderValue,
  sx,
}) => {
  return (
    <FormControl error={error} sx={{ width: "100%" }}>
      {label ? <Label label={label} required={required} /> : null}
      {readOnly ? (
        <Text
          content={
            capitalizeString(
              options.find((o) => o.value === value)?.label?.toString()
            ) ?? "--"
          }
          sx={{ fontWeight: 400, fontSize: "16px", color: palette.text.black }}
        />
      ) : (
        <>
          <MuiSelect
            placeholder={placeholder}
            variant="outlined"
            sx={{
              backgroundColor: palette.background.white,
              width: "100%",
              height: "47px",
              color: palette.text.black,
              borderColor: "white",
              "&: hover MuiInputBase-root": {
                borderColor: palette.primary.main,
              },
              "&: hover .MuiSelect-root": {
                borderColor: palette.primary.main,
              },
              "& .MuiSelect-select": {
                borderColor: palette.primary.main,
              },
              "& .MuiSelect-outlined": {
                borderColor: palette.primary.main,
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
                boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.primary.main,
                border: `1px solid ${palette.primary.main}`,
              },
              ...sx,
            }}
            name={name}
            multiple={multiple}
            value={value}
            onBlur={onBlur}
            displayEmpty
            onChange={(e) => {
              e.preventDefault();
              onChange(e);
            }}
            renderValue={renderValue}
            // disabled={options.length === 0}
          >
            {placeholder && (
              <MenuItem disabled value="">
                {placeholder}
              </MenuItem>
            )}
            {options.length
              ? options.map((option) => {
                  return (
                    <MenuItem
                      disabled={option?.disabled}
                      key={option.value}
                      value={option.value}
                      sx={{
                        padding: "8px 16px",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: palette.text.secondary,
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })
              : null}
          </MuiSelect>

          {error ? (
            <Text
              content={error}
              sx={{
                fontWeight: 400,
                fontSize: "10px",
                color: palette.text.error,
                marginTop: "8px",
              }}
            />
          ) : null}
        </>
      )}
    </FormControl>
  );
};

export default React.memo(Select);
