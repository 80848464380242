import PropTypes from "prop-types";
import { Box } from "@mui/material";
import * as React from "react";

const ContainerItem = (props) => {
  const align = props.align ? props.align : "flex-start";
  const flex = props.flex ? "flex" : "block";
  const flexWrap = props.flexWrap ? props.flexWrap : "unset";
  const height = props.height ? props.height : "auto";
  const justify = props.justify ? props.justify : "left";
  const margin = props.margin !== undefined ? props.margin : 0;
  const maxWidth = props.maxWidth ? props.maxWidth : "auto";
  const minWidth = props.minWidth ? props.minWidth : "auto";
  const padding = props.padding !== undefined ? props.padding : 1;
  const textAlign = props.textAlign ? props.textAlign : justify;
  const width = props.width
    ? props.width
    : props.minWidth
    ? "100%"
    : "fit-content";
  const overflow = props.overflow ? props.overflow : "visible";
  const position = props.position ? props.position : "static";

  return (
    <Box
      id={props.id}
      sx={{
        alignItems: align,
        flexWrap: flexWrap,
        display: flex,
        height: height,
        justifyContent:
          justify === "left"
            ? "flex-start"
            : justify === "right"
            ? "flex-end"
            : justify === "between"
            ? "space-between"
            : "center",
        m: margin,
        maxWidth: maxWidth,
        minWidth: minWidth,
        p: padding,
        textAlign: textAlign,
        width: width,
        overflowY: overflow,
        flexDirection: props.column ? "column" : "row",
        boxSizing: "border-box",
        position: position,
      }}
    >
      {props.children}
    </Box>
  );
};

ContainerItem.propTypes = {
  align: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
  column: PropTypes.bool,
  flex: PropTypes.bool,
  height: PropTypes.string,
  id: PropTypes.string,
  justify: PropTypes.string,
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.string,
  flexWrap: PropTypes.oneOf(["wrap", "nowrap", "unset"]),
  overflow: PropTypes.oneOf(["visible", "hidden", "auto"]),
  position: PropTypes.oneOf(["static", "relative", "absolute"]),
};

export default ContainerItem;
