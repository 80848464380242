import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import {
  POSTTYPES,
  createArrayOfObjects,
  extractDate,
  getUTCDate,
  TabTypes,
  tabOptions,
  dashboardOptions,
} from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  ContainerItem,
  Hint,
  Input,
  InputDate,
  Label,
  Loader,
  Select,
  Text,
} from "../../../components";
import BrandSelection from "../../common/brandSelection";
import { palette } from "../../../theme";
import { Box } from "@mui/material";
import PostCard from "./common/postCard";
import PostCardV2 from "./common/postCardV2";
import { api, apiEndpoints } from "../../../services/api";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../components/snackbar/snackbarTypes";
import { clearSelectedPost, selectPostSelected } from "../postSlice";
import { ReactComponent as TrashIcon } from "../../../assets/trash_icon.svg";
import { selectCurrentChallenges } from "../../challenges/tabs/manage-challenges/manageChallengesSlice";

const CreatePostV2 = () => {
  const [initialValues, setInitialValues] = useState({
    brand: "",
    selectAllLocations: true,
    locations: "",
    type: POSTTYPES.POST,
    caption: "",
    banner: "",
    options: [],
    startDate: "",
    endDate: "",
    noOfOptions: null,
    buttonText: "",
    tabDestination: "",
    detailDestination: "",
    video: "",
    videoUrl: "",
  });
  const [selectedBannerImage, setSelectedBannerImage] = useState();
  const [previewBannerImage, setPreviewBannerImage] = useState();
  const [clearLocations, setClearLocations] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [tabType, setTabType] = useState();
  const [destinationDetailsOptions, setDestinationDetailsOptions] = useState(
    []
  );
  const [weblink, setWeblink] = useState();
  // create 2 state variables - selectedVideo and previewVideo
  const [selectedVideo, setSelectedVideo] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [titleLoading, setTitleLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hiddenFileInput = React.useRef(null);
  const hiddenVideoInput = React.useRef(null);
  const formRef = React.useRef(null);
  const now_utc = getUTCDate();
  const selectedPost = useSelector(selectPostSelected);
  const isEditing = Boolean(selectedPost);
  const challenges = useSelector(selectCurrentChallenges);

  const noOfOptions = [
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
  ];
  const postTypeOptions = [
    { label: "Content Post", value: POSTTYPES.POST },
    { label: "Poll", value: POSTTYPES.POLL },
    { label: "Content Video", value: POSTTYPES.VIDEO },
    { label: "Text", value: POSTTYPES.TEXT },
  ];

  const handleBannerImageUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.includes("image")) {
      setSelectedBannerImage(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewBannerImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setSelectedBannerImage(null);
      setPreviewBannerImage(null);
      console.error("Invalid file selected. Please choose an image file.");
    }

    // get the aspect ratio of the image
    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      setAspectRatio(aspectRatio);
    };
  };

  const handleVideoUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.includes("video")) {
      setSelectedVideo(selectedFile);
      setPreviewVideo(URL.createObjectURL(selectedFile));
      formRef.current.setFieldValue("video", selectedFile.name);
      formRef.current.setFieldValue("videoUrl", "");
      formRef.current.validateForm();
    } else {
      setSelectedVideo(null);
      setPreviewVideo(null);
      console.error("Invalid file selected. Please choose a video file.");
    }
  };

  const validate = (values) => {
    var urlPattern = new RegExp(
      /^(?:(?:https?|ftp):\/\/)?\S+(?:\.\S+)+(?:\/\S*)?$/i
    );
    const errors = {};
    if (!values.type) {
      errors.type = "This field is required";
    }
    if (values.type === POSTTYPES.POST) {
      if (!values.banner) {
        errors.banner = "This field is required";
      }
    }
    if (values.type === POSTTYPES.VIDEO) {
      if (!(values.video || values.videoUrl)) {
        errors.video = "This field is required";
        errors.videoUrl = "This field is required or upload a video";
      }

      if (
        values.videoUrl &&
        values.videoUrl !== "" &&
        !urlPattern.test(values.videoUrl)
      ) {
        errors.videoUrl = "Invalid URL";
      }
    }

    if (values.type === POSTTYPES.VIDEO || values.type === POSTTYPES.POST) {
      const isRequired =
        values.buttonText !== "" || values.tabDestination !== "";
      if (isRequired && !values.buttonText) {
        errors.buttonText = "This field is required";
      }
      if (isRequired && values.tabDestination === "") {
        errors.tabDestination = "This field is required";
      }
      if (
        (values.tabDestination === TabTypes.WEBLINK ||
          values.tabDestination === TabTypes.SCREENPATH) &&
        !values.detailDestination
      ) {
        errors.detailDestination = "This field is required";
      }
    }
    if (!values.brand) {
      errors.brand = "This field is required";
    }

    if (!values.selectAllLocations && !values.locations) {
      errors.locations = "This field is required";
    }
    if (values.type === POSTTYPES.POLL) {
      if (values.caption === "") {
        errors.caption = "This field is required";
      }
      if (values.noOfOptions === "") {
        errors.noOfOptions = "This field is required";
      }
      if (!values.options.length) {
        errors.options = "This field is required";
      }
      if (
        (values.tabDestination && !values.buttonText) ||
        (!values.tabDestination && values.buttonText)
      ) {
        errors.buttonText = "This field is required";
        errors.tabDestination = "This field is required";
      }

      if (
        (values.tabDestination === TabTypes.WEBLINK ||
          values.tabDestination === TabTypes.SCREENPATH) &&
        !values.detailDestination
      ) {
        errors.detailDestination = "This field is required";
      }

      values.options.forEach((value, index) => {
        if (!value) {
          errors[`options[${index}]`] = "Field is required";
        }
      });
    }
    return errors;
  };

  const onSubmit = async (values, resetForm) => {
    const formData = new FormData();
    values = { ...values, detailDestination: values.detailDestination.trim() };
    formData.append(
      "target_ids",
      values.selectAllLocations ? values.brand : values.locations
    );
    let navPath =
      values.tabDestination === TabTypes.SCREENPATH
        ? ""
        : values.tabDestination;
    if (values.detailDestination) {
      if (values.tabDestination === TabTypes.SCREENPATH) {
        navPath =
          values.detailDestination[0] === "/"
            ? values.detailDestination
            : `/${values.detailDestination}`;
      } else {
        navPath = `${navPath}/${values.detailDestination}`;
      }
    }
    if (values.type === POSTTYPES.POST) {
      formData.append(
        "type",
        values.banner && values.banner !== "" ? "image with text" : "text"
      );
      formData.append("verb", values.caption);
      if (values.navigationPath !== "" && values.buttonText !== "") {
        formData.append(
          "navigation_details",
          JSON.stringify({
            button_text: values.buttonText,
            navigation_path: navPath,
          })
        );
      }

      formData.append("aspect_ratio", aspectRatio);
      formData.append("file", selectedBannerImage);
    } else if (values.type === POSTTYPES.VIDEO) {
      formData.append("type", values.type);
      formData.append("verb", values.caption);
      if (values.video) {
        formData.append("file", selectedVideo);
      } else {
        formData.append("video_url", values.videoUrl);
      }
      if (values.navigationPath !== "" && values.buttonText !== "") {
        formData.append(
          "navigation_details",
          JSON.stringify({
            button_text: values.buttonText,
            navigation_path: navPath,
          })
        );
      }
    } else if (values.type === POSTTYPES.TEXT) {
      formData.append("type", POSTTYPES.TEXT);
      formData.append("verb", values.caption);
    } else {
      formData.append("type", POSTTYPES.POLL);
      formData.append("verb", values.caption);
      formData.append("options", JSON.stringify(values.options));
      if (values.startDate !== "") {
        formData.append("start_date", extractDate(values.startDate));
      }
      if (values.endDate !== "") {
        formData.append("end_date", extractDate(values.endDate));
      }
    }
    try {
      await api.post(apiEndpoints.managePosts(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(
        showSnackbar({
          message: `${
            values.type === POSTTYPES.POLL ? "Poll" : "Post"
          } created successfully`,
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      resetForm();
      setClearLocations(true);
      setSelectedBannerImage(null);
      setPreviewBannerImage(null);
      navigate("/posts?tab=manage");
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const onEdit = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("post_id", values.id);
    formData.append("group_id", values.brand);

    if (values.caption !== selectedPost.caption) {
      formData.append("verb", values.caption);
    }

    if (values.type === POSTTYPES.POST) {
      let navPath =
        values.tabDestination === TabTypes.SCREENPATH
          ? ""
          : values.tabDestination;
      if (values.detailDestination) {
        if (values.tabDestination === TabTypes.SCREENPATH) {
          navPath =
            values.detailDestination[0] === "/"
              ? values.detailDestination
              : `/${values.detailDestination}`;
        } else {
          navPath = `${navPath}/${values.detailDestination}`;
        }
      }

      if (
        navPath !==
        `${selectedPost.tabDestination}/${selectedPost.detailDestination}`
      ) {
        formData.append(
          "navigation_details",
          JSON.stringify({
            button_text: values.buttonText,
            navigation_path: navPath,
          })
        );
      }
      if (selectedBannerImage) {
        formData.append("file", selectedBannerImage);
        formData.append("aspect_ratio", aspectRatio);
      }
    } else {
      formData.append("options", JSON.stringify(values.options));
      formData.append("end_date", values.endDate);
      formData.append("start_date", values.startDate);
    }

    try {
      await api.put(apiEndpoints.managePosts(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(
        showSnackbar({
          message: `${
            values.type === POSTTYPES.POLL ? "Poll" : "Post"
          } updated successfully`,
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      resetForm();
      setSelectedBannerImage(null);
      setPreviewBannerImage(null);
      navigate("/posts?tab=manage");
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (isEditing) {
      await onEdit(values, resetForm);
    } else {
      await onSubmit(values, resetForm);
    }
    setWeblink("");
  };

  useEffect(() => {
    if (selectedPost) {
      setInitialValues({
        ...selectedPost,
        selectAllLocations: true,
        noOfOptions: selectedPost.options.length,
      });
      setTabType(selectedPost.tabDestination);
    }

    return () => {
      dispatch(clearSelectedPost());
    };
  }, [selectedPost]);

  useEffect(() => {
    if (tabType) {
      let options = [];
      if (tabType === TabTypes.CHALLENGES) {
        options = challenges.map((challenge) => {
          return {
            label: challenge.challenge_name,
            value: challenge.challenge_id,
          };
        });
      } else if (tabType === TabTypes.DASHBOARD) {
        options = dashboardOptions;
      }
      setDestinationDetailsOptions(options);
    }
  }, [tabType]);

  const fetchTitle = async (url) => {
    setTitleLoading((prev) => !prev);
    try {
      const endpoint =
        "https://yscshwu17f.execute-api.us-east-1.amazonaws.com/fetchWebpageTitle?";

      const response = await fetch(endpoint + new URLSearchParams({ url }));
      const data = await response.json();

      formRef.current.setFieldValue("buttonText", data.title);
    } catch (e) {
      formRef.current.setFieldValue("buttonText", "");
      console.log(e);
    }
    setTitleLoading((prev) => !prev);
  };

  useEffect(() => {
    if (weblink) {
      const urlPattern = new RegExp(
        /^(?:(?:https?|ftp):\/\/)?\S+(?:\.\S+)+(?:\/\S*)?$/i
      );
      if (urlPattern.test(weblink)) {
        fetchTitle(weblink);
      }
    }
  }, [weblink]);

  return (
    <Formik
      innerRef={formRef}
      values={initialValues}
      enableReinitialize
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
      validate={validate}
      initialTouched={false}
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        validateForm,
      }) => (
        <form onSubmit={handleSubmit}>
          {titleLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            >
              <Loader />
            </div>
          )}
          <Container>
            {!isEditing && (
              <Container padding="0" width="100%">
                <BrandSelection
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  readOnly={false}
                  clearLocations={clearLocations}
                  setClearLocations={setClearLocations}
                />
              </Container>
            )}
            <Container width="345px" margin="0 200px 0 0">
              <ContainerItem padding="8px 0" width="100%">
                <Select
                  required
                  readOnly={isEditing}
                  name="type"
                  label="Select post type"
                  value={values.type}
                  options={postTypeOptions}
                  onChange={(e) => {
                    if (e.target.value === POSTTYPES.POST) {
                      setSelectedVideo(null);
                      setPreviewVideo(null);
                    }
                    if (e.target.value === POSTTYPES.VIDEO) {
                      setSelectedBannerImage(null);
                      setPreviewBannerImage(null);
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  error={errors.type}
                />
              </ContainerItem>
              {values.type === POSTTYPES.POLL ? (
                <>
                  <ContainerItem padding="8px 0" width="100%">
                    <Input
                      required
                      name="caption"
                      label="Enter question"
                      value={values.caption}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.caption}
                      type="text"
                    />
                  </ContainerItem>
                  <ContainerItem padding="8px 0" width="100%">
                    <Select
                      name="noOfOptions"
                      label="Select number of options"
                      value={values.noOfOptions}
                      options={noOfOptions}
                      onChange={(e) => {
                        handleChange(e);
                        const selectedValue = parseInt(e.target.value);
                        const currentLength = values.options.length;

                        if (selectedValue === currentLength) {
                          return; // No need to make any changes
                        } else if (selectedValue < currentLength) {
                          const newOptions = values.options.slice(
                            0,
                            selectedValue
                          );

                          setFieldValue("options", newOptions);
                        } else {
                          const newOptions = [];

                          for (
                            let i = 1;
                            i <= selectedValue - currentLength;
                            i++
                          ) {
                            newOptions.push({
                              label: "",
                              value: `option_${currentLength + i}`,
                            });
                          }
                          setFieldValue("options", [
                            ...values.options,
                            ...newOptions,
                          ]);
                        }
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.noOfOptions && errors.noOfOptions
                          ? errors.noOfOptions
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem padding="8px 0" width="100%">
                    {values.options.map((option, index) => {
                      return (
                        <ContainerItem flex>
                          <Input
                            label={`Option ${index + 1}`}
                            name={`options[${index}]`}
                            value={option.label}
                            onChange={(e) => {
                              setFieldValue(`options[${index}]`, {
                                ...option,
                                label: e.target.value,
                              });
                            }}
                            onBlur={handleBlur}
                            error={errors[`options[${index}]`]}
                          />
                          <TrashIcon
                            style={{
                              marginLeft: "16px",
                              marginTop: "4px",
                              cursor:
                                values.options.length > 2
                                  ? "pointer"
                                  : "not-allowed",
                            }}
                            onClick={() => {
                              if (values.options.length > 2) {
                                const opts = [...values.options];
                                opts.splice(index, 1);
                                setFieldValue(`options`, opts);
                                setFieldValue("noOfOptions", opts.length);
                              }
                            }}
                          />
                        </ContainerItem>
                      );
                    })}
                  </ContainerItem>
                </>
              ) : (
                <>
                  <ContainerItem padding="8px 0" width="100%">
                    <Input
                      required
                      name="caption"
                      value={values.caption}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hint="NOTE: Character count limited to 1000"
                      hintStyles={{ color: palette.text.purple }}
                      label="Enter description"
                      multiline
                      minRows={5}
                      characterLimit={1000}
                      error={errors.caption}
                    />
                  </ContainerItem>
                  <ContainerItem padding="8px 0" width="100%">
                    <Input
                      required={values.tabDestination}
                      name="buttonText"
                      value={values.buttonText}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Navigation Label"
                      error={errors.buttonText}
                    />
                  </ContainerItem>
                  <ContainerItem padding="0 0 16px 0" width="100%">
                    <Select
                      name="tabDestination"
                      value={values.tabDestination}
                      label="Select Tab Destination"
                      options={tabOptions}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setTabType(e.target.value);
                        setFieldValue("detailDestination", "");
                      }}
                      error={
                        errors.tabDestination && touched.tabDestination
                          ? errors.tabDestination
                          : null
                      }
                    />
                  </ContainerItem>
                  {values.tabDestination === TabTypes.SCREENPATH && (
                    <Box
                      onClick={() => {
                        window.open(
                          "https://docs.google.com/document/d/1ybugkogdCJefzWMyqqSPQZllRXR3T35GLjY04ixm_tQ/edit",
                          "blank"
                        );
                      }}
                    >
                      <Text
                        content="Document for list of app routes"
                        sx={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  )}
                  {values.tabDestination &&
                    values.tabDestination !== TabTypes.DISCOVER && (
                      <ContainerItem padding="16px 0" width="100%">
                        {tabType === TabTypes.CHALLENGES ? (
                          <Select
                            required={
                              (values.tabDestination === TabTypes.WEBLINK ||
                                values.tabDestination ===
                                  TabTypes.SCREENPATH) &&
                              !values.detailDestination
                            }
                            name="detailDestination"
                            value={values.detailDestination}
                            label="Select Detail Destination "
                            options={destinationDetailsOptions}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={
                              errors.detailDestination &&
                              touched.detailDestination
                                ? errors.detailDestination
                                : null
                            }
                          />
                        ) : (
                          <Input
                            required={
                              (values.tabDestination === TabTypes.WEBLINK ||
                                values.tabDestination ===
                                  TabTypes.SCREENPATH) &&
                              !values.detailDestination
                            }
                            name="detailDestination"
                            label={
                              tabType === TabTypes.WEBLINK
                                ? "Enter the link"
                                : tabType === TabTypes.SCREENPATH
                                ? "Enter the path"
                                : "Enter the post id"
                            }
                            hint={
                              tabType === TabTypes.WEBLINK
                                ? "Please enter the raw link directly. Do not start with weblink "
                                : undefined
                            }
                            hintStyles={{ color: palette.text.purple }}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setWeblink(e.target.value);
                            }}
                            type="text"
                            value={values.detailDestination}
                            error={
                              errors.detailDestination &&
                              touched.detailDestination
                                ? errors.detailDestination
                                : null
                            }
                          />
                        )}
                      </ContainerItem>
                    )}

                  <ContainerItem width="100%" padding="0" margin="8px 0 0 0">
                    <Container
                      padding="0"
                      align="center"
                      justify="space-between"
                    >
                      {values.type === POSTTYPES.VIDEO ? (
                        <ContainerItem padding="0">
                          <Button
                            required
                            label="Upload Video"
                            variant="contained"
                            onClick={() => {
                              hiddenVideoInput.current.click();
                            }}
                            styles={{ width: "160px" }}
                          />
                          <Hint
                            hint=".mp4 .mov are supported"
                            hintStyles={{ color: palette.text.purple }}
                          />
                          <input
                            name="video"
                            type="file"
                            accept=".mp4, .mov*"
                            ref={hiddenVideoInput}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleVideoUpload(e);
                              setFieldValue("videoUrl", "");
                              setFieldValue("video", e.target.files[0].name);
                              validateForm({
                                ...values,
                                video: e.target.files[0].name,
                              });
                            }}
                          />
                          <Text
                            content="OR"
                            sx={{ margin: "8px 0", width: "100%" }}
                          />
                          <Input
                            name="videoUrl"
                            value={values.videoUrl}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("video", "");
                              setPreviewVideo(e.target.value);
                              setSelectedVideo(null);
                            }}
                            onBlur={handleBlur}
                            label="Add Video URL"
                            required={!(values.video || values.videoUrl)}
                            sx={{ width: "330px" }}
                            type="text"
                            error={errors.videoUrl}
                          />
                        </ContainerItem>
                      ) : values.type === POSTTYPES.POST ? (
                        <ContainerItem padding="0">
                          <Button
                            required
                            label="Upload Photo"
                            variant="contained"
                            onClick={() => {
                              hiddenFileInput.current.click();
                            }}
                          />
                          <Hint
                            hint=".png .webp .jpg .jpeg are supported"
                            hintStyles={{ color: palette.text.purple }}
                          />
                          <input
                            name="banner"
                            type="file"
                            accept=".png, .jpg, .webp, .jpeg*"
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleBannerImageUpload(e);
                              setFieldValue("banner", e.target.files[0].name);
                            }}
                          />
                        </ContainerItem>
                      ) : null}
                    </Container>
                  </ContainerItem>
                </>
              )}
              {values.type ? (
                <ContainerItem flex width="100%" padding="40px 0 8px 0">
                  {isEditing && (
                    <Button
                      label="Cancel"
                      variant="outlined"
                      onClick={() => {
                        navigate("/posts?tab=manage");
                        dispatch(clearSelectedPost());
                      }}
                      styles={{ marginRight: "16px", width: "120px" }}
                    />
                  )}
                  <Button
                    isLoading={isSubmitting}
                    disabled={!isValid}
                    label={`${isEditing ? "Update" : "Publish"} ${
                      values.type === POSTTYPES.POLL ? "Poll" : "Post"
                    }`}
                    variant="contained"
                    type="submit"
                  />
                </ContainerItem>
              ) : null}
            </Container>
            <Container width="345px">
              {values.type === POSTTYPES.POLL && (
                <>
                  <ContainerItem padding="8px 0" width="100%">
                    <InputDate
                      label="Start Date"
                      labelStyles={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      name="startDate"
                      value={values.startDate}
                      minDate={now_utc}
                      maxDate={values.endDate}
                      handleDate={(date) => {
                        setFieldValue("startDate", date);
                      }}
                      error={
                        touched.startDate && errors.startDate
                          ? errors.startDate
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem
                    padding="8px 0"
                    margin="0 0 40px 0"
                    width="100%"
                  >
                    <InputDate
                      label="End Date"
                      labelStyles={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      name="endDate"
                      value={values.endDate}
                      minDate={
                        values.startDate !== "" ? values.startDate : now_utc
                      }
                      handleDate={(date) => {
                        setFieldValue("endDate", date);
                      }}
                      error={
                        touched.endDate && errors.endDate
                          ? errors.endDate
                          : null
                      }
                    />
                  </ContainerItem>
                </>
              )}
              <Box
                sx={{
                  width: "404px",
                  height: "auto",
                  backgroundColor: palette.background.darkPlum,
                  borderRadius: "10px",
                  // marginTop: "56px",
                  padding: "28px 56px 56px 56px",
                  boxSizing: "border-box",
                }}
              >
                <ContainerItem padding="0 0 18px 0" width="100%">
                  <Text
                    content="Live Preview"
                    sx={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: palette.text.white,
                      margin: "0 68px",
                    }}
                  />
                </ContainerItem>
                <PostCardV2
                  isEditing={isEditing}
                  values={values}
                  previewBannerImage={previewBannerImage}
                  previewVideo={previewVideo}
                  fileRef={hiddenFileInput}
                />
              </Box>
            </Container>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default CreatePostV2;
