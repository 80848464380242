import React from "react";

import Container from "../common/container";
import ContainerItem from "../common/containerItem";
import Text from "../common/text";
import { palette } from "../../theme";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";

const DialogHeader = ({ title, handleClose }) => {
  return (
    <Container
      align="left"
      padding="0"
      justify="space-between"
      margin="16px 8px 8px 16px"
    >
      <ContainerItem padding="0">
        {title ? (
          <Text
            content={title}
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: palette.text.black,
            }}
          />
        ) : null}
      </ContainerItem>
      <CloseIcon
        style={{ cursor: "pointer", height: "20px", width: "20px" }}
        onClick={handleClose}
      />
    </Container>
  );
};

export default DialogHeader;
