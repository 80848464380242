import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Challenges from "./challenges";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBrandSelected,
  selectBrandSelected,
} from "../../../Brand/tabs/manage-brand/manageBrandSlice";

const ManageChallenge = () => {
  const brand = useSelector(selectBrandSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearBrandSelected());
    };
  }, []);
  return (
    <Box>
      <Challenges type="current" brandId={brand?.group_id} />
      <div style={{ marginTop: "36px" }} />
      <Challenges type="completed" brandId={brand?.group_id} />
      <div style={{ marginTop: "36px" }} />
      <Challenges type="deleted" brandId={brand?.group_id} />
    </Box>
  );
};

export default ManageChallenge;
