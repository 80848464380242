import React, { useState } from "react";
import {
  Button,
  Container,
  ContainerItem,
  Input,
  Text,
} from "../../../components";
import { useDispatch } from "react-redux";
import { api, apiEndpoints } from "../../../services/api";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../components/snackbar/snackbarTypes";
import { Box } from "@mui/material";
import { palette } from "../../../theme";
import { fetchChallenge } from "../../challenges/tabs/manage-challenges/manageChallengesSlice";

const AddParticipants = ({ challengeId, type }) => {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const dispatch = useDispatch();

  const handleUsername = (e) => {
    if (error) {
      setError();
    }
    setUsername(e.target.value);
  };

  const doesUsernameExists = async () => {
    setIsLoading(true);

    try {
      const response = await api.get(apiEndpoints.checkUsername(username));
      if (response.length) return true;
      else return false;
    } catch (e) {
      return false;
    }
  };

  const addParticipant = async () => {
    if (await doesUsernameExists(username)) {
      setIsLoading((state) => !state);
      try {
        await api.post(apiEndpoints.manageParticipant, {
          challenge: challengeId,
          target_user: username,
        });
        dispatch(
          showSnackbar({
            message: "Participant Added Successfully",
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
        dispatch(fetchChallenge({ id: challengeId, type }));
        // handleDialog("Participant Added successfully", true);
      } catch (e) {
        console.log(e);
        setUsername("");
        dispatch(
          showSnackbar({
            message: typeof e === "string" ? e : JSON.stringify(e),
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
        setError("User doesn't exist or already added");
      }
    } else {
      dispatch(
        showSnackbar({
          message: "Error!",
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
      setUsername("");
      setError("User doesn't exist");
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Container padding="0" align="center">
        <ContainerItem padding="0 8px 8px 0" width="345px">
          <Input
            label="Add a participant"
            placeholder="Enter username or email"
            labelFontSize="16px"
            labelFontWeight={400}
            name="username"
            value={username}
            onChange={handleUsername}
            type="text"
          />
        </ContainerItem>
        <ContainerItem padding="0 8px 8px 8px">
          <Button
            isLoading={isLoading}
            disabled={username.length === 0}
            label="Add to Challenge"
            variant="outlined"
            styles={{ width: "auto", marginTop: "26px" }}
            onClick={() => {
              addParticipant();
            }}
          />
        </ContainerItem>
      </Container>
      {error && (
        <Text
          content={error}
          sx={{
            color: palette.error.main,
            fontSize: "12px",
            fontWeight: 400,
            marginLeft: "16px",
          }}
        />
      )}
    </Box>
  );
};

export default AddParticipants;
