import { Box } from "@mui/material";
import React from "react";

import { palette } from "../../../../../theme";
import { Container, ContainerItem, Text } from "../../../../../components";
import { ReactComponent as PlaceholderImage } from "../../../../../assets/placeholderChallengeBanner.svg";
import ParticipantCard from "./participantCard";
import { useNavigate } from "react-router-dom";
import {
  parseDateString,
  saveToLocalStorage,
} from "../../../../../utils/utils";

const ChallengeCard = ({ banner, challenge, disableClick }) => {
  const navigate = useNavigate();
  const description = challenge.goal_description ?? challenge.prize_description;
  const dateString =
    parseDateString(challenge.start_date, challenge.end_date) ?? "";

  return (
    <Box
      key={challenge.challenge_id}
      sx={{
        backgroundColor: palette.background.white,
        width: "344px",
        height: "176px",
        borderRadius: "10px",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!disableClick) {
          navigate(`/challenges/${challenge.challenge_id}`);
        }
      }}
    >
      <Container padding="0" borderRadius="10px" width="336px" height="176px">
        <ContainerItem
          width="132px"
          height="100%"
          overflow="hidden"
          padding="0"
        >
          <Box sx={{ height: "100%", overflow: "hidden" }}>
            {banner || challenge.challenge_image ? (
              <img
                loading="lazy"
                src={banner ?? challenge.challenge_image_url}
                alt={challenge.challenge_name}
                style={{
                  width: "132px",
                  height: "100%",
                  objectFit: "cover",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <PlaceholderImage
                style={{
                  width: "auto",
                  height: "100%",
                  objectFit: "cover",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              />
            )}
          </Box>
        </ContainerItem>
        <ContainerItem padding="8px" height="100%" flex column width="200px">
          <Text
            content={challenge.challenge_name ?? "NA"}
            tooltip={challenge.challenge_name?.length > 36}
            titleCutoff={36}
            tooltipPlacement="right-end"
            sx={{
              fontWeight: 700,
              fontSize: "12px",
              color: palette.text.secondary,
            }}
          />
          <div style={{ marginTop: "4px" }} />
          <Text
            content={dateString ?? "NA"}
            tooltip={dateString.length > 27}
            titleCutoff={27}
            tooltipPlacement="right-end"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              color: palette.text.secondary,
            }}
          />
          <div style={{ marginTop: "4px" }} />

          <Text
            content={description}
            tooltip={description?.length > 124}
            tooltipPlacement="right-end"
            titleCutoff={124}
            sx={{
              height: "100px",
              lineHeight: "14px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": "4",
              "-webkit-box-orient": "vertical",
              "-moz-box-orient": "vertical",

              fontWeight: 400,
              fontSize: "12px",
              color: palette.text.secondary,
              textTransform: description ? "none" : "capitalize",
            }}
          />
          {challenge?.total_participants ? (
            <Box
              sx={{
                display: "flex",
                ml: "4px",
                mt: "6px",
                border: `1px solid ${palette.border.lightGrey}`,
                borderRadius: "10px",
                p: "4px",
              }}
              onClick={() => {
                saveToLocalStorage(
                  "selectedChallenge",
                  JSON.stringify(challenge)
                );
              }}
            >
              <>
                {challenge.participants
                  ?.slice(0, 3)
                  ?.map((participant, index) => {
                    const participantName =
                      participant.participant_user__first_name +
                      participant.participant_user__last_name +
                      participant.participant_user__email;
                    return (
                      <ParticipantCard
                        name={participantName}
                        index={index}
                        profie_img={participant.profile_pic}
                      />
                    );
                  })}
              </>
              <Text
                content={`${challenge.total_participants}${
                  challenge?.total_participants > 1
                    ? " Participants"
                    : " Participant"
                }`}
                sx={{ fontSize: "12px", fontWeight: 400, marginLeft: "4px" }}
              />
            </Box>
          ) : null}
        </ContainerItem>
      </Container>
    </Box>
  );
};

export default ChallengeCard;
