import React, { useEffect, useMemo, useState } from "react";
import {
  AppBar as AppBarContainer,
  Box,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Container from "./common/container";
import ContainerItem from "./common/containerItem";
import Text from "./common/text";
import { palette } from "../theme";
import Avatar from "./common/avatar";
import Navbar from "./navbar";
import {
  logout,
  selectIsBrandAdmin,
  selectUserType,
  setCurrentBrand,
  setUpdatePasswordModal,
} from "../redux/auth/authSlice";
import {
  clearLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
} from "../utils/utils";
import { ReactComponent as BackIcon } from "../assets/back_icon.svg";
import Select from "./common/select";
import {
  manageBrandsLogout,
  selectBrands,
} from "../pages/Brand/tabs/manage-brand/manageBrandSlice";
import { manageUsersLogout } from "../pages/manage-users/manageUsersSlice";
import { manageChallegesLogout } from "../pages/challenges/tabs/manage-challenges/manageChallengesSlice";
import { postsLogout } from "../pages/posts/postSlice";

const drawerWidth = 222;
const appBarHeight = "64px";

const Layout = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isBrandAdmin = useSelector(selectIsBrandAdmin);
  const brands = useSelector(selectBrands);
  const fitlvlBrand = process.env.REACT_APP_FITLVL_BRAND;

  const brandOptions = useMemo(() => {
    return brands.map((b) => {
      return { label: b.group_name, value: b.group_id };
    });
  }, [brands]);

  const displayName = getFromLocalStorage("userName");
  const profilePic = getFromLocalStorage("profilePic");
  const currentBrand = getFromLocalStorage("currentBrand");

  const [profileMenuEl, setProfileMenuEl] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(
    currentBrand ?? brands[0].group_id
  );

  const open = Boolean(profileMenuEl);

  const handleClick = (event) => {
    setProfileMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileMenuEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(manageBrandsLogout());
    dispatch(manageChallegesLogout());
    dispatch(manageUsersLogout());
    dispatch(postsLogout());
    clearLocalStorage();
    navigate("/");
  };

  useEffect(() => {
    if (selectedBrand && isBrandAdmin) {
      dispatch(setCurrentBrand(selectedBrand));
      saveToLocalStorage("currentBrand", selectedBrand);
    } else {
      dispatch(setCurrentBrand(fitlvlBrand));
      saveToLocalStorage("currentBrand", fitlvlBrand);
    }
  }, [selectedBrand]);

  return (
    <>
      <AppBarContainer
        sx={{
          backgroundColor: "#fff",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          height: appBarHeight,
        }}
      >
        <Toolbar>
          <Container flex justify="space-between" align="center" width="100%">
            <ContainerItem flex align="center">
              <BackIcon
                style={{
                  height: "32px",
                  width: "32px",
                  marginRight: "24px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Text
                content="Admin Dashboard"
                sx={{
                  fontSize: "20px",
                  fontweight: 600,
                  color: palette.text.primary,
                }}
              />
            </ContainerItem>

            <Container padding="0" align="center">
              {isBrandAdmin && (
                <ContainerItem flex width="240px" margin="0">
                  <Select
                    options={brandOptions}
                    value={selectedBrand}
                    onChange={(e) => {
                      setSelectedBrand(e.target.value);
                    }}
                  />
                </ContainerItem>
              )}
              <ContainerItem padding="8px 24px 8px 8px">
                <Text
                  content={displayName}
                  sx={{
                    fontSize: "16px",
                    fontweight: 500,
                    color: palette.text.secondary,
                    width: "fit-content",
                  }}
                />
              </ContainerItem>
              <ContainerItem>
                <Box sx={{ cursor: "pointer" }} onClick={handleClick}>
                  <Avatar content={displayName} profile_image={profilePic} />
                </Box>
              </ContainerItem>
            </Container>
          </Container>
        </Toolbar>
      </AppBarContainer>
      <Navbar drawerWidth={drawerWidth} />
      <Menu
        autoFocus={false}
        open={open}
        anchorEl={profileMenuEl}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(setUpdatePasswordModal(true));
            handleClose();
          }}
        >
          Update Passwoord
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();

            handleLogout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          position: "relative",
          boxSizing: "border-box",
          p: 1,
          top: "64px",
          left: `${drawerWidth}px`,
          background: "#FAFBFD",
          minHeight: `calc(100vh - ${appBarHeight})`,
          padding: "8px 50px",
          mb: "16px",
        }}
      >
        {props.children}
      </Box>
    </>
  );
};

export default React.memo(Layout);
