import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  Autocomplete,
  createFilterOptions,
  TextField,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import { ReactComponent as EditIcon } from "../../assets/edit_icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash_icon.svg";
import {
  Button,
  Container,
  ContainerItem,
  Dialog,
  Input,
  Label,
  Select,
  Text,
} from "../../components";
import { palette } from "../../theme";
import { api, apiEndpoints } from "../../services/api";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";

const filter = createFilterOptions();

const timezonesOptions = [
  {
    label: "Eastern Standard Time: UTC -5:00/UTC -4:00",
    value: "America/New_York",
  },
  {
    label: "Central Standard Time: UTC -6:00/UTC -5:00",
    value: "America/Chicago",
  },
  {
    label: "Mountain Standard Time: UTC -7:00/UTC -6:00",
    value: "America/Denver",
  },
  {
    label: "Pacific Standard Time: UTC -8:00/UTC -7:00",
    value: "America/Los_Angeles",
  },
];

const LocationForm = ({
  isGym,
  location,
  index,
  fetchLocations,
  brandName,
  brandId,
  handleDeleteGroup,
  formType,
  onCancel,
  otherLocationSlugs = [],
  otherLocationAddresses = [],
}) => {
  const [initialValues, setInitialValues] = useState({
    group_name: location?.group_name.replace(`${brandName}`, "").trim(),
    address: location?.meta_data?.address || "",
    timezone: location?.meta_data?.timezone || "",
    ...(isGym
      ? {
          location_slug: location?.meta_data?.location_slug || "",
        }
      : {}),
  });
  const isAdding = formType === "add";

  const [readOnly, setReadOnly] = useState(isAdding ? false : true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [locationSlugAutoCompleteOptions, setLocationSlugAutoCompleteOptions] =
    useState([]);
  const [locationSlugOptionsLoading, setLocationSlugOptionsLoading] =
    useState(false);
  const [locationSlugAutoCompleteOpen, setLocationSlugAutoCompleteOpen] =
    useState(false);
  const [slugOptions, setSlugOptions] = useState({});
  const isDevEnv = process.env.REACT_APP_ENV === "dev";
  let timer = null;

  // const timeZones = moment.tz.names().filter((tz) => tz.startsWith("America/"));
  // const timesonesOptions = timeZones.map((t) => {
  //   return { label: t, value: t };
  // });

  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((k) => {
      if (!values[k]) {
        errors[k] = "This field is required";
      }
    });
    if (
      values.location_slug &&
      otherLocationSlugs?.includes(values.location_slug)
    ) {
      errors.location_slug = "This location slug is already taken";
    }
    if (values.address && otherLocationAddresses?.includes(values.address)) {
      errors.address = "This address is already taken";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    if (isAdding) {
      values.name = values.group_name;
      formData.append("locations", [JSON.stringify([values])]);
      formData.append("brand_id", brandId);
    } else {
      Object.keys(values).forEach((k) => {
        formData.append(k, values[k]);
      });
    }
    formData.append("group_type", "LOCATION");

    try {
      if (isAdding) {
        await api.post(apiEndpoints.addGroup, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        await api.put(apiEndpoints.editGroup(location?.group_id), formData, {
          eaders: { "Content-Type": "multipart/form-data" },
        });
      }
      dispatch(
        showSnackbar({
          message: `Location updated successfully`,
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      fetchLocations();
      if (onCancel) {
        onCancel();
      }
    } catch (e) {
      dispatch(
        showSnackbar({
          message: typeof e === "string" ? e : JSON.stringify(e),
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
    setReadOnly(true);
  };

  const handleDelete = async () => {
    setIsDeleting((state) => !state);
    await handleDeleteGroup(location?.group_id, "LOCATION");
    setIsDeleting((state) => !state);
    fetchLocations();
  };

  const fetchSlugs = async (address) => {
    setLocationSlugOptionsLoading((state) => true);

    let options = [];
    const slugs = {};
    if (address.length > 3) {
      try {
        const response = await api.get(apiEndpoints.getLocationSlug(address));
        if (response.suggestions) {
          options = response.suggestions.map((item) => {
            slugs[item.address] = item.slug;
            return {
              label: item.address,
              value: item.address,
            };
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    setLocationSlugAutoCompleteOptions(options);
    setSlugOptions(slugs);
    setLocationSlugOptionsLoading((state) => false);
  };

  const debouncedFetchSlugs = (address) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fetchSlugs(address);
    }, 1000);
  };

  useEffect(() => {
    if (isDevEnv) {
      timezonesOptions.push({
        label: "Indian Standard Time: UTC +5:30",
        value: "Asia/Kolkata",
      });
    }
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={validate}
        validateOnBlur
        validateOnChange
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Container
              width="100%"
              column
              key={location?.group_id}
              padding="8px 0"
            >
              {isAdding ? (
                <ContainerItem
                  flex
                  justify="between"
                  width="36%"
                  padding="0 0 8px 0"
                >
                  <Text
                    content="Add Location"
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: palette.text.black,
                      width: "100px",
                    }}
                  />
                </ContainerItem>
              ) : (
                <ContainerItem
                  flex
                  justify="between"
                  width="36%"
                  padding="0 0 16px 0"
                >
                  <Text
                    content={`Location ${index}`}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: palette.text.black,
                      width: "100px",
                    }}
                  />
                  <ContainerItem margin="0 0 0 20px" padding="0">
                    <ContentCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(location?.group_id);
                        dispatch(
                          showSnackbar({
                            message: "Group ID copied",
                            type: SNACKBAR_TYPES.SNACKBAR_INFO,
                          })
                        );
                      }}
                      style={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                        color: palette.border.black,
                        marginRight: "4px",
                      }}
                    />
                    <EditIcon
                      onClick={() => {
                        setReadOnly(false);
                      }}
                      style={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                      }}
                    />
                    <TrashIcon
                      style={{
                        marginLeft: "12px",
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDeleteModalOpen(true);
                      }}
                    />
                  </ContainerItem>
                </ContainerItem>
              )}
              <ContainerItem width="36%" padding="0 0 0 8px">
                <Input
                  sx={{ display: "flex" }}
                  placeholder="Enter Location name here"
                  name="group_name"
                  value={values.group_name}
                  readOnly={readOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  error={
                    errors.group_name && touched.group_name
                      ? errors.group_name
                      : null
                  }
                />
              </ContainerItem>

              <ContainerItem width="36%" padding="8px 0 0 8px">
                <Label label="Address" />
                {readOnly ? (
                  <>
                    <Text
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        color: palette.text.black,
                      }}
                      content={values.address}
                    />
                  </>
                ) : isGym ? (
                  <Tooltip
                    title={
                      values?.address?.length > 33 ? values?.address : null
                    }
                    placement="right-start"
                  >
                    <Autocomplete
                      name="address"
                      options={locationSlugAutoCompleteOptions}
                      open={locationSlugAutoCompleteOpen}
                      onOpen={() => {
                        setLocationSlugAutoCompleteOpen(true);
                      }}
                      onClose={() => {
                        setLocationSlugAutoCompleteOpen(false);
                      }}
                      getOptionLabel={(option) => option.label ?? option}
                      loading={locationSlugOptionsLoading}
                      onBlur={handleBlur}
                      onChange={(event, newValue) => {
                        let value = "";
                        if (typeof newValue === "string") {
                          value = newValue;
                        } else if (newValue && newValue.value) {
                          value = newValue.value;
                        } else {
                          value = newValue;
                        }

                        setFieldValue("address", value ?? "", true);
                        setFieldValue(
                          "location_slug",
                          slugOptions[value] ?? "",
                          true
                        );
                        if (value) {
                          setLocationSlugAutoCompleteOptions((state) => {
                            const oldOptions = state;
                            oldOptions.push({
                              label: value,
                              value: value,
                            });
                            return oldOptions;
                          });
                        }
                        setLocationSlugAutoCompleteOpen(false);
                      }}
                      onInputChange={(event, newInputValue) => {
                        debouncedFetchSlugs(newInputValue);
                      }}
                      freeSolo
                      clearOnBlur
                      selectOnFocus
                      handleHomeEndKeys
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.label
                        );
                        if (inputValue && inputValue !== "" && !isExisting) {
                          filtered.push({
                            value: inputValue,
                            label: `Add ${inputValue}`,
                          });
                        }

                        return filtered;
                      }}
                      value={values.address}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "&:hover .MuiTextField-root": {
                              borderColor: palette.primary.main,
                            },
                            backgroundColor: palette.background.white,
                            color: "black",
                            width: "100%",
                            borderRadius: "10px",
                          }}
                          placeholder={"Select Address here"}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {locationSlugOptionsLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Tooltip>
                ) : (
                  <Input
                    placeholder="Enter Address here"
                    name="address"
                    value={values.address}
                    readOnly={readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    error={
                      errors.address && touched.address ? errors.address : null
                    }
                  />
                )}
              </ContainerItem>

              {isGym && (
                <ContainerItem width="36%" padding="8px 0 0 8px">
                  <Input
                    label="Location Slug"
                    placeholder="Enter Location Slug here"
                    name="location_slug"
                    value={values.location_slug}
                    readOnly={readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    error={
                      errors.location_slug && touched.location_slug
                        ? errors.location_slug
                        : null
                    }
                  />
                </ContainerItem>
              )}
              <div
                style={{
                  fontSize: "12px",
                  margin: "8px 0 4px 0",
                  width: "36%",
                  padding: "2px 0 0 8px",
                }}
              >
                {!readOnly && <span>Is this booking url correct?</span>}{" "}
                {/* make this sentence short */}
                <a
                  style={{ color: palette.primary.main }}
                  href={`https://www.mindbodyonline.com/explore/locations/${values.location_slug}`}
                  target="_blank"
                  rel="noreferrer"
                >{`https://www.mindbodyonline.com/explore/locations/${values.location_slug}`}</a>
              </div>

              <ContainerItem width="36%" padding="8px 0 0 8px">
                <Select
                  label="Timezone"
                  placeholder="Select Timezone here"
                  name="timezone"
                  options={timezonesOptions}
                  value={values.timezone}
                  readOnly={readOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  error={
                    errors.timezone && touched.timezone ? errors.timezone : null
                  }
                />
              </ContainerItem>

              {!readOnly && (
                <ContainerItem
                  padding="16px 0 0 0"
                  width="36%"
                  flex
                  justify="between"
                >
                  <Button
                    label="Cancel"
                    variant="outlined"
                    onClick={() => {
                      resetForm();
                      if (onCancel) {
                        onCancel();
                      }
                      setReadOnly(true);
                    }}
                    styles={{ width: "120px" }}
                  />
                  <Button
                    disabled={
                      !isValid ||
                      JSON.stringify(initialValues) === JSON.stringify(values)
                    }
                    isLoading={isSubmitting}
                    label={isAdding ? "Create" : "Update"}
                    variant="contained"
                    onClick={() => {
                      // setReadOnly(true);
                    }}
                    styles={{ width: "120px" }}
                    type="submit"
                  />
                </ContainerItem>
              )}
            </Container>
          </form>
        )}
      </Formik>
      <Dialog
        isOpen={deleteModalOpen}
        bottomActions={
          <Container padding="0" align="center">
            <Button
              label="Cancel"
              styles={{
                width: "auto",
                textAlign: "center",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={async () => {
                setDeleteModalOpen(false);
              }}
            />

            <Button
              isLoading={isDeleting}
              label="Delete"
              styles={{ width: "auto", textAlign: "center" }}
              variant="contained"
              onClick={async () => {
                handleDelete();
              }}
            />
          </Container>
        }
      >
        <Container justify="space-around" padding="16px 0" margin="auto">
          <ContainerItem>
            <Text
              content={"Are you sure you want to delete this Location?"}
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: palette.text.black,
              }}
            />
          </ContainerItem>

          <ContainerItem padding="8px">
            <Text
              content={
                "By deleting this Location, all users who are currently following it will no longer follow the location. Additionally, users will no longer have access to view your challenges or community posts associated with this location."
              }
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: palette.error.main,
                textAlign: "center",
              }}
            />
          </ContainerItem>
        </Container>
      </Dialog>
    </>
  );
};

export default LocationForm;
