import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Container,
  ContainerItem,
  Label,
  Text,
} from "../../../../components";
import { palette } from "../../../../theme";
import { Box } from "@mui/material";
import Challenge from "./challenge";
import ChallengeCard from "../manage-challenges/common/challengeCard";
import { api, apiEndpoints } from "../../../../services/api";
import { extractDate } from "../../../../utils/utils";
import {
  fetchCurrentChallenges,
  setSelectedChallange,
} from "../manage-challenges/manageChallengesSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SNACKBAR_TYPES } from "../../../../components/snackbar/snackbarTypes";
import { showSnackbar } from "../../../../components/snackbar/snackbarSlice";

const UNITS = {
  steps: "count",
  active_minutes: "mins",
  sleep: "mins",
  distance: "miles",
  pelotonkjs: "kjs",
  gym_visit: "count",
  referral: "count",
};

const Step2 = ({
  step1Data,
  previewBanner,
  selectedBanner,
  isStep1Valid,
  challenge,
  isEditing,
  globalEdit = false,
  hasImported,
  setClearLocations,
  setIsWeeklyChallenge,
}) => {
  const [add2ndMetric, setAdd2ndMetric] = useState(false);
  const [part1Data, setPart1Data] = useState({});
  const [part2Data, setPart2Data] = useState({});
  const [metadata, setMetadata] = useState();

  const [isPart1Valid, setIsPart1Valid] = useState(false);
  const [isPart2Valid, setIsPart2Valid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let isFormValid = isStep1Valid && isPart1Valid;
  if (add2ndMetric) {
    isFormValid = isFormValid && isPart2Valid;
  }

  const handleAdd2ndMetric = (value) => {
    setAdd2ndMetric(value);
  };

  const handlePartsData = (part, data) => {
    if (part === 1 || part === 0) {
      setPart1Data((state) => {
        return { ...part1Data, ...data };
      });
    }
    if (part === 2) {
      setPart2Data({ ...part2Data, ...data });
    }
  };

  const getMetadata = (data, type) => {
    const metadata = {
      // [part1Data["metric"]]: {
      challenge_type: type,
      // if(data)
      metric_unit: UNITS[data["metric"]],
      // },
    };

    if (data["hasPrize"]) {
      metadata["prize_description"] = data["prize_description"];
    }

    if (data["hasPerDayGoal"]) {
      metadata.min_count = {
        per_day: parseFloat(data["perDayGoal"]),
      };
      metadata["goal_description"] = data["goal_description"];
    }
    if (data["hasWeeklyGoal"]) {
      metadata.min_count = {
        per_week: parseFloat(data["perDayGoal"]),
      };
      metadata["goal_description"] = data["goal_description"];
    }
    return metadata;
  };

  const handleSubmit = async () => {
    if (isFormValid) {
      setIsLoading((state) => !state);
      let finalData = { ...step1Data };

      const formData = new FormData();
      if (add2ndMetric) {
        let data = {
          challenge_metrics: `${part1Data["metric"]},${part2Data["metric"]}`,
          challenge_metadata: JSON.stringify({
            [part1Data["metric"]]: getMetadata(
              part1Data,
              step1Data["challenge_type"]
            ),
            [part2Data["metric"]]: getMetadata(
              part2Data,
              step1Data["challenge_type"]
            ),
          }),
        };
        finalData = { ...finalData, ...data };
      } else {
        let data = {
          challenge_metrics: part1Data["metric"],
          challenge_metadata: JSON.stringify({
            [part1Data["metric"]]: getMetadata(
              part1Data,
              step1Data["challenge_type"]
            ),
          }),
        };
        finalData = { ...finalData, ...data };
      }
      if (finalData.selectAllLocations) {
        finalData.group_ids = [step1Data.brand];
      } else {
        finalData.group_ids = step1Data.locations;
      }

      delete finalData.selectAllLocations;
      delete finalData.locations;

      if (isEditing) {
        formData.append("challenge_id", challenge.challenge_id);
      }
      if (step1Data.challenge_image_url && !selectedBanner) {
        formData.append(
          "challenge_image_url",
          step1Data["challenge_image_url"]
        );
      }

      formData.append("aspect_ratio", finalData["aspect_ratio"]);

      formData.append("challenge_name", finalData["challenge_name"]);
      formData.append("goal_description", finalData["goal_description"]);
      formData.append(
        "start_date",
        typeof finalData["start_date"] === "string"
          ? finalData["start_date"]
          : extractDate(finalData["start_date"])
      );
      formData.append(
        "end_date",
        typeof finalData["end_date"] === "string"
          ? finalData["end_date"]
          : extractDate(finalData["end_date"])
      );
      formData.append("default_challenge", finalData["default_challenge"]);
      formData.append("is_private", finalData["is_private"]);
      formData.append("challenge_metrics", finalData["challenge_metrics"]);
      formData.append("challenge_metadata", finalData["challenge_metadata"]);
      if (finalData["challenge_referral_alias_code"]) {
        formData.append(
          "challenge_referral_alias_code",
          finalData["challenge_referral_alias_code"].toUpperCase()
        );
      }
      formData.append("group_ids", finalData["group_ids"]);

      if (selectedBanner) {
        formData.append("challenge_image", selectedBanner);
      }

      let ch_id = finalData.challenge_id;

      try {
        if (isEditing) {
          await api.put(apiEndpoints.createChallenge, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } else {
          const ch = await api.post(apiEndpoints.createChallenge, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          ch_id = ch.challenge_id;
        }
        dispatch(
          showSnackbar({
            message: `Challenge ${
              isEditing ? "edited" : "created"
            } Successfully`,
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
        dispatch(fetchCurrentChallenges());
        dispatch(setSelectedChallange(null));
        setClearLocations(true);
        // if (isEditing) {
        navigate(`/challenges/${ch_id}`);
        // }
      } catch (e) {
        dispatch(
          showSnackbar({
            message: typeof e === "string" ? e : JSON.stringify(e),
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      } finally {
        setIsLoading((state) => !state);
      }
    }
  };

  useEffect(() => {
    if (challenge && Object.keys(challenge).length) {
      const metaData = challenge.challenge_metadata;
      const mData = Object.keys(metaData).map((key) => {
        const temp = { metric: key };
        if (metaData[key].min_count) {
          if (metaData[key].min_count.per_day) {
            temp.hasPerDayGoal = true;
            temp.perDayGoal = metaData[key].min_count.per_day;
          } else if (metaData[key].min_count.per_week) {
            temp.hasWeeklyGoal = true;
            temp.perDayGoal = metaData[key].min_count.per_week;
          }
        } else {
          temp.hasPerDayGoal = false;
        }

        if (metaData[key].goal_description) {
          temp.goal_description = metaData[key].goal_description;
        }

        if (metaData[key].prize_description) {
          temp.hasPrize = true;
          temp.prize_description = metaData[key].prize_description;
        } else {
          temp.hasPrize = false;
        }

        return temp;
      });

      setMetadata(mData);
      setPart1Data(mData[0]);
      if (mData.length > 1) {
        setAdd2ndMetric(true);
        setPart2Data(mData[1]);
      }
    }
  }, [challenge]);

  return (
    <Box sx={{ marginTop: "24px" }}>
      <Card
        headerColor={palette.background.hotPink}
        title="Metric & prize"
        titleColor={palette.text.white}
      >
        <form>
          <Container padding="8px 0" column>
            <Container padding="0">
              <ContainerItem padding="0" width="345px">
                <Challenge
                  isEditing={isEditing}
                  globalEdit={globalEdit}
                  data={metadata ? metadata[0] : null}
                  part={add2ndMetric ? 1 : 0}
                  handlePartsData={handlePartsData}
                  setIsValid={setIsPart1Valid}
                  setIsWeeklyChallenge={setIsWeeklyChallenge}
                />
              </ContainerItem>

              <ContainerItem margin="0 0 0 90px" padding="0" width="345px">
                {add2ndMetric ? (
                  <Challenge
                    isEditing={isEditing}
                    globalEdit={globalEdit}
                    data={add2ndMetric ? (metadata ? metadata[1] : null) : null}
                    part={add2ndMetric ? 2 : 0}
                    handle2ndMetric={handleAdd2ndMetric}
                    handlePartsData={handlePartsData}
                    setIsValid={setIsPart2Valid}
                    setIsWeeklyChallenge={setIsWeeklyChallenge}
                  />
                ) : (
                  <Box>
                    <Label label="Is this a 2 metric challenge? " />
                    <Button
                      disabled={isEditing}
                      styles={{ marginTop: "8px", width: "160px" }}
                      variant="outlined"
                      label="Add Metric "
                      onClick={() => {
                        handleAdd2ndMetric(true);
                      }}
                    />
                  </Box>
                )}
              </ContainerItem>
            </Container>
            <ContainerItem margin="60px auto 0 auto">
              <Text
                content="Live challenge preview "
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  color: palette.text.black,
                  margin: "0 auto 16px 64px",
                }}
              />
              <ChallengeCard
                banner={previewBanner}
                challenge={step1Data}
                disableClick
              />
            </ContainerItem>

            <ContainerItem flex margin="48px auto 0 auto" padding="0">
              {isEditing && (
                <Button
                  label="Cancel"
                  variant="outlined"
                  styles={{ width: "160px", marginRight: "16px" }}
                  onClick={() => {
                    navigate(`/challenges/${challenge.challenge_id}`);
                    dispatch(setSelectedChallange(null));
                  }}
                />
              )}
              <Button
                disabled={!isFormValid}
                isLoading={isLoading}
                label="Save"
                variant="contained"
                styles={{ width: "160px" }}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </ContainerItem>
          </Container>
        </form>
      </Card>
    </Box>
  );
};

export default React.memo(Step2);
