import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { confirmPasswordReset, getAuth } from "firebase/auth";
import { Text, Card, Loader, Input, Button } from "../../components";
import VerifiedIcon from "@mui/icons-material/Verified";

import { ReactComponent as FitlvlIcon } from "../../assets/fitlvl_logo.svg";
import { palette } from "../../theme";

import fire from "../../services/firebase";

const ResetPassword = ({ oobCode }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetStatus, setResetStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePasswordUpdate = async () => {
    setLoading((prev) => !prev);
    const auth = getAuth(fire);

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setResetStatus("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      if (error.code === "auth/expired-action-code") {
        setResetStatus("The password reset link has expired");
      } else if (error.code === "auth/invalid-action-code") {
        setResetStatus("The password reset link is invalid");
      } else if (error.code === "auth/weak-password") {
        setResetStatus("Password should be of minimum 6 characters");
      } else {
        setResetStatus("Error updating password");
      }
    } finally {
      setNewPassword("");
      setConfirmPassword("");
    }
    setLoading((prev) => !prev);
  };

  useEffect(() => {
    document.title = "FitLvl - Reset Password";
  }, []);

  return (
    <Box
      sx={{
        width: "340px",
        marginTop: "40px",
      }}
    >
      <Card width="100%" contentPadding="20px">
        <div style={{ height: "30px" }} />
        {resetStatus === "Password updated successfully" ? (
          <Box
            sx={{
              height: "400px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <VerifiedIcon
              sx={{
                color: "green",
                fontSize: "100px",
              }}
            />
            <Text
              content="Success"
              sx={{
                fontSize: "36px",
                color: palette.text.black,
                margin: "40px 0",
              }}
            />
            <Text
              content="Password updated successfully"
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text
              content="Password should be of minimun 6 characters"
              sx={{
                textAlign: "center",
              }}
            />

            <div style={{ height: "50px" }} />

            <Input
              label="Enter Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div style={{ height: "20px" }} />

            <Input
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div style={{ height: "10px" }} />
            {newPassword !== confirmPassword &&
              newPassword.length > 0 &&
              confirmPassword.length > 0 && (
                <Text
                  content="Passwords do not match"
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                />
              )}
            <div style={{ height: "40px" }} />

            <Button
              isLoading={loading}
              onClick={handlePasswordUpdate}
              disabled={
                newPassword !== confirmPassword ||
                newPassword.length < 6 ||
                confirmPassword.length < 6
              }
              label="Reset Password"
              variant="contained"
            />

            <div style={{ height: "20px" }} />

            {resetStatus !== "Password updated successfully" && (
              <Text
                content={resetStatus}
                sx={{
                  color: "red",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              />
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default ResetPassword;
