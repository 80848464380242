import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import * as React from "react";

const propTypes = {
  content: PropTypes.string,
  startIcon: PropTypes.node,
  inline: PropTypes.bool,
  sx: PropTypes.object,
  textOverflow: PropTypes.oneOf(["ellipsis", "clip", "unset"]),
  tooltip: PropTypes.bool,
  titleCutoff: PropTypes.number,
  tooltipPlacement: PropTypes.string,
};

const Text = (props) => {
  const overflow = props.textOverflow ? "hidden" : "unset";

  if (!props.content) {
    return null;
  }

  return (
    <>
      {props.content.length > props.titleCutoff ? (
        <Tooltip title={props.content} placement={props.tooltipPlacement}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: overflow,
              ...props.sx,
            }}
          >
            {props.startIcon ? <span>{props.startIcon}</span> : null}
            <span>
              {props.content.length > props.titleCutoff
                ? `${props.content.substring(0, props.titleCutoff)}...`
                : props.content}
            </span>
          </div>
        </Tooltip>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: overflow,
            ...props.sx,
          }}
        >
          <span>{props.startIcon ? props.startIcon : null}</span>
          <span>{props.content.toString()}</span>
        </div>
      )}
    </>
  );
};

Text.propTypes = propTypes;

export default Text;
