import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  ContainerItem,
  Input,
  Label,
  Select,
  Text,
  ToggleButton,
} from "../../../../components";
import { Box } from "@mui/material";
import { palette } from "../../../../theme";
import { Formik } from "formik";
import { units } from "../../../../utils/utils";
import Sleep from "./sleep";

const CHARACTERLIMIT = 90;

const allGoalsOptions = {
  steps: {
    start: 0,
    end: 2000000,
    increment: 10000,
    unit: "steps per day",
  },
  active_minutes: {
    start: 0,
    end: 1440,
    increment: 10,
    unit: "minutes per day",
  },
  distance: {
    start: 0,
    end: 200,
    increment: 0.5,
    unit: "miles per day",
  },
  sleep: {
    start: 0,
    end: 24,
    increment: 1,
    unit: "hours per day",
  },
  pelotonkjs: {
    start: 0,
    end: 2000,
    increment: 1,
    unit: "KJs per day",
  },
  gym_visit: {
    start: 0,
    end: 2,
    increment: 1,
    unit: "gym check-in(s) per day",
  },
  referral: {
    start: 0,
    end: 100,
    increment: 1,
    unit: "referrals per day",
  },
};

const Challenge = ({
  part,
  handle2ndMetric,
  handlePartsData,
  setIsValid,
  data,
  isEditing,
  setIsWeeklyChallenge,
  globalEdit,
}) => {
  const formRef = useRef(null);

  const [initialValues, setInitialValues] = useState({
    metric: "",
    hasPerDayGoal: false,
    perDayGoal: "",
    hasWeeklyGoal: false,
    hasPrize: false,
    prize_description: "",
    goal_description: "",
  });

  const metricOptions = [
    {
      label: "Steps",
      value: "steps",
    },
    {
      label: "Active Minutes",
      value: "active_minutes",
    },
    {
      label: "Distance",
      value: "distance",
    },
    {
      label: "Sleep",
      value: "sleep",
    },
    {
      label: "Peloton Kjs",
      value: "pelotonkjs",
    },
    {
      label: "Gym Check-ins",
      value: "gym_visit",
    },
    {
      label: "Referral",
      value: "referral",
    },
  ];
  if (part === 2) {
    metricOptions.push({ label: "NA", value: "na" });
  }

  const handleChangeOverrided = (handleChange, e) => {
    handleChange(e);
    handlePartsData(part, { [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (data && Object.keys(data).length) {
      setInitialValues(data);
      setIsValid(true);
    }
  }, [data]);

  return (
    <>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.metric) {
            errors.metric = "This field is required";
          }
          if (Object.keys(errors).length) {
            setIsValid(false);
          } else {
            setIsValid(true);
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Container width="100%" padding="8px 0">
            {part ? (
              <Box
                sx={{
                  mx: "auto",
                  mb: "16px",
                }}
              >
                <Text
                  content={`CHALLENGE PART ${part}`}
                  sx={{
                    fontWeight: 700,
                    fontSize: "16px",
                    color: palette.text.black,
                  }}
                />
              </Box>
            ) : null}
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <ContainerItem padding="0" width="100%">
                <Select
                  required
                  readOnly={isEditing && !globalEdit}
                  label={part ? `Select Metric ${part}` : "Select a metric"}
                  name="metric"
                  options={metricOptions}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (e.target.value === "na") {
                      handle2ndMetric(false);
                      return;
                    }
                    handleChangeOverrided(handleChange, e);
                  }}
                  value={values.metric}
                />
              </ContainerItem>
              <ContainerItem padding="24px 0 0 0" width="100%">
                <ToggleButton
                  readOnly={isEditing && !globalEdit}
                  label="Is there a per-day goal? "
                  checked={values.hasPerDayGoal}
                  name="hasPerDayGoal"
                  onChange={(checked) => {
                    setFieldValue("hasPerDayGoal", checked);
                    if (checked) {
                      setFieldValue("hasWeeklyGoal", false);
                    }
                    handlePartsData(part, { hasPerDayGoal: checked });
                  }}
                />
              </ContainerItem>
              <ContainerItem padding="24px 0 0 0" width="100%">
                <ToggleButton
                  readOnly={isEditing && !globalEdit}
                  label="Is there a per-week goal? "
                  checked={values.hasWeeklyGoal}
                  name="hasWeeklyGoal"
                  onChange={(checked) => {
                    setIsWeeklyChallenge(checked);
                    setFieldValue("hasWeeklyGoal", checked);
                    if (checked) {
                      setFieldValue("hasPerDayGoal", false);
                    }
                    handlePartsData(part, { hasWeeklyGoal: checked });
                  }}
                />
              </ContainerItem>
              {values.hasPerDayGoal | values.hasWeeklyGoal ? (
                <>
                  <ContainerItem padding="16px 0 0 0" width="100%">
                    <Label
                      required
                      label={`What is the ${
                        values.hasPerDayGoal ? "per day" : "per week"
                      }  goal? `}
                    />
                    <Box
                      sx={{
                        mt: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ minWidth: "76px" }}>
                        {values.metric === "sleep" ? (
                          <>
                            <Sleep
                              value={values.perDayGoal}
                              handleChange={(e) => {
                                handlePartsData(part, { perDayGoal: e });
                                setFieldValue("perDayGoal", e);
                              }}
                              isEditing={isEditing}
                              globalEdit={globalEdit}
                              width="100%"
                            />
                          </>
                        ) : (
                          <Input
                            readOnly={isEditing && !globalEdit}
                            name="perDayGoal"
                            value={values.perDayGoal}
                            onChange={(e) => {
                              handleChangeOverrided(handleChange, e);
                            }}
                            type="number"
                            onBlur={handleBlur}
                          />
                        )}
                      </Box>
                      <Text
                        content={units(values.metric, values.hasPerDayGoal)}
                        sx={{ marginLeft: "12px" }}
                      />
                    </Box>
                  </ContainerItem>
                  {/* <ContainerItem padding="24px 0 0 0" width="100%">
                    <Input
                      name="goal_description"
                      type="text"
                      label="Describe the goal"
                      hint="NOTE: Character count limited to 90"
                      multiline
                      minRows={4}
                      hintStyles={{ color: palette.text.purple }}
                      value={values.goal_description}
                      onBlur={handleBlur}
                      characterLimit={CHARACTERLIMIT}
                      onChange={(e) => {
                        handleChangeOverrided(handleChange, e);
                      }}
                      error={
                        touched.goal_description && errors.goal_description
                          ? errors.goal_description
                          : null
                      }
                    />
                  </ContainerItem> */}
                </>
              ) : null}
              <ContainerItem padding="24px 0 0 0" width="100%">
                <ToggleButton
                  readOnly={isEditing && !globalEdit}
                  name="hasPrize"
                  label="Is there a prize? "
                  checked={values.hasPrize}
                  onChange={(checked) => {
                    setFieldValue("hasPrize", checked);
                    handlePartsData(part, { hasPrize: checked });
                  }}
                />
              </ContainerItem>
              {values.hasPrize ? (
                <ContainerItem padding="24px 0 0 0" width="100%">
                  <Input
                    required
                    name="prize_description"
                    type="text"
                    label="Describe the prize"
                    hint="NOTE: Character count limited to 90"
                    multiline
                    minRows={4}
                    hintStyles={{ color: palette.text.purple }}
                    value={values.prize_description}
                    onBlur={handleBlur}
                    characterLimit={CHARACTERLIMIT}
                    onChange={(e) => {
                      handleChangeOverrided(handleChange, e);
                    }}
                    error={
                      touched.prize_description && errors.prize_description
                        ? errors.prize_description
                        : null
                    }
                  />
                </ContainerItem>
              ) : null}
            </form>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default Challenge;
