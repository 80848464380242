import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiEndpoints } from "../../../../services/api";

export const fetchBrands = createAsyncThunk("getBrands", async () => {
  let response = await api.get(apiEndpoints.getBrands);
  return response;
});

const initialState = {
  brands: [],
  isLoading: false,
  error: null,
  brandSelected: null,
};

const manageBrandSlice = createSlice({
  name: "manageBrands",
  initialState,
  reducers: {
    setBrandSelected: (state, action) => {
      state.brandSelected = action.payload;
    },
    clearBrandSelected: (state) => {
      state.brandSelected = null;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
    updateBrand: (state, action) => {
      const newBrand = action.payload;
      const oldBrands = [...state.brands];
      let oldBrandIndex = oldBrands.findIndex(
        (b) => b.group_id === newBrand.group_id
      );
      oldBrands[oldBrandIndex] = { ...newBrand };

      state.brands = oldBrands;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.brands = action.payload ?? [];
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectIsBrandsLoading = (state) => state.manageBrands.isLoading;
export const selectBrands = (state) => state.manageBrands.brands;
export const selectBrandSelected = (state) => state.manageBrands.brandSelected;

export const {
  setBrandSelected,
  clearBrandSelected,
  logout: manageBrandsLogout,
  updateBrand,
} = manageBrandSlice.actions;

export default manageBrandSlice.reducer;
