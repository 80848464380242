import React, { useEffect, useState } from "react";
import { Container, ContainerItem } from "../../components";
import CreateAnnouncements from "./createAnnouncements";
import PreviousAnnouncements from "./previousAnnouncements";
import { api, apiEndpoints } from "../../services/api";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchAnnouncements = async () => {
    setIsLoading((state) => !state);
    const response = await api.get(apiEndpoints.manageAnnouncements());
    setAnnouncements(response);
    setIsLoading((state) => !state);
  };

  const deleteAnnouncement = async (id) => {
    setIsLoading((state) => !state);
    try {
      await api.delete(apiEndpoints.manageAnnouncements(id));
      const updatedAnnouncements = announcements.filter(
        (a) => a.announcement_id !== id
      );
      setAnnouncements(updatedAnnouncements);
      dispatch(
        showSnackbar({
          message: "Announcement deleted Successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
    setIsLoading((state) => !state);
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <Container padding={0}>
      <ContainerItem padding={0}>
        <CreateAnnouncements fetchData={fetchAnnouncements} />
      </ContainerItem>
      <ContainerItem padding={0} margin="36px  0 0 0">
        <PreviousAnnouncements
          deleteAnnouncement={deleteAnnouncement}
          announcements={announcements}
          isLoading={isLoading}
        />
      </ContainerItem>
    </Container>
  );
};

export default Announcements;
