import { Box, List } from "@mui/material";
import React from "react";
import NavItem from "./navItem";

const NavList = ({ navItems }) => {
  return (
    <Box sx={{ textAlign: "left", margin: "40px 0 0 24px" }}>
      {navItems.map((navItem) => {
        return <NavItem key={navItem.id} navitem={navItem} />;
      })}
    </Box>
  );
};

export default NavList;
