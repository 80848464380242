import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiEndpoints } from "../../services/api";

export const fetchPosts = createAsyncThunk("postsSlice/fetch", async () => {
  const response = await api.get(apiEndpoints.managePosts());
  return response.results;
});

const initialState = {
  posts: [],
  isPostsLoading: false,
  error: null,
  selectedPost: null,
};

const PostSlice = createSlice({
  name: "postSlice",
  initialState,
  reducers: {
    setSelectedPost: (state, action) => {
      state.selectedPost = action.payload;
    },
    clearSelectedPost: (state) => {
      state.selectedPost = null;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.posts = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectPosts = (state) => state.posts.posts;
export const selectIsPostsLoading = (state) => state.posts.isLoading;
export const selectPostSelected = (state) => state.posts.selectedPost;

export const {
  setSelectedPost,
  clearSelectedPost,
  logout: postsLogout,
} = PostSlice.actions;

export default PostSlice.reducer;
