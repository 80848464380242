import React, { useEffect, useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { PushPin } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";

import Hls from "hls.js";

import ReactPlayer from "react-player/lazy";

import {
  Avatar,
  Container,
  ContainerItem,
  Divider,
  Drawer,
  RadioGroup,
  Text,
} from "../../../../components";
import { palette } from "../../../../theme";
import { POSTTYPES, TabTypes } from "../../../../utils/utils";
import { ReactComponent as PlaceholderImage } from "../../../../assets/placeholderImage.svg";
import { ReactComponent as LikeIcon } from "../../../../assets/like_icon.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/comment_icon.svg";
import { ReactComponent as RightArrow } from "../../../../assets/rightArrow.svg";
import { ReactComponent as InstagramColorIcon } from "../../../../assets/icons8-instagram.svg";

import PollResult from "./pollResult";
import { showSnackbar } from "../../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../../components/snackbar/snackbarTypes";
import ChallengePostCard from "./challengePostCard";
import { setSelectedPost } from "../../postSlice";

const PostCard = ({
  values,
  previewBannerImage,
  fileRef,
  readOnly,
  onDelete,
  handlePinning,
  isUpdating,
  isEditing,
  openComments,
  disableActions,
  previewVideo,
}) => {
  const options = values.options
    ? [...values.options].sort((a, b) => b.votes - a.votes)
    : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [hlsRef, setHlsRef] = useState(null);
  const [domain, setDomain] = useState("");

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const styles = (theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      marginBottom: 24,
      marginLeft: 24,
      marginRight: 60,
    },
    image: {
      marginLeft: 24,
      width: 200,
      height: 200,
    },
    img: {
      display: "block",
      width: 200,
      height: 200,
      maxWidth: "100%",
      maxHeight: "100%",
    },
    detail: {
      marginLeft: 16,
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  const menuOptions = [
    { label: "Copy Post ID", value: "copy" },
    {
      label: values.pinned ? "Unpin Post" : "Pin Post",
      value: values.pinned ? "unpin" : "pin",
    },
    { label: "Delete Post", value: "delete" },
  ];

  if (values.type !== POSTTYPES.CHALLENGE) {
    menuOptions.push({ label: "Edit Post", value: "edit" });
  }

  const handleCopyId = () => {
    navigator.clipboard.writeText(values.id);
    dispatch(
      showSnackbar({
        message: "ID copied",
        type: SNACKBAR_TYPES.SNACKBAR_INFO,
      })
    );
  };

  const handlePinClick = (action) => {
    if (handlePinning && !isUpdating) {
      handlePinning(action, values.id);
    }
  };

  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(true, values.id, values.brand);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditPost = () => {
    let options = [];
    if (values.type === POSTTYPES.POLL) {
      options = values.options.map((o) => {
        return {
          label: o.text,
          value: o.kind,
        };
      });
    }
    // let buttonText = "";
    // let tabDestination = "",
    //   detailDestination = "";

    // if (values.type === POSTTYPES.POST) {
    //   const navParts = values.navigation_details?.navigation_path?.split("/");

    //   if (navParts.length) {
    //     tabDestination = `/${navParts[1]}`;
    //     detailDestination = navParts[2] ?? "";
    //   }
    //   buttonText = values.navigation_details?.button_text;
    // }

    dispatch(
      setSelectedPost({
        ...values,
        options,
      })
    );
    navigate("/posts?tab=create");
  };

  const handleMenuItemClick = (value) => {
    switch (value) {
      case "pin":
        handlePinClick("pin");
        break;
      case "unpin":
        handlePinClick("unpin");
        break;
      case "delete":
        handleDeleteClick();
        break;
      case "edit":
        handleEditPost();
        break;
      case "copy":
        handleCopyId();
        break;
      default:
        handleMenuClose();
    }
  };

  useEffect(() => {
    if (previewVideo && hlsRef?.current) {
      let player = hlsRef.current;
      if (!player.canPlayType("application/vnd.apple.mpegurl")) {
        console.log("hls not supported");
        var hls = new Hls({
          xhrSetup: function (xhr, url) {
            xhr.withCredentials = true;
          },
        });
        hls.loadSource(previewVideo);
        hls.attachMedia(player);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          player.play();
        });
      }
    }
  }, [previewVideo, hlsRef?.current]);

  useEffect(() => {
    if (
      values.detailDestination &&
      values.tabDestination === TabTypes.WEBLINK
    ) {
      try {
        setDomain(new URL(values.detailDestination).hostname);
      } catch (e) {
        setDomain("");
      }
    }
  }, [values.detailDestination, values.tabDestination]);

  return (
    <>
      <Container padding="0" height="fit-content">
        <ContainerItem padding="0" height="fit-content">
          <Box
            onMouseEnter={() => setPlayVideo(true)}
            onMouseLeave={() => setPlayVideo(false)}
            sx={{
              backgroundColor: palette.background.white,
              width: "294px",
              height: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Container width="100%" column padding="0">
              <Container
                width="100%"
                align="center"
                padding="16px 0 0 0"
                justify="space-between"
              >
                <ContainerItem
                  flex
                  padding="0"
                  margin="0 0 0 16px"
                  align="center"
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      // navigate(`/brand/${values.brand}`);
                    }}
                  >
                    <Avatar profile_image={values.profileImage} />
                    <Text
                      titleCutoff={20}
                      tooltip
                      tooltipPlacement="top"
                      content={values.profileName ?? "Fitlvl"}
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        color: palette.text.black,
                        marginLeft: "8px",
                      }}
                    />
                  </Box>
                </ContainerItem>
                {readOnly && (
                  <ContainerItem
                    flex
                    margin="0 16px 0 0"
                    padding="0"
                    align="center"
                  >
                    {values.pinned && (
                      <PushPin
                        style={{
                          cursor: "pointer",
                          width: "16px",
                          height: "16px",
                        }}
                        onClick={() => handlePinClick("unpin")}
                      />
                    )}
                    {values.igPost && (
                      <InstagramColorIcon
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                          marginLeft: "4px",
                        }}
                        onClick={() => {
                          window.open(values.igPostLink, "_blank");
                        }}
                      />
                    )}
                    {!disableActions && (
                      <MoreVertIcon
                        sx={{
                          cursor: "pointer",
                          width: "18px",
                          height: "18px",
                        }}
                        onClick={handleMenuClick}
                      />
                    )}
                  </ContainerItem>
                )}
              </Container>
              <ContainerItem flex align="center" padding="16px 0 8px 16px">
                <Text
                  content={values.caption === "_" ? " " : values.caption}
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: palette.text.black,
                    width: "100%",
                  }}
                />
              </ContainerItem>
              {!(values.type === POSTTYPES.POLL && readOnly) && <Divider />}
              {values.type === POSTTYPES.CHALLENGE ? (
                <ChallengePostCard challenge={values.challenge} />
              ) : values.type === POSTTYPES.POLL ? (
                readOnly ? (
                  <ContainerItem padding="0" width="100%">
                    <PollResult
                      result={options}
                      totalVotes={values.totalVotes}
                    />
                  </ContainerItem>
                ) : (
                  <>
                    <ContainerItem
                      padding="0 0 0 18px"
                      height={values.options.length ? "auto" : "320px"}
                    >
                      <RadioGroup options={values.options} />
                    </ContainerItem>
                  </>
                )
              ) : (
                <>
                  <ContainerItem padding="0" width="100%">
                    {values.type === POSTTYPES.POST &&
                      (isEditing || previewBannerImage) && (
                        <img
                          loading="lazy"
                          src={
                            previewBannerImage
                              ? previewBannerImage
                              : values.banner
                          }
                          alt="Preview"
                          style={{
                            width: "100%",
                            height: "auto",
                            position: "relative",
                          }}
                        />
                      )}
                    {values.type === POSTTYPES.VIDEO &&
                      (isEditing || previewVideo) && (
                        <ReactPlayer
                          ref={setHlsRef}
                          key={values.id}
                          url={previewVideo}
                          width="100%"
                          height="100%"
                          controls
                          muted={!readOnly}
                          playing={playVideo}
                          volume={0.1}
                        />
                      )}
                    {!(
                      isEditing ||
                      previewBannerImage ||
                      previewVideo ||
                      values.type === POSTTYPES.TEXT
                    ) && (
                      <PlaceholderImage
                        style={{
                          backgroundColor: palette.background.white,
                          width: "100%",
                          height: "270px",
                          margin: "0",
                          objectFit: "cover",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!readOnly && fileRef.current) {
                            fileRef.current.click();
                          }
                        }}
                      />
                    )}
                    {values.tabDestination === TabTypes.WEBLINK ? (
                      <Box
                        sx={{
                          width: "294px",
                          marginTop: "-4px",
                          backgroundColor:
                            palette.background.navigationBackground,
                          zIndex: "100",
                          height: "auto",
                          maxHeight: "76px",
                          boxSizing: "border-box",
                          padding: "4px 16px 6px 16px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(values.detailDestination, "_blank");
                        }}
                      >
                        <Text
                          content={domain}
                          sx={{
                            fontSize: "12px",
                            color: palette.text.trueBlack,
                            marginTop: "4px",
                          }}
                        />
                        <Text
                          content={
                            values.buttonText
                              ? values.buttonText.length > 72
                                ? values.buttonText.substring(0, 72) + "..."
                                : values.buttonText
                              : ""
                          }
                          sx={{
                            maxHeight: "36px",
                            lineHeight: "18px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical",
                            "-moz-box-orient": "vertical",

                            fontSize: "14px",
                            color: palette.text.trueBlack,
                            fontWeight: 600,
                            marginTop: "2px",
                          }}
                        />
                      </Box>
                    ) : (
                      values.buttonText && (
                        <Box
                          sx={{
                            width: "294px",
                            marginTop: "-4px",
                            backgroundColor: "#FC3357",
                            zIndex: "100",
                            height: "40px",
                            boxSizing: "border-box",
                            padding: "0 16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text
                            content={values.buttonText}
                            sx={{
                              color: palette.text.white,
                              fontWeight: "600",
                            }}
                          />
                          <RightArrow
                            style={{
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </Box>
                      )
                    )}
                  </ContainerItem>
                </>
              )}
              {values.type !== POSTTYPES.POLL && (
                <>
                  <Divider />
                  <Container
                    padding="14px 18px"
                    flex
                    align="center"
                    justify="space-between"
                    width="90%"
                  >
                    <ContainerItem padding="0" flex align="center">
                      <LikeIcon />
                      <Text
                        content={
                          readOnly
                            ? `${values.counts.like ?? 0}${
                                values.counts.like === 1 ? " Like" : " Likes"
                              }`
                            : "Likes"
                        }
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          color: palette.text.darkGray,
                          marginLeft: "8px",
                        }}
                      />
                    </ContainerItem>
                    <ContainerItem padding="0" flex align="center">
                      <Box
                        onClick={() => {
                          if (!disableActions && openComments) {
                            openComments(values);
                          }
                        }}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CommentIcon
                          style={{ height: "20px", width: "20px" }}
                        />
                        <Text
                          content={
                            readOnly
                              ? `${values.counts.comment ?? 0}${
                                  values.counts.comment === 1
                                    ? " Comment"
                                    : " Comments"
                                }`
                              : "Comments"
                          }
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            color: palette.text.darkGray,
                            marginLeft: "8px",
                          }}
                        />
                      </Box>
                    </ContainerItem>
                  </Container>
                </>
              )}
            </Container>
          </Box>
        </ContainerItem>
        <Menu open={open} anchorEl={anchorEl} onClose={handleMenuClose}>
          {menuOptions.map((o, index) => {
            return (
              <MenuItem
                autoFocus={false}
                key={index}
                onClick={(e) => {
                  handleMenuItemClick(o.value);
                  handleMenuClose();
                }}
              >
                {o.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Container>
    </>
  );
};

export default React.memo(PostCard);
