import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { Card, Loader } from "../../../../components";
import { palette } from "../../../../theme";
import ChallengesList from "./common/challengesList";
import { useSelector } from "react-redux";
import {
  selectIsCurrentChallengesLoading,
  selectIsPastChallengesLoading,
  selectCurrentChallenges,
  selectPastChallenges,
  selectDeletedChallenges,
} from "./manageChallengesSlice";
import { api, apiEndpoints } from "../../../../services/api";

const Challenges = ({ type, brandId }) => {
  const [groupChallenges, setGroupChallenges] = useState([]);
  const [isGroupChallengesLoading, setIsGroupChallengesLoading] =
    useState(false);
  const metadata =
    type === "current"
      ? {
          title: "Current Challenges",
          background: palette.background.lime,
        }
      : type === "completed"
      ? {
          title: "Completed Challenges",
          background: palette.background.yellow,
        }
      : { title: "Deleted Challenges", background: palette.background.crimson };

  const challenges = useSelector(
    type === "current"
      ? selectCurrentChallenges
      : type === "completed"
      ? selectPastChallenges
      : selectDeletedChallenges
  );
  const isLoading = useSelector(
    type === "current"
      ? selectIsCurrentChallengesLoading
      : selectIsPastChallengesLoading
  );

  const fetchGroupChallenges = async () => {
    setIsGroupChallengesLoading((state) => !state);
    const response = await api.get(
      type === "current"
        ? apiEndpoints.getCurrentChallenges(brandId)
        : type === "completed"
        ? apiEndpoints.getPastChallenges(brandId)
        : apiEndpoints.getDeletedChallenges(brandId)
    );
    setGroupChallenges(response ?? []);
    setIsGroupChallengesLoading((state) => !state);
  };

  if (isGroupChallengesLoading || isLoading) {
    <Loader color={metadata.background} />;
  }

  useEffect(() => {
    if (brandId) {
      fetchGroupChallenges();
    }
  }, [brandId]);

  return (
    <Card
      title={metadata.title}
      headerColor={metadata.background}
      titleColor={palette.text.black}
      height="760px"
      contentPadding="20px 60px"
    >
      {isGroupChallengesLoading || isLoading ? (
        <Loader color={metadata.background} />
      ) : (
        <Box sx={{ m: "8px 0px 8px 44px", height: "100%" }}>
          <ChallengesList challenges={brandId ? groupChallenges : challenges} />
        </Box>
      )}
    </Card>
  );
};

export default Challenges;
