import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import Login from "./pages/login";
import Announcements from "./pages/announcements";
import Notifications from "./pages/notifications";
import Challenges from "./pages/challenges";
import ManageUsers from "./pages/manage-users";
import UserDetails from "./pages/user-details";
import ChallengeDetails from "./pages/challenge-details";
import Posts from "./pages/posts";
import Dashboard from "./pages/dashboard";
import ReportBuilder from "./pages/report-builder";
import FirebaseAnalytics from "./pages/firebase-analytics";
import Brands from "./pages/Brand";

import RequireAuth from "./redux/auth/requireAuth";
import { Snackbar } from "./components";
import { getFromLocalStorage } from "./utils/utils";
import BrandDetails from "./pages/brand-details";
import UpdatePassword from "./components/updatePassword";
import Leaderboard from "./pages/leaderboard";
import ChallengeInsights from "./pages/challenge-details/challengeInsights";
import InstagramSettings from "./pages/instagram-settings";
import AuthHandler from "./pages/auth-handler";
import PasswordResetPage from "./pages/auth-handler/resetPassword";
import SchnucksLeaderboard from "./pages/leaderboard-schnucks";

function App() {
  const isLoggedIn = getFromLocalStorage("isLoggedIn");
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = { ...location.state }?.redirectTo;

  return (
    <div className="App">
      <Snackbar />
      <Routes>
        <Route
          index
          path="/"
          element={
            isLoggedIn ? (
              <Navigate to={redirectTo ? redirectTo : "/dashboard"} />
            ) : (
              <Login />
            )
          }
        />
        <Route element={<RequireAuth />}>
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/challenges">
            <Route index element={<Challenges />} />
            <Route path=":challengeId">
              <Route index element={<ChallengeDetails />} />
              <Route path="insights" element={<ChallengeInsights />} />
            </Route>
          </Route>

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/manage-users">
            <Route index element={<ManageUsers />} />
            <Route path=":userId" element={<UserDetails />} />
          </Route>
          <Route path="/firebase-analytics" element={<FirebaseAnalytics />} />
          <Route path="/report-builder" element={<ReportBuilder />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/brand">
            <Route index element={<Brands />} />
            <Route path=":brandId" element={<BrandDetails />} />
          </Route>
          <Route path="/instagram-settings" element={<InstagramSettings />} />
        </Route>
        <Route path="stores/:challengeId" element={<SchnucksLeaderboard />} />
        <Route path=":challengeId" element={<Leaderboard />} />

        <Route path="/auth/handler" element={<AuthHandler />} />
        <Route path="/auth/reset-password" element={<PasswordResetPage />} />
      </Routes>
      <UpdatePassword />
    </div>
  );
}

export default React.memo(App);
