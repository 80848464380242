import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Drawer, Tooltip } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  Avatar,
  Button,
  Container,
  ContainerItem,
  Dialog,
  Loader,
  Text,
} from "../../../../components";
import { api, apiEndpoints } from "../../../../services/api";
import { palette } from "../../../../theme";
import { ReactComponent as CloseIcon } from "../../../../assets/close_icon.svg";
import PostCardV2 from "./postCardV2";
import { ReactComponent as TrashIcon } from "../../../../assets/trash_icon.svg";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../../components/snackbar/snackbarTypes";

const CommentsModal = ({ open, onClose, post }) => {
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [lastId, setLastId] = useState(null);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(null);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const drawerId = "drawerId";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchComments = async (refetch) => {
    setIsLoading((state) => !state);
    if (refetch || hasMore) {
      try {
        const data = await api.get(
          apiEndpoints.postMetaData(post.id, "comment", refetch ? null : lastId)
        );
        if (refetch) {
          setComments(data);
        } else if (data.length) {
          setComments(refetch ? data : [...comments, ...data]);
          setLastId(data[data.length - 1].id);
        } else {
          setHasMore(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setIsLoading((state) => !state);
  };

  const deleteComment = async () => {
    try {
      await api.delete(apiEndpoints.deleteComment(deleteCommentId), {
        reaction_id: deleteCommentId,
      });
      dispatch(
        showSnackbar({
          message: "Comment deleted successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      await fetchComments(true);
      setDeleteCommentId(null);
    } catch (e) {
      dispatch(
        showSnackbar({
          message: e,
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    if (post) {
      fetchComments();
    }
  }, []);

  return (
    <>
      <Dialog
        title={`Post from ${post.profileName}`}
        isOpen={open}
        handleClose={onClose}
        anchor="right"
        width="800px"
        height="auto"
      >
        <Box sx={{ width: "100%", alignItems: "center" }}>
          <div
            style={{
              width: "44%",
              height: "400px",
              display: "inline-block",
              overflow: "scroll",
              padding: "0 0 0 16px",
              // borderRight: `1px solid ${palette.border.mediumGrey}`,
            }}
          >
            <PostCardV2
              values={post}
              previewBannerImage={post.banner}
              disableActions
              readOnly
            />
          </div>
          <div
            id="infiniteDiv"
            style={{
              width: "50%",
              height: "400px",
              display: "inline-block",
              overflow: "scroll",
              marginLeft: "16px",
            }}
          >
            <Text
              content="Comments"
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 700,
                color: palette.text.black,
                marginBottom: "12px",
              }}
            />
            {!isLoading && !comments.length ? (
              <Text content="No Comments Yet!" />
            ) : (
              <InfiniteScroll
                dataLength={comments.length}
                hasMore={hasMore}
                loader={<Loader />}
                next={fetchComments}
                style={{ display: "flex", flexDirection: "column" }}
                scrollableTarget="infiniteDiv"
                scrollThreshold="50px"
              >
                {comments.map((c) => {
                  return (
                    <ContainerItem key={c.id} flex column align="flex-start">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: "4px",
                          cursor: "pointer",
                          // width: "300px"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            navigate(`/manage-users/${c.user.id}`);
                          }}
                        >
                          <Avatar
                            profile_image={c?.user?.data?.profileImage}
                            content={c?.user?.data?.name}
                            noBorder
                          />
                          <Text
                            content={c?.user?.data?.name}
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              color: palette.text.black,
                              marginLeft: "12px",
                            }}
                          />
                        </Box>
                        <Tooltip title="Delete Comment" placement="top-start">
                          <TrashIcon
                            style={{ marginLeft: "16px" }}
                            onClick={() => {
                              setDeleteCommentId(c.id);
                              setOpenDeleteConfirmationModal(true);
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Text
                          content={c?.data?.text}
                          sx={{ paddingLeft: "40px" }}
                        />
                      </Box>
                    </ContainerItem>
                  );
                })}
              </InfiniteScroll>
            )}
          </div>
        </Box>

        {/* </div> */}
      </Dialog>
      <Dialog
        isOpen={openDeleteConfirmationModal}
        handleClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
        bottomActions={
          <Container padding="0" align="center">
            <Button
              label="Cancel"
              styles={{
                width: "auto",
                textAlign: "center",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={async () => {
                setOpenDeleteConfirmationModal(false);
              }}
            />

            <Button
              isLoading={isDeletingComment}
              label="Delete"
              styles={{ width: "auto", textAlign: "center" }}
              variant="contained"
              onClick={async () => {
                setIsDeletingComment((state) => !state);

                await deleteComment();
                setIsDeletingComment((state) => !state);
                setOpenDeleteConfirmationModal(false);
              }}
            />
          </Container>
        }
      >
        <Container>
          <ContainerItem>
            <Text
              sx={{ textAlign: "center" }}
              content="Are you sure you want to delete the comment. This is an irreversible action"
            />
          </ContainerItem>
        </Container>
      </Dialog>
    </>
  );
};

export default React.memo(CommentsModal);
