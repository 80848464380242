import React from "react";
import { Card, Loader, Table } from "../../components";
import { palette } from "../../theme";

const PreviousAnnouncements = ({
  announcements,
  isLoading,
  deleteAnnouncement,
}) => {
  const columns = [
    { label: "Title", id: "title" },
    { label: "Description", id: "description" },
    { label: "Button Label", id: "button_lable" },
    { label: "Navigation Path", id: "navigation_path" },
  ];

  return (
    <Card
      title="Previous Announcements"
      titleColor={palette.text.black}
      headerColor={palette.background.skyBlue}
      key="previous"
      contentPadding="16px"
    >
      {isLoading ? (
        <Loader color={palette.background.skyBlue} />
      ) : (
        <Table
          columns={columns}
          rows={announcements}
          pageCount={10}
          totalCount={announcements.length}
          noSort
          minWidth={500}
          menuOptions={[
            {
              label: "Delete",
              onClick: (row) => {
                deleteAnnouncement(row.announcement_id);
              },
            },
          ]}
        />
      )}
    </Card>
  );
};

export default PreviousAnnouncements;
