import { Box } from "@mui/material";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ChallengeCard from "./challengeCard";
import { palette } from "../../../../../theme";
import "./carousel.css";
import { ReactComponent as LeftArrow } from "../../../../../assets/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../../../assets/rightArrow.svg";
import { Text } from "../../../../../components";

const ChallengesList = ({ challenges }) => {
  const numberOfSets = Math.ceil(challenges.length / 6);
  const [currentIndex, setCurrentIndex] = useState(0);

  const challengeSets = Array.from({ length: numberOfSets }, (_, index) =>
    challenges.slice(index * 6, (index + 1) * 6)
  );

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < challengeSets.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  const renderCustomIndicator = (clickHandler, isSelected, index) => {
    return (
      <li
        className={`custom-indicator ${isSelected ? "selected" : ""}`}
        onClick={clickHandler}
        key={index}
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: isSelected
            ? palette.background.darkGrey
            : palette.background.white,
          borderRadius: "10px",
          marginRight: "4px",
          display: "inline-block",
          cursor: "pointer",
        }}
      />
    );
  };

  if (!challenges.length) {
    return <Text content="No Challenges Yet!" />;
  }

  return (
    <Box sx={{ height: "100%" }} className="carousel-container">
      <LeftArrow
        style={{
          position: "relative",
          left: "-80px",
          top: "280px",
          visibility: currentIndex !== 0 ? "visible" : "hidden",
          cursor: "pointer",
        }}
        onClick={() => {
          handlePrevClick();
        }}
      />

      <RightArrow
        style={{
          position: "relative",
          right: "-760px",
          top: "280px",
          cursor: "pointer",
          visibility:
            currentIndex !== challengeSets.length - 1 ? "visible" : "hidden",
        }}
        onClick={() => {
          handleNextClick();
        }}
      />

      <Carousel
        className="custom-carousel"
        showStatus={false}
        showArrows={false}
        renderIndicator={renderCustomIndicator}
        showThumbs={false}
        selectedItem={currentIndex}
        onChange={handleSlideChange}
      >
        {challengeSets.map((challengeSet, indexSet) => {
          return (
            <div key={indexSet} style={{ display: "flex", flexWrap: "wrap" }}>
              {challengeSet.map((challenge, index) => {
                return (
                  <Box sx={{ mb: "24px", mr: index % 2 === 1 ? "0" : "100px" }}>
                    <ChallengeCard
                      index={index}
                      challenge={challenge}
                      key={challenge.challenge_id}
                    />
                  </Box>
                );
              })}
            </div>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default ChallengesList;
