import React, { useEffect, useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useRive, Layout, Alignment, Fit } from "@rive-app/react-canvas";

import fire from "../../services/firebase";
import background from "../../assets/loginBackground.png";
import { palette } from "../../theme";
import {
  OS,
  getPlatform,
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../utils/utils";
import { saveUser } from "../../redux/auth/authSlice";
import { Input, Button } from "../../components";

const errorMessagesMap = {
  "auth/invalid-email": "Please enter a valid email address.",
  "auth/user-disabled":
    "Your account has been disabled. Please contact support for assistance.",
  "auth/user-not-found":
    "No user found with this email address. Please sign up.",
  "auth/wrong-password": "Incorrect password. Please try again.",
  "auth/email-already-in-use":
    "This email is already in use. Please use a different email or try signing in.",
  "auth/account-exists-with-different-credential":
    "An account with this email already exists, but you used a different sign-in method. Please sign in using that method.",
  "auth/weak-password":
    "Your password is too weak. It should be at least 6 characters long and contain a mix of letters, numbers, and symbols.",
  "auth/requires-recent-login":
    "For security reasons, please log in again to continue.",
  "auth/too-many-requests":
    "Too many unsuccessful sign-in attempts. Please try again later.",
  "auth/operation-not-allowed":
    "This authentication method is not allowed. Please use a different method.",
  "auth/provider-already-linked":
    "This provider is already linked to your account.",
  "auth/invalid-verification-code":
    "The verification code you entered is incorrect. Please double-check and try again.",
};

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [os, setOS] = useState(false);
  const [error, setError] = useState("");
  const [hint, setHint] = useState();
  const [showHttpError, setShowHttpError] = useState(false);
  const [startTimer, setStartTimer] = useState(false);

  const httpFlag = process.env.REACT_APP_SHOW_HTTP_ERROR;
  const location = useLocation();
  const { rive, RiveComponent } = useRive({
    src: "./fitlvl_riv.riv",
    autoplay: true,
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center,
    }),
    onLoad: () => setStartTimer(true),
  });

  const redirectTo = { ...location.state }?.redirectTo;

  const auth = getAuth(fire);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const postSignIn = async (userName) => {
    const token = "Bearer " + getFromLocalStorage("firebaseToken");

    fetch(`${API_BASE_URL}/user/social-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ is_dashboard_login: true }),
    })
      .then((response) => response.json())
      .then((data) => {
        const userDetails = data.token_details;
        const userType = data.user_details.user_type;

        if (["admin", "dev", "brand_admin"].includes(userType)) {
          fetch(`${API_BASE_URL}/user/profile`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userDetails.access}`,
            },
          })
            .then((response) => response.json())
            .then((profileData) => {
              const firstName = String(profileData.first_name);
              const lastName = String(profileData.last_name);
              const data = { profilePic: profileData.profile_pic };
              if (firstName.length || lastName.length) {
                data.name = `${firstName} ${lastName}`.trim();
              } else {
                data.name = profileData.email;
              }
              return data;
            })
            .then((data) => {
              saveToLocalStorage("access", userDetails.access);
              saveToLocalStorage("accessExpiry", userDetails.access_expiry);
              saveToLocalStorage("refresh", userDetails.refresh);
              saveToLocalStorage("refreshExpiry", userDetails.refresh_expiry);
              saveToLocalStorage("userType", userType);
              saveToLocalStorage("userName", data.name);
              saveToLocalStorage("isLoggedIn", true);
              saveToLocalStorage("profilePic", data.profilePic);

              dispatch(
                saveUser({
                  ...userDetails,
                  userType,
                  userName: data.name,
                  isLoggedIn: true,
                })
              );
              navigate(redirectTo ? redirectTo : "/dashboard");
            });
        } else {
          setError(
            "You don’t have enough permissions to access the dashboard. Please contact the admin."
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError("Unable to login");
      });
  };

  const loginUser = () => {
    setLoading(true);
    if (email.length === 0 || password.length === 0) {
      alert("Enter the credentials");
      setLoading(false);
    } else {
      setEmail(email.toLocaleLowerCase());
      signInWithEmailAndPassword(auth, email.toLocaleLowerCase(), password)
        .then((auth) => {
          saveToLocalStorage("firebaseToken", auth.user.accessToken);
          postSignIn(auth.user.displayName);
        })
        .catch((error) => {
          console.log("there's an error loginUser", error);
          setError(errorMessagesMap[error.code] ?? "Unable to Login");
          setLoading(false);
        });
    }
  };

  const signInWithGoogle = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();

    try {
      signInWithPopup(auth, provider)
        .then((result) => {
          setHint(null);
          const user = result.user;
          saveToLocalStorage("firebaseToken", user.accessToken);
          postSignIn(user.displayName);
          // window.location(redirecturi?accesstoke=use)
        })
        .catch((error) => {
          setHint(null);

          console.log("there's an error signInWithGoogle", error);
          if (error.code !== "auth/popup-closed-by-user") {
            setError(errorMessagesMap[error.code] ?? "Unable to Login");
          } else if (error.code === "auth/popup-closed-by-user") {
            setHint("Popup closed");
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const signInWithApple = () => {
    setLoading(true);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    const auth = getAuth();
    try {
      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          saveToLocalStorage("firebaseToken", user.accessToken);
          postSignIn(user.displayName);
        })
        .catch((error) => {
          console.log("there's an error signInWithApple", error);
          if (error.code !== "auth/popup-closed-by-user") {
            setError(errorMessagesMap[error.code] ?? "Unable to Login");
          }
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const os = getPlatform();
    setOS(os);
    const protocol = window.location.protocol;
    setShowHttpError(!protocol.includes("https") && httpFlag === "yes");
  }, []);

  useEffect(() => {
    let timer;
    if (startTimer) {
      timer = setTimeout(() => {
        rive.pause();
      }, 2950);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [startTimer]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0,0,0, 0.5)",
          pointerEvents: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto 0",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <form
          style={{
            maxWidth: "360px",
            margin: "16px",
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <Box>
            {/* <FitlvlIcon
              style={{ width: "300px", height: "100px", paddingBottom: "40px" }}
            /> */}
            <RiveComponent
              style={{ height: "120px", width: "400PX", marginLeft: "-20px" }}
            />

            <Input
              label="Email"
              type="email"
              labelColor={palette.text.white}
              value={email}
              sx={{ color: palette.text.black }}
              onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            />

            <Box sx={{ mb: "16px" }} />

            <Input
              label="Password"
              labelColor={palette.text.white}
              type={showPassword ? "text" : "password"}
              value={password}
              sx={{ color: palette.text.black }}
              onChange={(e) => setPassword(e.target.value)}
              endIcon={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: palette.primary.main }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <Box sx={{ marginTop: "28px" }}>
              <Button
                disabled={showHttpError}
                label="Login"
                onClick={loginUser}
                isLoading={loading}
                variant="contained"
                styles={{ fontSize: "16px", fontWeight: 700 }}
              />
            </Box>
          </Box>
          <Box sx={{ mt: "16px" }}>
            <Button
              disabled={showHttpError}
              label="Sign In with Google"
              styles={{
                backgroundColor: "white",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#fffa",
                },
              }}
              startIcon={
                <img
                  width="20px"
                  height="20px"
                  src={require("../../assets/google_logo_2.png")}
                  alt="Google"
                />
              }
              onClick={() => {
                setHint(
                  "Please check one of your browser tabs to select your google login options. if you are not getting redirected automatically to login options, please check if there is a google login tab already live"
                );
                signInWithGoogle();
              }}
            />
          </Box>
          {os === OS.macOS ? (
            <Box sx={{ mt: "16px" }}>
              <Button
                disabled={showHttpError}
                label="Sign In with Apple"
                styles={{
                  backgroundColor: "black",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
                startIcon={
                  <img
                    width="30px"
                    height="30px"
                    src={require("../../assets/apple_logo_2.png")}
                    alt="Google"
                  />
                }
                onClick={signInWithApple}
              />
            </Box>
          ) : null}
        </form>
        {error && (
          <span
            style={{
              color: "#ff0000",
              fontSize: "24px",
              fontWeight: "bold",
              width: "320px",
              textAlign: "center",
              zIndex: 100,
              backgroundColor: "white",
              borderRadius: "12px",
              padding: "8px 2px",
            }}
          >
            {error}
          </span>
        )}
        {hint && (
          <span
            style={{
              color: "#088F8F",
              fontSize: "24px",
              fontWeight: "bold",
              width: "320px",
              textAlign: "center",
              zIndex: 100,
              backgroundColor: "white",
              borderRadius: "12px",
              padding: "8px 2px",
            }}
          >
            {hint}
          </span>
        )}
        {showHttpError && (
          <>
            <span style={{ color: palette.error.main }}>
              Please open https{" "}
              <a
                style={{
                  textDecoration: "underline",
                  color: palette.error.main,
                }}
                href={
                  process.env.REACT_APP_ENV === "dev"
                    ? "https://www.fitlvl.us/dev"
                    : "https://www.fitlvl.us/"
                }
              >
                here
              </a>
            </span>
          </>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(Login);
