import { Box } from "@mui/material";
import React from "react";
import TabbedHeader from "./tabNavigation";

const TabbedContent = ({ tabs, currentTab }) => {
  const CurrentTab = tabs[currentTab]?.component;

  return <div>{CurrentTab ? <CurrentTab /> : null}</div>;
};

export default TabbedContent;
