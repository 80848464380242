import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Box, createFilterOptions } from "@mui/material";
import moment from "moment-timezone";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";

import {
  Container,
  ContainerItem,
  Card,
  Input,
  Button,
  Label,
  Select,
  Text,
  Hint,
} from "../../../../components";
import { palette } from "../../../../theme";
import { ReactComponent as PlaceholderImage } from "../../../../assets/placeholderImage.svg";
import { api, apiEndpoints } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "../../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../../components/snackbar/snackbarTypes";
import {
  fetchBrands,
  selectBrandSelected,
  clearBrandSelected,
} from "../manage-brand/manageBrandSlice";
import { downloadFile } from "../../../../utils/utils";
import Location from "./location";

const filter = createFilterOptions();

const CreateBrand = () => {
  const [selectedBannerImage, setSelectedBannerImage] = useState(null);
  const [previewBannerImage, setPreviewBannerImage] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noOfLocations, setNoOfLocations] = useState("");
  const [isEditingBanner, setIsEditingBanner] = useState(false);
  const [initialValues, setInitialValues] = useState({
    locations: [],
    group_name: "",
    primary_contact_name: "",
    primary_contact_email: "",
    primary_contact_phone_number: "",
    group_url: "",
    app_link: "",
    integration_type: "na",
    group_logo: null,
  });

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand = useSelector(selectBrandSelected);
  const isEditing = Boolean(brand);
  const hiddenFileInput = React.useRef(null);
  const hiddenLocationFileInput = React.useRef(null);
  const timeZones = moment.tz.names();
  const [fields, setFields] = useState(["name", "address", "timezone"]);
  const [locationSlugAutoCompleteOpen, setLocationSlugAutoCompleteOpen] =
    useState(false);
  const [locationSlugOptionsLoading, setLocationSlugOptionsLoading] =
    useState(false);
  const [locationSlugAutoCompleteOptions, setLocationSlugAutoCompleteOptions] =
    useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slugOptions, setSlugOptions] = useState({});

  const handleBannerImageUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type.includes("image")) {
      setSelectedBannerImage(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewBannerImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setIsEditingBanner(true);
    } else {
      setSelectedBannerImage(null);
      setPreviewBannerImage(null);
    }
  };

  const validate = (values) => {
    const errors = {};

    const validateField = (fieldName, fieldValue) => {
      if (!fieldValue) {
        errors[fieldName] = "This field is required";
      } else {
        delete errors[fieldName];
      }
    };

    Object.entries(values).forEach(([fieldName]) => {
      if (fieldName === "app_link") return;
      validateField(fieldName, values[fieldName]);
    });

    delete errors.integration_type;

    if (values.locations?.length) {
      const locAddresses = [];
      const slugs = [];
      values.locations.forEach((value, index) => {
        const locationErrors = {};
        validateField(`locations[${index}].name`, value.name);
        validateField(`locations[${index}].address`, value.address);
        validateField(`locations[${index}].timezone`, value.timezone);
        if (values.integration_type !== "na") {
          validateField(
            `locations[${index}].location_slug`,
            value.location_slug
          );
        }
        if (locAddresses.includes(value.address)) {
          errors[`locations[${index}].address`] = "Duplicate address";
        } else {
          locAddresses.push(value.address);
        }

        if (values.integration_type !== "na") {
          if (slugs.includes(value.location_slug)) {
            errors[`locations[${index}].location_slug`] = "Duplicate slug";
          } else {
            slugs.push(value.location_slug);
          }
        }

        if (!timeZones.includes(value.timezone)) {
          errors[`locations[${index}].timezone`] =
            "Please enter valid timezone";
        }

        if (Object.keys(locationErrors).length) {
          errors[`locations[${index}]`] = locationErrors;
        } else {
          delete errors[`locations[${index}]`];
        }
      });
    }

    const isValidEmail = /\S+@\S+\.\S+/.test(values.primary_contact_email);
    if (!isValidEmail) {
      errors.primary_contact_email = "Please enter a valid email address";
    }

    const isValidUrl =
      /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/.test(
        values.group_url
      );
    if (!isValidUrl) {
      errors.group_url = "Please enter a valid URL";
    }

    setIsFormValid(Object.keys(errors).length === 0);
    return errors;
  };

  const updateDataPostAPI = (resetForm, group_id) => {
    resetForm();
    setPreviewBannerImage();
    setSelectedBannerImage();
    dispatch(fetchBrands());
    if (group_id) {
      navigate(`/brand/${group_id}`);
    } else navigate("/brand?tab=manage");
  };

  const onEdit = async (values, resetForm) => {
    const editedFields = {};
    if (values.group_name !== brand.group_name) {
      editedFields["group_name"] = values.group_name;
    }
    if (values.primary_contact_email !== brand.group_email) {
      editedFields["group_email"] = values.primary_contact_email;
    }
    if (values.primary_contact_phone_number !== brand.group_contact) {
      editedFields["group_contact"] = values.primary_contact_phone_number;
    }
    if (values.app_link !== brand.app_link) {
      editedFields["app_link"] = values.app_link;
    }
    if (values.primary_contact_name !== brand?.meta_data?.contact_name) {
      if (!editedFields["meta_data"]) {
        editedFields["meta_data"] = {};
      }
      editedFields["meta_data"]["contact_name"] = values.primary_contact_name;
    }
    if (
      values.integration_type !== brand?.meta_data?.integration_type &&
      values.integration_type !== ""
    ) {
      if (!editedFields["meta_data"]) {
        editedFields["meta_data"] = {};
      }
      editedFields["meta_data"]["integration_type"] = values.integration_type;
    }
    if (values.group_url !== brand?.meta_data?.website_url) {
      if (!editedFields["meta_data"]) {
        editedFields["meta_data"] = {};
      }
      editedFields["meta_data"]["website_url"] = values.group_url;
    }

    const formData = new FormData();
    Object.keys(editedFields).forEach((key) => {
      if (key === "meta_data") {
        formData.append(key, JSON.stringify(editedFields[key]));
      } else {
        formData.append(key, editedFields[key]);
      }
    });
    formData.append("group_type", "BRAND");

    if (isEditingBanner) {
      formData.append("group_logo", selectedBannerImage);
    }

    try {
      await api.put(apiEndpoints.editGroup(brand.group_id), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(
        showSnackbar({
          message: `Brand updated successfully`,
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      updateDataPostAPI(resetForm, brand.group_id);
    } catch (e) {
      dispatch(
        showSnackbar({
          message: typeof e === "string" ? e : JSON.stringify(e),
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    setIsLoading((state) => !state);
    if (isEditing) {
      await onEdit(values, resetForm);
    } else {
      const formData = new FormData();
      delete values.group_logo;
      const metadata = {
        contact_name: values.primary_contact_name,
        website_url: values.group_url,
      };
      if (values.integration_type !== "na") {
        metadata.integration_type = values.integration_type;
      }

      formData.append("group_name", values.group_name);
      formData.append("group_contact", values.primary_contact_phone_number);
      formData.append("group_email", values.primary_contact_email);
      formData.append("app_link", values.app_link);
      formData.append("meta_data", JSON.stringify(metadata));
      formData.append("group_type", "BRAND");
      formData.append("locations", JSON.stringify(values.locations));
      formData.append("group_logo", selectedBannerImage);

      try {
        await api.post(apiEndpoints.addGroup, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        dispatch(
          showSnackbar({
            message: `Brand created successfully`,
            type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
          })
        );
        updateDataPostAPI(resetForm);
      } catch (e) {
        dispatch(
          showSnackbar({
            message: typeof e === "string" ? e : JSON.stringify(e),
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
    }

    setIsLoading((state) => !state);
  };

  const clearEditingBrand = () => {
    navigate(`/brand/${brand.group_id}`);
    dispatch(clearBrandSelected());
  };

  const hasEdited = (values) => {
    if (isEditing) {
      const originalData = {
        group_name: brand.group_name || "",
        group_logo: brand.group_logo || "",
        primary_contact_phone_number: brand.group_contact || "",
        group_url: brand.meta_data?.website_url || "",
        integration_type: brand.meta_data?.integration_type || "",
        primary_contact_email: brand.group_email || "",
        primary_contact_name: brand.meta_data?.contact_name || "",
      };
      const newData = { ...values };
      delete newData.locations;

      return !(JSON.stringify(newData) === JSON.stringify(originalData));
    } else return true;
  };

  const handleJSONUpload = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        setNoOfLocations(jsonData.length);
        formRef.current.setFieldValue("locations", jsonData);
      } catch (e) {
        dispatch(
          showSnackbar({
            message: "Invalid JSON File",
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
      // setFiles(e.target.result);
    };
  };

  const handleCSVUpload = (file) => {
    try {
      Papa.parse(file, {
        complete: function (results) {
          if (results.errors && results.errors.length) {
            dispatch(
              showSnackbar({
                message: "Unable to Parse CSV",
                type: SNACKBAR_TYPES.SNACKBAR_ERROR,
              })
            );
          }
          setNoOfLocations(results.data.length);
          formRef.current.setFieldValue("locations", results.data);
        },
        header: true,
        skipEmptyLines: true,
      });
    } catch (e) {
      console.log("error", e);
      dispatch(
        showSnackbar({
          message: "Unable to Parse CSV",
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    // Check the file type to decide the conversion logic
    const fileType = file.type;
    if (fileType === "application/json") {
      // Handle JSON file upload
      handleJSONUpload(file);
    } else if (fileType === "text/csv") {
      // Handle CSV file upload
      handleCSVUpload(file);
    } else {
      alert("Unsupported file type. Please select a JSON or CSV file.");
    }
    e.target.value = "";
  };

  const handleDownload = () => {
    const env = process.env.REACT_APP_ENV;
    const filesToDownload = [
      { fileType: "json", fileName: "sample.json" },
      { fileType: "csv", fileName: "sample.csv" },
    ];

    filesToDownload.forEach((file) => {
      downloadFile(file, env);
    });
  };

  const fetchSlugs = async (address) => {
    setLocationSlugOptionsLoading((state) => true);
    let options = [];
    const slugs = {};
    if (address.length > 3) {
      try {
        const response = await api.get(apiEndpoints.getLocationSlug(address));
        if (response.suggestions) {
          options = response.suggestions.map((item) => {
            slugs[item.address] = item.slug;
            return {
              label: item.address,
              value: item.address,
            };
          });
        }
      } catch (e) {
        console.log("error", e);
      }
    }
    const existingLocationsAddresses = formRef.current.values.locations.map(
      (location) => location.address
    );
    options = options.filter(
      (option) => !existingLocationsAddresses.includes(option.label)
    );
    setLocationSlugAutoCompleteOptions(options);
    setSlugOptions(slugs);
    setLocationSlugOptionsLoading((state) => false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearBrandSelected());
    };
  }, []);

  useEffect(() => {
    if (brand) {
      const initState = {
        group_name: brand.group_name || "",
        group_logo: brand.group_logo || "",
        primary_contact_phone_number: brand.group_contact || "",
        group_url: brand.meta_data?.website_url || "",
        integration_type: brand.meta_data?.integration_type || "",
        primary_contact_email: brand.group_email || "",
        primary_contact_name: brand.meta_data?.contact_name || "",
        app_link: brand?.app_link || "",
      };

      validate(initState);
      setInitialValues(initState);
    }
  }, [brand]);

  return (
    <Box
      style={{
        overflow: "scroll",
        mb: "16px",
        width: "990px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Card
        headerColor={palette.primary.main}
        title="Primary Details"
        titleColor={palette.text.white}
      >
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container padding="8px 0">
                <Container width="345px" padding="0" column>
                  <ContainerItem width="100%" padding="0px 9px 0px 0px">
                    <Input
                      required
                      name="group_name"
                      label="Brand name"
                      type="text"
                      marginTop="26px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.group_name}
                      labelColor={palette.text.black}
                      error={
                        touched.group_name && errors.group_name
                          ? errors.group_name
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      required
                      name="primary_contact_name"
                      label="Brand primary contact name"
                      type="text"
                      marginTop="16px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.primary_contact_name}
                      labelColor={palette.text.black}
                      error={
                        touched.primary_contact_name &&
                        errors.primary_contact_name
                          ? errors.primary_contact_name
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      required
                      name="primary_contact_email"
                      label="Brand primary contact email"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.primary_contact_email}
                      labelColor={palette.text.black}
                      error={
                        touched.primary_contact_email &&
                        errors.primary_contact_email
                          ? errors.primary_contact_email
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      required
                      name="primary_contact_phone_number"
                      label="Brand primary contact phone number"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.primary_contact_phone_number}
                      labelColor={palette.text.black}
                      error={
                        touched.primary_contact_phone_number &&
                        errors.primary_contact_phone_number
                          ? errors.primary_contact_phone_number
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      required
                      name="group_url"
                      label="Brand URL"
                      type="text"
                      marginTop="506px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.group_url}
                      labelColor={palette.text.black}
                      error={
                        touched.group_url && errors.group_url
                          ? errors.group_url
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      name="app_link"
                      label="App URL"
                      type="text"
                      marginTop="506px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.app_link}
                      labelColor={palette.text.black}
                    />
                  </ContainerItem>
                  {!isEditing && (
                    <ContainerItem width="100%" padding="8px 0">
                      <Select
                        name="integration_type"
                        label="Gym Software Provider"
                        type="text"
                        marginTop="506px"
                        onChange={(e) => {
                          if (e.target.value === "na") {
                            setFields(["name", "address", "timezone"]);
                          } else {
                            setFields([
                              "name",
                              "address",
                              "location_slug",
                              "timezone",
                            ]);
                          }
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values.integration_type}
                        labelColor={palette.text.black}
                        options={[
                          { label: "NA", value: "na" },
                          { label: "Mindbody", value: "mindbody" },
                          { label: "Mariana Tek", value: "marianatek" },
                        ]}
                        error={
                          touched.integration_type && errors.integration_type
                            ? errors.integration_type
                            : null
                        }
                      />
                    </ContainerItem>
                  )}
                  {!isEditing && (
                    <>
                      <ContainerItem width="100^" padding="8px 0">
                        <Label label="Select number of brand locations" />
                        <Box sx={{ width: "80px", mt: "8px" }}>
                          <Select
                            name="noOfLocations"
                            options={[
                              {
                                label: "1",
                                value: 1,
                                disabled: isEditing && noOfLocations > 1,
                              },
                              {
                                label: "2",
                                value: 2,
                                disabled: isEditing && noOfLocations > 2,
                              },
                              {
                                label: "3",
                                value: 3,
                                disabled: isEditing && noOfLocations > 3,
                              },
                              {
                                label: "4",
                                value: 4,
                                disabled: isEditing && noOfLocations > 4,
                              },
                              {
                                label: "5",
                                value: 5,
                                disabled: isEditing && noOfLocations > 5,
                              },
                              {
                                label: "6",
                                value: 6,
                                disabled: isEditing && noOfLocations > 6,
                              },
                            ]}
                            onChange={(e) => {
                              setNoOfLocations(e.target.value);
                              const selectedValue = parseInt(e.target.value);
                              const currentLength = values.locations.length;

                              if (selectedValue === currentLength) {
                                return; // No need to make any changes
                              } else if (selectedValue < currentLength) {
                                const newOptions = values.locations.slice(
                                  0,
                                  selectedValue
                                );

                                setFieldValue("locations", newOptions);
                              } else {
                                const newOptions = [];

                                for (
                                  let i = currentLength;
                                  i < selectedValue;
                                  i++
                                ) {
                                  if (values.integration_type === "na") {
                                    newOptions.push({
                                      name: "",
                                      address: "",
                                      timezone: "",
                                    });
                                  } else {
                                    newOptions.push({
                                      name: "",
                                      address: "",
                                      location_slug: "",
                                      timezone: "",
                                    });
                                  }
                                }

                                setFieldValue("locations", [
                                  ...values.locations,
                                  ...newOptions,
                                ]);
                                validate(values);
                              }
                            }}
                            value={noOfLocations}
                          />
                        </Box>
                      </ContainerItem>
                      <Text
                        content="OR"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 700,
                          color: palette.text.black,
                        }}
                      />

                      <ContainerItem
                        flex
                        align="center"
                        width="100%"
                        padding="8px 0"
                      >
                        <Box style={{ display: "inline", width: "60%" }}>
                          <Label label="Upload Locations File(csv or json)" />
                          {"  "}
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: palette.text.purple,
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={handleDownload}
                          >
                            sample files
                          </span>
                        </Box>

                        <Button
                          label="Upload File"
                          variant="contained"
                          styles={{ marginLeft: "24px", width: "140px" }}
                          onClick={() => {
                            hiddenLocationFileInput.current.click();
                          }}
                        />
                        <input
                          name="group_logo"
                          type="file"
                          accept=".json, .csv"
                          ref={hiddenLocationFileInput}
                          style={{ display: "none" }}
                          onChange={handleFileUpload}
                        />
                      </ContainerItem>
                    </>
                  )}

                  {!isEditing &&
                    Array.from({ length: noOfLocations }, (_, index) => {
                      return (
                        <Container
                          padding="16px 0 0 0"
                          key={index}
                          width="100%"
                        >
                          <Label label={`Location ${index + 1}`} />
                          {fields.map((field, fIndex) => {
                            let placeholder = "";
                            switch (fIndex) {
                              case 0:
                                placeholder = "Enter Location name here";
                                break;
                              case 1:
                                placeholder = "Enter Address here";
                                break;
                              case 2:
                                placeholder =
                                  values.integration_type === "na"
                                    ? "Enter Location timezone here"
                                    : "Enter Location Slug here";
                                break;
                              case 3:
                                placeholder = "Enter Location timezone here";
                                break;
                              default:
                                placeholder = "Enter Location here";
                                break;
                            }

                            const fieldName = `locations[${index}].${field}`;

                            return (
                              <Location
                                index={index}
                                field={field}
                                placeholder={placeholder}
                                fieldName={fieldName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                values={values}
                                locationSlugAutoCompleteOptions={
                                  locationSlugAutoCompleteOptions
                                }
                                locationSlugAutoCompleteOpen={
                                  locationSlugAutoCompleteOpen
                                }
                                fetchSlugs={fetchSlugs}
                                setLocationSlugAutoCompleteOpen={
                                  setLocationSlugAutoCompleteOpen
                                }
                                errors={errors}
                                touched={touched}
                                currentIndex={currentIndex}
                                setFieldValue={setFieldValue}
                                locationSlugOptionsLoading={
                                  locationSlugOptionsLoading
                                }
                                slugOptions={slugOptions}
                                setCurrentIndex={setCurrentIndex}
                                setLocationSlugOptionsLoading={
                                  setLocationSlugOptionsLoading
                                }
                                setLocationSlugAutoCompleteOptions={
                                  setLocationSlugAutoCompleteOptions
                                }
                              />
                            );
                          })}
                        </Container>
                      );
                    })}
                </Container>

                <Container padding="0 0 0 120px" width="345px">
                  <ContainerItem width="100%" padding="0" margin="8px 0 0 0">
                    <Container
                      padding="0"
                      align="center"
                      justify="space-between"
                      width="320px"
                    >
                      <ContainerItem padding="0" flex column>
                        <Label label={"Logo"} required />
                        <Hint
                          hint=".png .webp .jpg .jpeg are supported"
                          hintStyles={{ color: palette.text.purple }}
                        />
                      </ContainerItem>

                      <ContainerItem padding="0">
                        <Button
                          label="Upload Photo"
                          variant="contained"
                          onClick={() => {
                            hiddenFileInput.current.click();
                          }}
                        />

                        <input
                          name="group_logo"
                          type="file"
                          // ".png, .jpg, .jpeg"
                          accept=".png, .jpg, .webp, .jpeg*"
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleBannerImageUpload(e);
                            setFieldValue("group_logo", e.target.files[0].name);
                          }}
                        />
                      </ContainerItem>
                    </Container>
                    <Container
                      width="330px"
                      height="242px"
                      margin=" 12px 0 0 0"
                    >
                      {previewBannerImage || brand?.group_logo ? (
                        <img
                          src={
                            isEditingBanner
                              ? previewBannerImage
                              : brand
                              ? brand?.group_logo
                              : previewBannerImage
                          }
                          alt="Preview"
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                            maxHeight: "300px",
                          }}
                        />
                      ) : (
                        <PlaceholderImage
                          style={{
                            backgroundColor: palette.background.white,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            hiddenFileInput.current.click();
                          }}
                        />
                      )}
                    </Container>
                  </ContainerItem>
                </Container>
              </Container>

              <Container
                align="center"
                margin={
                  isEditing ? "16px 280px 0 280px " : "16px 320px 0 320px"
                }
                width="100%"
              >
                {isEditing && (
                  <Button
                    label="Cancel"
                    variant="outlined"
                    styles={{
                      width: "160px",
                      margin: "0 16px 0 0 ",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      clearEditingBrand();
                    }}
                  />
                )}

                <Button
                  type="submit"
                  disabled={!isFormValid || !hasEdited(values)}
                  isLoading={isLoading}
                  label={isEditing ? "Update Brand" : "Create Brand"}
                  variant="contained"
                  styles={{
                    width: "160px",
                    // margin: "20px 340px 0 340px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    // nothing to handle
                  }}
                />
              </Container>
            </form>
          )}
        </Formik>
      </Card>
    </Box>
  );
};

export default React.memo(CreateBrand);
