import { Box } from "@mui/material";
import React from "react";
import { palette } from "../../theme";
import { Avatar, Text } from "../../components";
import { ReactComponent as CrownIcon } from "../../assets/crownIcon.svg";

const RankedCard = ({
  height,
  zIndex = 1,
  margin = "0",
  data,
  rank,
  metric,
}) => {
  const colos = {
    1: palette.background.purple,
    2: palette.background.blue,
    3: palette.background.orange,
  };

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: height,
        m: margin,
        width: "100%",
        boxShadow: "0px 1px 20px 0px #D9D9D9",
        backgroundColor: palette.background.white,
        borderRadius: "30px 30px 8px 10px",
        zIndex: zIndex,
        display: "flex",
        flexDirection: "column",
        paddingBottom: "16px",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ mt: rank === 1 ? "-180px" : "-120px" }}>
        {rank === 1 && <CrownIcon style={{ margin: "0 0 -30px 16px" }} />}
        <Avatar
          sx={{
            width: "160px",
            height: "160px",
            border: `8px solid ${colos[rank]}`,
          }}
          profile_image={data.profile_pic}
          content={data.username}
        />
        <Avatar
          sx={{
            width: "61px",
            height: "61px",
            fontSize: "40px",
            fontWeight: 700,
            marginTop: "-24px",
            fontFamily: "MicroExtend Bold",
          }}
          noBorder
          content={rank.toString()}
          bgcolor={colos[rank]}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: rank === 1 ? "12px" : 0,
          }}
        >
          <Text
            content={
              data.username?.length > 10
                ? `${data.username?.slice(0, 10)}...`
                : data.username
            }
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              color: palette.text.black,
              fontFamily: "Work sans Bold",
            }}
          />
          <Text
            content={Math.floor(data.score).toLocaleString("en-us")}
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: palette.text.purple,
              marginTop: rank === 1 ? "10px" : "-4px",
              fontFamily: "Work sans Bold",
            }}
          />
          <Text
            content={metric}
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: palette.text.purple,
              marginTop: "-8px",
              fontFamily: "Work sans Bold",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RankedCard;
