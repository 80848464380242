import { Box } from "@mui/material";
import React from "react";
import { Text } from "../../../../components";
import { palette } from "../../../../theme";

const ResultItem = ({ row, index }) => {
  return (
    <Box
      sx={{ position: "relative", minHeight: "39px", width: "100%", mb: "2px" }}
    >
      <Box
        sx={{
          position: "relative",
          left: 0,
          width: `${row.votesPercent}%`,
          borderRadius: "10px",
          backgroundColor: index
            ? palette.background.pollLightPink
            : palette.background.pollYellow,
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            padding: "8px",
            width: "240px",
            height: "100%",
          }}
        >
          <Text
            content={`${row.votesPercent.toString()}%`}
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              color: palette.text.black,
              marginRight: "14px",
            }}
          />
          <Text
            content={row.text}
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: palette.text.black,
              marginRight: "14px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ResultItem;
