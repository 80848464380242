import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSelector } from "react-redux";

import { api, apiEndpoints } from "../../services/api";
import {
  Container,
  ContainerItem,
  Input,
  InputDate,
  Loader,
  Select,
} from "../../components";
import Table from "../../components/table";
import { selectManageUsersIsLoading } from "../manage-users/manageUsersSlice";
import { capitalizeString } from "../../utils/utils";

const UserDetails = () => {
  let { userId } = useParams();
  const initialToDate = new Date();
  const [searchParams, setSearchParams] = useSearchParams();
  const isManageUsersLoading = useSelector(selectManageUsersIsLoading);

  const metric = searchParams.get("metric");
  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const challengeName = searchParams.get("challengeName");

  const [totalCount, setTotalCount] = useState();
  const [isTableLoading, setTableIsLoading] = useState(false);
  const [fitnessData, setFitnessData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDatetime, setFromDatetime] = useState(
    fromDateParam
      ? new Date(fromDateParam)
      : new Date(initialToDate - 6 * 24 * 60 * 60 * 1000)
  );
  const [toDatetime, setToDatetime] = useState(
    toDateParam ? new Date(toDateParam) : initialToDate
  );
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [metricTypeFilter, setMetricTypeFilter] = useState(
    metric ? "custom" : "all"
  );

  const [userProfile, setUserProfile] = useState({});
  const groupBy = Boolean(metric);
  const csvFilename = groupBy
    ? `${challengeName}_${userProfile?.username}.csv`
    : `${userProfile?.username}fitness_data.csv`;

  const columns = useMemo(() => {
    return groupBy
      ? [
          { label: "Date", id: "day" },
          { label: "Metric type", id: "metric_type" },
          { label: "Metric value", id: "total_value" },
          { label: "Metric unit", id: "metric_unit" },
          { label: "Source", id: "source" },
        ]
      : [
          { label: "Created at", id: "created_at" },
          { label: "Updated at", id: "updated_at" },
          { label: "From Datetime", id: "from_datetime" },
          { label: "To Datetime", id: "to_datetime" },
          { label: "Metric type", id: "metric_type" },
          { label: "Metric value", id: "metric_value" },
          { label: "Metric unit", id: "metric_unit" },
          { label: "Source", id: "source" },
          { label: "Gym id", id: "gym_id" },
          { label: "Timezone", id: "timezone" },
        ];
  }, [groupBy]);

  const metricTypeOptions = useMemo(() => {
    return groupBy
      ? [
          { label: "ALL METRICS", value: "all" },
          { label: "STEPS", value: "steps" },
          { label: "DISTANCE", value: "distance" },
          { label: "ACTIVE MINUTES", value: "active_minutes" },
          { label: "SLEEP", value: "sleep" },
          { label: "GYM VISIT", value: "gym_visit" },
          { label: "PELOTON KJS", value: "peletonkjs" },
          {
            label: capitalizeString(
              metric
                .split(",")
                .map((m) => capitalizeString(m))
                .join(" & ")
            ),
            value: "custom",
          },
        ]
      : [
          { label: "ALL METRICS", value: "all" },
          { label: "STEPS", value: "steps" },
          { label: "DISTANCE", value: "distance" },
          { label: "ACTIVE MINUTES", value: "active_minutes" },
          { label: "SLEEP", value: "sleep" },
          { label: "GYM VISIT", value: "gym_visit" },
          { label: "PELOTON KJS", value: "peletonkjs" },
        ];
  }, [metric, groupBy]);

  const fetchFitnessData = useCallback(
    async (offset, limit, freshStart = false) => {
      setTableIsLoading((state) => !state);
      if (userProfile && Object.keys(userProfile).length > 0) {
        let fromDate = moment(fromDatetime).format("YYYY-MM-DD");
        let toDate = moment(toDatetime).format("YYYY-MM-DD");
        let metric_type = metricTypeFilter;
        if (metricTypeFilter === "custom") {
          metric_type = searchParams.get("metric").split(",");
        }
        if (metricTypeFilter === "all") {
          metric_type = null;
        }

        try {
          const response = await api.get(
            apiEndpoints.getFitnessData(
              userId,
              fromDate,
              toDate,
              metric_type,
              offset,
              limit,
              groupBy,
              userProfile.timezone
            )
          );

          setTotalCount(response.total_count);
          setFitnessData(
            freshStart ? response.data : fitnessData.concat(response.data)
          );
          setFilteredUserData(
            freshStart ? response.data : fitnessData.concat(response.data)
          );
        } catch (error) {
          console.error("Failed to fetch fitness data:", error);
        }
      }
      setTableIsLoading((state) => !state);
    },
    [
      userId,
      fromDatetime,
      toDatetime,
      metricTypeFilter,
      groupBy,
      fitnessData,
      searchParams,
      userProfile,
    ]
  );

  const onPageChange = (page, rowsPerPage, freshStart) => {
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    fetchFitnessData(offset, limit, freshStart);
  };

  const fetchUserData = async () => {
    await onPageChange(0, 10, true);
  };

  const fetchUserProfile = async () => {
    const response = await api.get(apiEndpoints.getUserProfile(userId));
    setUserProfile(response);
  };

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  useEffect(() => {
    fetchUserData();
  }, [fromDatetime, toDatetime, metricTypeFilter, userId, userProfile]);

  useEffect(() => {
    const data = fitnessData.filter((value) => {
      if (searchTerm === "") {
        return value;
      }

      const searchTermLower = searchTerm.toLowerCase();
      const {
        metric_type,
        source,
        to_datetime,
        from_datetime,
        day,
        metric_unit,
      } = value;

      return (
        metric_type.toLowerCase().includes(searchTermLower) ||
        metric_unit.toLowerCase().includes(searchTermLower) ||
        source.toLowerCase().includes(searchTermLower) ||
        to_datetime?.toLowerCase().includes(searchTermLower) ||
        from_datetime?.toLowerCase().includes(searchTermLower) ||
        day?.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredUserData(data);
  }, [searchTerm]);

  if (isManageUsersLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>First Name : </span>
          <span style={{ paddingLeft: "28px" }}>{userProfile?.first_name}</span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Last Name : </span>
          <span style={{ paddingLeft: "28px" }}>{userProfile?.last_name}</span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>User Name : </span>
          <span style={{ paddingLeft: "28px" }}>{userProfile?.username}</span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Email Id : </span>
          <span style={{ paddingLeft: "28px" }}>{userProfile?.email}</span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Timezone: </span>
          <span style={{ paddingLeft: "28px" }}>{userProfile?.timezone}</span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Date Joined: </span>
          <span style={{ paddingLeft: "28px" }}>
            {moment(userProfile?.date_joined)
              .tz("UTC")
              .format("YYYY-MM-DD HH:mm:ss")}{" "}
            UTC
          </span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Last device shift: </span>
          <span style={{ paddingLeft: "28px" }}>
            {moment(userProfile?.last_device_shift)
              .tz("UTC")
              .format("YYYY-MM-DD HH:mm:ss")}{" "}
            UTC
          </span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Mode of Authetication: </span>
          <span style={{ paddingLeft: "28px" }}>
            {userProfile?.login_source}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "flex-start",
          }}
        >
          <span>Gym Cards: </span>
          {userProfile?.gym_cards?.map((card, index) => (
            <span key={index} style={{ paddingLeft: "28px" }}>
              <a href={card} target="_blank" rel="noreferrer">
                Card {index + 1}
              </a>
            </span>
          ))}
        </div>
      </Box>
      <Container padding="0" margin="16px 0 0 0">
        <ContainerItem padding="8px 8px 8px 0" width="20%">
          <Select
            readOnly={groupBy}
            label="Metric Type"
            onChange={(e) => {
              setMetricTypeFilter(e.target.value);
            }}
            options={metricTypeOptions}
            value={
              groupBy
                ? metricTypeOptions[metricTypeOptions.length - 1].label
                : metricTypeFilter
            }
          />
        </ContainerItem>
        <ContainerItem padding="8px 16px" width="20%">
          <InputDate
            label="From Datetime"
            handleDate={(date) => {
              setFromDatetime(date);
            }}
            maxDate={toDatetime ?? new Date()}
            value={fromDatetime}
          />
        </ContainerItem>
        <ContainerItem padding="8px 16px" width="20%">
          <InputDate
            label="To Datetime"
            handleDate={(date) => setToDatetime(date)}
            minDate={fromDatetime}
            maxDate={new Date()}
            value={toDatetime}
          />
        </ContainerItem>
        <ContainerItem padding="8px 16px" width="20%">
          <Input
            label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </ContainerItem>
        <ContainerItem padding="44px 0 8px 0px" width="10%" height="auto">
          <CSVLink data={filteredUserData} filename={csvFilename}>
            Export to CSV
          </CSVLink>
        </ContainerItem>
      </Container>
      <Table
        columns={columns}
        rows={filteredUserData}
        defaultSortColumn={groupBy ? "day" : "to_datetime"}
        defaultSortOrder="desc"
        totalCount={
          metricTypeFilter !== "all" ? filteredUserData.length : totalCount
        }
        isLoading={isTableLoading}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

export default UserDetails;
