import React from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { palette } from "../../theme";

const TableHeader = ({ columns, orderBy, order, handleSort, noSort }) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => {
          return (
            <TableCell
              sx={{ fontWeight: 700, color: palette.text.black, width: "auto" }}
              key={col.id}
            >
              {noSort ? (
                col.label
              ) : (
                <TableSortLabel
                  sx={{ width: "auto" }}
                  active={orderBy === col.id}
                  direction={orderBy === col.id ? order : "asc"}
                  onClick={() => {
                    handleSort(col.id);
                  }}
                >
                  {col.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
