import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getAuth, applyActionCode } from "firebase/auth";
import { Box } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";

import { ReactComponent as FitlvlIcon } from "../../assets/fitlvl_logo.svg";
import fire from "../../services/firebase";
import { Card, Loader, Text } from "../../components";
import { palette } from "../../theme";
import ResetPassword from "./resetPassword";
import VerifyEmail from "./verifyEmail";

const AuthHandler = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const apiKey = searchParams.get("apiKey");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px 64px",
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <FitlvlIcon style={{ height: "60px", width: "120px" }} />
      </Box>
      <Text
        content={
          mode === "resetPassword" ? "Reset Password" : "Email Verification"
        }
        sx={{ fontSize: "24px", fontWeight: 600 }}
      />
      {mode === "resetPassword" ? (
        <ResetPassword oobCode={oobCode} />
      ) : mode === "verifyEmail" ? (
        <VerifyEmail apiKey={apiKey} oobCode={oobCode} />
      ) : null}
    </Box>
  );
};

export default AuthHandler;
