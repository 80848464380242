import { TableContainer, Table as MuiTable, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableFooter from "./tableFooter";
import Loader from "../common/loader";

const Table = ({
  minWidth,
  columns,
  rows,
  onClick,
  defaultSortColumn,
  defaultSortOrder,
  noSort,
  clickable,
  pageCount,
  menuOptions,
  onPageChange,
  totalCount,
  isLoading,
  searchIncluded,
  readOnly = false,
  rowsPerPageOptions: rowsOptions,
}) => {
  const [orderBy, setOrderBy] = useState(defaultSortColumn ?? columns[0].id);
  const [order, setOrder] = useState(defaultSortOrder ?? "asc");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageCount ?? 10);
  const rowsPerPageOptions = rowsOptions ?? [5, 10, 25];
  columns = [...columns, { label: "", id: "actions" }];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  useEffect(() => {
    if (onPageChange) {
      onPageChange(page, rowsPerPage, true);
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchIncluded & page) {
      setPage(0);
    }
  }, [rows]);

  return (
    <TableContainer
      component={Paper}
      style={{ minWidth: 300, marginTop: "40px" }}
    >
      <MuiTable sx={{ minWidth: minWidth ?? 300 }}>
        <TableHeader
          columns={columns}
          handleSort={handleSort}
          order={order}
          orderBy={orderBy}
          noSort={noSort}
        />

        {isLoading ? (
          <div style={{ marginTop: "16px" }}>
            <Loader />
          </div>
        ) : (
          <TableBody
            rows={rows}
            columns={columns}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            onClick={onClick}
            noSort={noSort}
            clickable={clickable}
            menuOptions={menuOptions}
          />
        )}

        {/* {rows.length > rowsPerPage && ( */}
        {!readOnly && (
          <TableFooter
            rowsPerPageOptions={rowsPerPageOptions}
            rows={rows}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onPageChange={onPageChange}
          />
        )}
        {/* )} */}
      </MuiTable>
    </TableContainer>
  );
};

export default React.memo(Table);
