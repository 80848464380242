import React, { useEffect, useState } from "react";
import moment from "moment";

import { capitalizeString } from "../../utils/utils";
import ApexChart from "./apexChart";
import { useSelector } from "react-redux";
import {
  selectCurrentChallenges,
  selectDeletedChallenges,
  selectPastChallenges,
} from "../challenges/tabs/manage-challenges/manageChallengesSlice";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { api, apiEndpoints } from "../../services/api";
import { set } from "firebase/database";
import { Loader } from "../../components";
import { f } from "html2pdf.js";

const ChallengeInsights = () => {
  let { challengeId } = useParams();

  const currentChallenges = useSelector(selectCurrentChallenges);
  const pastChallenges = useSelector(selectPastChallenges);
  const deletedChallenges = useSelector(selectDeletedChallenges);

  const [challenge, setChallenge] = useState();
  const [data, setData] = useState({});
  const [participants, setParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAgeData = () => {
    const ageGroups = [
      "14 - 18",
      "18 - 24",
      "25 - 34",
      "35 - 44",
      "45 - 54",
      "55 - 64",
      "64 - 100",
    ];
    const types = {
      "14 - 18": 0,
      "18 - 24": 0,
      "25 - 34": 0,
      "35 - 44": 0,
      "45 - 54": 0,
      "55 - 64": 0,
      "64 - 100": 0,
    };
    // fix the order of the age groups

    for (let p of participants) {
      if (types[p.age]) {
        types[p.age] = types[p.age] + 1;
      } else {
        types[p.age] = 1;
      }
    }
    return { categories: Object.keys(types), data: Object.values(types) };
  };

  const getExerciseFrequencyData = () => {
    const types = {};
    for (let p of participants) {
      if (p.exercise_frequency === "") {
        p.exercise_frequency = "NA";
      }
      if (types[p.exercise_frequency]) {
        types[p.exercise_frequency] = types[p.exercise_frequency] + 1;
      } else {
        types[p.exercise_frequency] = 1;
      }
    }
    return { categories: Object.keys(types), data: Object.values(types) };
  };

  const getGenderData = (ch) => {
    const types = {};
    const participantsData = {};
    for (let p of participants) {
      const date = moment(p.created_at).format("DD/MM/YYYY");
      if (participantsData[date]) {
        participantsData[date] = participantsData[date] + 1;
      } else {
        participantsData[date] = 1;
      }
      if (types[p.participant_user__gender]) {
        types[p.participant_user__gender] =
          types[p.participant_user__gender] + 1;
      } else {
        types[p.participant_user__gender] = 1;
      }
    }

    // sort the participants data by date
    const sortedData = Object.keys(participantsData).sort((a, b) => {
      return moment(a, "DD/MM/YYYY") - moment(b, "DD/MM/YYYY");
    });

    let sum = 0;
    for (let i = 0; i < sortedData.length; i++) {
      sum += participantsData[sortedData[i]];
      participantsData[sortedData[i]] = sum;
    }

    let challengeStartDate = moment(ch?.start_date);
    const challengeEndDate = moment();
    const newParticipantsData = {};

    while (challengeStartDate <= challengeEndDate) {
      newParticipantsData[challengeStartDate.format("DD/MM/YYYY")] =
        participantsData[challengeStartDate.format("DD/MM/YYYY")] ?? 0;

      challengeStartDate.add(1, "days");
    }

    const ageData = getAgeData();
    const exerciseFrequencyData = getExerciseFrequencyData();
    console.log("ageData", ageData);
    console.log("exerciseFrequencyData", exerciseFrequencyData);
    return {
      gender: { categories: Object.keys(types), data: Object.values(types) },
      participants: {
        categories: Object.keys(newParticipantsData),
        data: Object.values(newParticipantsData),
      },
      age: { categories: ageData.categories, data: ageData.data },
      exerciseFrequency: {
        categories: exerciseFrequencyData.categories,
        data: exerciseFrequencyData.data,
      },
    };
  };

  const getChallengeParticipants = async (ch) => {
    setIsLoading((prev) => !prev);
    const response = await api.get(
      apiEndpoints.manageParticipant(ch.challenge_id)
    );
    setParticipants(response);
    setIsLoading((prev) => !prev);
  };

  useEffect(() => {
    const ch = [
      ...currentChallenges,
      ...pastChallenges,
      ...deletedChallenges,
    ].find((ch) => ch.challenge_id === challengeId);

    if (ch) {
      setChallenge(ch);
      getChallengeParticipants(ch);
    }
  }, [challengeId]);

  useEffect(() => {
    if (challenge) {
      setData(getGenderData(challenge));
    }
  }, [challenge, participants]);

  if (!challenge) {
    return <></>;
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Challenge Name : </span>
              <span style={{ paddingLeft: "20px" }}>
                {challenge?.challenge_name}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Start Date : </span>
              <span style={{ paddingLeft: "20px" }}>
                {moment(challenge?.start_date).format("MMM DD, YYYY")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>End Date : </span>
              <span style={{ paddingLeft: "20px" }}>
                {moment(challenge?.end_Date).format("MMM DD, YYYY")}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>No of Participants : </span>
              <span style={{ paddingLeft: "20px" }}>
                {challenge?.total_participants}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Group Name : </span>
              <span style={{ paddingLeft: "20px" }}>
                {challenge?.group?.group_name}
              </span>
            </div>
          </Box>
          <div style={{ marginTop: "32px" }}>
            <ApexChart
              type="bar"
              data={data.gender.data}
              categories={data.gender.categories.map((c) =>
                capitalizeString(c)
              )}
              height={300}
              width={600}
              title="Gender Graph"
            />
          </div>

          <div style={{ marginTop: "32px", marginLeft: "8px " }}>
            <ApexChart
              type="bar"
              data={data.age.data}
              categories={data.age.categories}
              width={800}
              height={422}
              title="Age Graph"
            />
          </div>

          <div style={{ marginTop: "32px", marginLeft: "8px " }}>
            <ApexChart
              type="bar"
              data={data.exerciseFrequency.data}
              categories={data.exerciseFrequency.categories}
              width={800}
              height={422}
              title="Exercise Frequency Graph"
            />
          </div>

          <div style={{ marginTop: "32px", marginLeft: "8px " }}>
            <ApexChart
              type="line"
              data={data.participants.data}
              categories={data.participants.categories}
              width={800}
              height={422}
              title="Participants Graph"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChallengeInsights;
