import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiEndpoints } from "../../../../services/api";

const fetchChallenges = async (endpoint, mapType) => {
  let response = await api.get(endpoint);
  return response.map((res) => ({ ...res, type: mapType }));
};

export const fetchCurrentChallenges = createAsyncThunk(
  "manageChallenges/current",
  (group_id) =>
    fetchChallenges(apiEndpoints.getCurrentChallenges(group_id), "current")
);

export const fetchPastChallenges = createAsyncThunk(
  "manageChallenges/past",
  (group_id) =>
    fetchChallenges(apiEndpoints.getPastChallenges(group_id), "completed")
);

export const fetchDeletedChallenges = createAsyncThunk(
  "manageChallenges/deleted",
  (group_id) =>
    fetchChallenges(apiEndpoints.getDeletedChallenges(group_id), "deleted")
);

export const fetchChallenge = createAsyncThunk(
  "manageChallenges/fetchChallenge",
  async ({ id, type }) => {
    let response = await api.get(apiEndpoints.manageChallege(id));
    response.type = type;
    return response;
  }
);

const initialState = {
  isCurrentChallengesLoading: false,
  isPastChallengesLoading: false,
  isDeletedChallengesLoading: false,
  selectedChallenge: null,
  currentChallenges: [],
  pastChallenges: [],
  deletedChallenges: [],
  error: null,
};
const manageChallengesSlice = createSlice({
  name: "manageChallenges",
  initialState,
  reducers: {
    setSelectedChallange: (state, action) => {
      state.selectedChallenge = action.payload;
    },
    clearSelectedChallange: (state) => {
      state.selectedChallenge = null;
    },
    setCurrentChallenges: (state, action) => {
      state.currentChallenges = action.payload;
    },
    setPastChallenges: (state, action) => {
      state.pastChallenges = action.payload;
    },
    deleteChallenge: (state, action) => {
      const updatedChallenges = [];
      let deleteChallenge = {};
      state.currentChallenges.forEach((c) => {
        if (c.challenge_id !== action.payload) {
          updatedChallenges.push(c);
        } else {
          deleteChallenge = c;
        }
      });
      state.currentChallenges = updatedChallenges;
      state.deletedChallenges = [...state.deletedChallenges, deleteChallenge];
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentChallenges.pending, (state) => {
        state.isCurrentChallengesLoading = true;
        state.error = null;
      })
      .addCase(fetchCurrentChallenges.fulfilled, (state, action) => {
        state.currentChallenges = action.payload;
        state.isCurrentChallengesLoading = false;
      })
      .addCase(fetchCurrentChallenges.rejected, (state, action) => {
        state.isCurrentChallengesLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPastChallenges.pending, (state) => {
        state.isPastChallengesLoading = true;
        state.error = null;
      })
      .addCase(fetchPastChallenges.fulfilled, (state, action) => {
        state.pastChallenges = action.payload;
        state.isPastChallengesLoading = false;
      })
      .addCase(fetchPastChallenges.rejected, (state, action) => {
        state.isPastChallengesLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDeletedChallenges.pending, (state) => {
        state.isDeletedChallengesLoading = true;
        state.error = null;
      })
      .addCase(fetchDeletedChallenges.fulfilled, (state, action) => {
        state.deletedChallenges = action.payload;
        state.isDeletedChallengesLoading = false;
      })
      .addCase(fetchDeletedChallenges.rejected, (state, action) => {
        state.isDeletedChallengesLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchChallenge.pending, (state) => {
        state.isPastChallengesLoading = true;
        state.error = null;
      })
      .addCase(fetchChallenge.fulfilled, (state, action) => {
        state.isPastChallengesLoading = false;
        const { challenge_id } = action.payload;
        const currentChallenges = state.currentChallenges;
        const pastChallenges = state.pastChallenges;
        let indexc = currentChallenges.findIndex(
          (item) => item.challenge_id === challenge_id
        );
        if (indexc === -1) {
          const indexp = pastChallenges.findIndex(
            (item) => item.challenge_id === challenge_id
          );
          pastChallenges[indexp] = {
            ...currentChallenges[indexp],
            ...action.payload,
          };
        } else {
          currentChallenges[indexc] = {
            ...currentChallenges[indexc],
            ...action.payload,
          };
        }
        state.currentChallenges = currentChallenges;
        state.pastChallenges = pastChallenges;
      })
      .addCase(fetchChallenge.rejected, (state, action) => {
        state.isPastChallengesLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectSelectedChallange = (state) =>
  state.manageChallenges.selectedChallenge;
export const selectCurrentChallenges = (state) =>
  state.manageChallenges.currentChallenges;
export const selectPastChallenges = (state) =>
  state.manageChallenges.pastChallenges;
export const selectDeletedChallenges = (state) =>
  state.manageChallenges.deletedChallenges;

export const selectIsPastChallengesLoading = (state) =>
  state.manageChallenges.isPastChallengesLoading;
export const selectIsCurrentChallengesLoading = (state) =>
  state.manageChallenges.isCurrentChallengesLoading;
export const selectIsDeletedChallengesLoading = (state) =>
  state.manageChallenges.isDeletedChallengesLoading;

export const {
  setSelectedChallange,
  setCurrentChallenges,
  setPastChallenges,
  deleteChallenge,
  clearSelectedChallange,
  logout: manageChallegesLogout,
} = manageChallengesSlice.actions;

export default manageChallengesSlice.reducer;
