import jwtDecode from "jwt-decode";
const env = process.env.REACT_APP_ENV;

export const OS = {
  macOS: "MACOS",
  windows: "WINDOWS",
  linux: "LINUX",
  unknown: "UNKNOWN",
};

export const POSTTYPES = {
  POST: "post",
  POLL: "poll",
  CHALLENGE: "challenge",
  VIDEO: "video",
  TEXT: "text",
};


export const TabTypes = {
  CHALLENGES: "/challenges",
  // DASHBOARD: "dashboard",
  COMMUNITYFEED: "/community/feed",
  WEBLINK: "/weblink",
  DISCOVER: "/discover",
  SCREENPATH: "/",
};

export const tabOptions = [
  { label: "Challenges Landing Screen", value: TabTypes.CHALLENGES },
  // { label: "Dashboard - Chart", value: TabTypes.DASHBOARD },
  // { label: "Community - Feed ", value: TabTypes.COMMUNITYFEED },
  { label: "Weblink ", value: TabTypes.WEBLINK },
  { label: "Discover ", value: TabTypes.DISCOVER },
  { label: "Screen Path ", value: TabTypes.SCREENPATH },
];

export const dashboardOptions = [
  { label: "Steps Chart", value: "stepsChart" },
  { label: "Active Minutes Chart", value: "activeMinutesChart" },
  { label: "Distance Chart", value: "distanceChart" },
  { label: "Sleep Chart", value: "sleepChart" },
  { label: "Peloton Chart", value: "pelotonChart" },
  { label: "Workouts Chart", value: "workoutsChart" },
];

// const DAYS = {
//   0: "Sun",
//   1: "Mon",
//   2: "Tue",
//   3: "Wed",
//   4: "Thu",
//   5: "Fri",
//   6: "Sat",
// };

const MONTHS = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sept",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export function saveToken(token) {
  localStorage.setItem("token", token);
}

export function saveToLocalStorage(name, value) {
  localStorage.setItem(`${env}_${name}`, value);
}

export function getFromLocalStorage(name) {
  return localStorage.getItem(`${env}_${name}`);
}

export function clearLocalStorage() {
  const storageTokens = [
    "access",
    "accessExpiry",
    "firebaseToken",
    "isLoggedIn",
    "profilePic",
    "refresh",
    "refreshExpiry",
    "userName",
    "userType",
    "currentBrand",
  ];
  for (let i of storageTokens) {
    localStorage.removeItem(`${env}_${i}`);
  }
}

export function logout() {
  clearLocalStorage();
  window.location = env === "dev" ? "/dev" : "/";
}

export function isTokenExpired(token) {
  if (!token) {
    return true;
  }
  const decodedToken = jwtDecode(token);
  const expiryTime = new Date(decodedToken.exp * 1000);
  const currentTime = new Date();
  const secondsRemaining =
    (expiryTime.getTime() - currentTime.getTime()) / 1000;

  console.log("secondsRemaining", secondsRemaining);
  return secondsRemaining < 10;
}

export function getPlatform() {
  const userAgent = navigator.userAgent;
  if (/Win/i.test(userAgent)) {
    return OS.windows;
  }
  if (/Mac/i.test(userAgent)) {
    return OS.macOS;
  }
  if (/Linux/i.test(userAgent)) {
    return OS.linux;
  }
  return OS.unknown;
}

export function getUTCDate() {
  const date = new Date();
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  date.getDate();
  return now_utc;
}

export function getDate(inputDate) {
  const date = new Date(inputDate);
  return ` ${date.getUTCFullYear()}-
    ${date.getUTCMonth()}-
    ${date.getUTCDate()}`;
}

export function extractDate(date) {
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
  var day = date.getDate().toString().padStart(2, "0");

  var formattedDate = year + "-" + month + "-" + day;
  return formattedDate;
}

const convert = (obj) => {
  if (typeof obj === "object") {
    return JSON.stringify(obj);
  }
  return obj;
};

export const convertObjectToString = (data, columnName) =>
  data.map((row) => ({
    ...row,
    [columnName]: convert(row[columnName]),
  }));

export const parseDateString = (startDate, endDate) => {
  if (!startDate && !endDate) {
    return undefined;
  }
  const start = new Date(startDate);
  const end = new Date(endDate);

  return `${
    MONTHS[start.getUTCMonth()]
  } ${start.getUTCDate()}, ${start.getUTCFullYear()} - ${
    MONTHS[end.getUTCMonth()]
  } ${end.getUTCDate()}, ${end.getUTCFullYear()}`;
};

export const capitalizeString = (string) => {
  if (!string) return "";
  const a = string.replace("_", " ");
  return a.charAt(0).toUpperCase() + a.slice(1);
};

// export const arrayToObject = (array, key) => {
//   const obj = array.reduce((acc, [key, value]) => {
//     acc[key] = value;
//     return acc;
//   }, {});
// };

const csvFileToArray = (string) => {
  var array = string.toString().split(" ");
  var data = [];
  for (const r of array) {
    let row = r.toString().split(",");
    data.push(row);
  }

  var heading = data[0];
  var ans_array = [];
  for (var i = 1; i < data.length; i++) {
    var row = data[i];
    var obj = {};
    for (var j = 0; j < heading.length; j++) {
      if (!row[j]) {
        row[j] = "NA";
      }
      obj[heading[j].replaceAll(" ", "_")] = row[j]
        .toString()
        .replaceAll(" ", "_");
    }
    ans_array.push(obj);
  }
};

export const downloadFile = (file, env) => {
  let fileUrl = window.location.origin + "/";
  if (env === "dev" && fileUrl.includes("fitlvl")) {
    fileUrl = fileUrl + "dev/";
  }
  fileUrl = fileUrl + file.fileName;

  const downloadLink = document.createElement("a");
  downloadLink.href = fileUrl;
  downloadLink.download = file.fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadJSONData = (json, name) => {
  const fileData = JSON.stringify(json);
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `${name}.json`;
  link.href = url;
  link.click();
};

export const createArrayOfObjects = (n) => {
  const arr = [];
  for (let i = 1; i <= n; i++) {
    arr.push({ label: "", value: `option_${i}` });
  }

  return arr;
};

const convertArrayOfObjectsToCSV = (data) => {
  const keys = Object.keys(data[0]);
  const csvRows = [];
  csvRows.push(keys.join(","));
  data.forEach((row) => {
    const values = keys.map((key) => row[key]);
    csvRows.push(values.join(","));
  });

  return csvRows.join("\n");
};

export const downloadAsCSVData = (csv, name) => {
  const csvData =
    typeof csv === "string" ? csv : convertArrayOfObjectsToCSV(csv);

  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `${name}.csv`;
  link.href = url;
  link.click();
};

export const units = (metric, perDay) => {
  let unit = "";
  switch (metric) {
    case "steps":
      unit = "steps";
      break;
    case "active_minutes":
      unit = "minutes";
      break;
    case "distance":
      unit = "miles";
      break;
    case "sleep":
      unit = "hours";
      break;
    case "peletonkjs":
      unit = "KJs";
      break;
    case "gym_visit":
      unit = "gym check-in(s)";
      break;
    case "referral":
      unit = "referrals";
      break;
    default:
      unit = "NA";
  }

  switch (Boolean(perDay)) {
    case true:
      unit = `${unit} per day`;
      break;
    case false:
      unit = `${unit} per week`;
      break;
    default:
      unit = "NA";
  }

  return unit;
};

export async function getAspectRatioOfImageFromImageFileOrImageUrl(
  imageFileOrUrl,
  callback
) {
  // calculate aspect ratio of image and return it
  // if imageFileOrUrl is a file, convert it to url
  let imageUrl = imageFileOrUrl;
  if (imageFileOrUrl instanceof File) {
    imageUrl = URL.createObjectURL(imageFileOrUrl);
  }
  const image = new Image();
  image.src = imageUrl;
  image.onload = function () {
    const aspectRatio = image.width / image.height;
    callback(aspectRatio);
    // return aspectRatio;
  };
}
