import React from "react";
import { CardContent as CardContentContainer } from "@mui/material";
import { palette } from "../../theme";

const CardContent = (props) => {
  return (
    <CardContentContainer
      sx={{
        backgroundColor: palette.background.lightGrey,
        padding: props.contentPadding
          ? props.contentPadding
          : "20px 100px 20px 60px",
        maxHeight: props.height,
      }}
    >
      {props.children}
    </CardContentContainer>
  );
};

export default CardContent;
