import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  ContainerItem,
  Input,
  Select,
  Text,
  ToggleButton,
} from "../../components";
import { palette } from "../../theme";
import { Formik } from "formik";
import { api, apiEndpoints } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentChallenges } from "../challenges/tabs/manage-challenges/manageChallengesSlice";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";
import BrandSelection from "../common/brandSelection";
import { Box } from "@mui/material";
import { selectIsBrandAdmin } from "../../redux/auth/authSlice";

const TabTypes = {
  CHALLENGES: "/challenges",
  // DASHBOARD: "dashboard",
  COMMUNITYFEED: "/community/feed",
  WEBLINK: "/weblink",
  DISCOVER: "/discover",
  SCREENPATH: "/",
};

const tabOptions = [
  { label: "Challenges Landing Screen", value: TabTypes.CHALLENGES },
  // { label: "Dashboard - Chart", value: TabTypes.DASHBOARD },
  { label: "Community - Feed ", value: TabTypes.COMMUNITYFEED },
  { label: "Weblink ", value: TabTypes.WEBLINK },
  { label: "Discover ", value: TabTypes.DISCOVER },
  { label: "Screen Path ", value: TabTypes.SCREENPATH },
];

const dashboardOptions = [
  { label: "Steps Chart", value: "stepsChart" },
  { label: "Active Minutes Chart", value: "activeMinutesChart" },
  { label: "Distance Chart", value: "distanceChart" },
  { label: "Sleep Chart", value: "sleepChart" },
  { label: "Peloton Chart", value: "pelotonChart" },
  { label: "Workouts Chart", value: "workoutsChart" },
];

const CreateAnnouncements = ({ fetchData }) => {
  const dispatch = useDispatch();
  const isBrandAdmin = useSelector(selectIsBrandAdmin);
  const fitlvl = process.env.REACT_APP_FITLVL_BRAND;

  const [tabType, setTabType] = useState();
  const [destinationDetailsOptions, setDestinationDetailsOptions] = useState(
    []
  );
  const [clearLocations, setClearLocations] = useState(false);

  const challenges = useSelector(selectCurrentChallenges);

  useEffect(() => {
    if (tabType) {
      let options = [];
      if (tabType === TabTypes.CHALLENGES) {
        options = challenges.map((challenge) => {
          return {
            label: challenge.challenge_name,
            value: challenge.challenge_id,
          };
        });
      } else if (tabType === TabTypes.DASHBOARD) {
        options = dashboardOptions;
      }
      setDestinationDetailsOptions(options);
    }
  }, [tabType]);

  return (
    <Card
      title="Create an announcement"
      headerColor={palette.background.skyBlue}
      titleColor={palette.text.black}
      key="createAnnouncement"
    >
      <Formik
        initialValues={{
          title: "",
          description: "",
          buttonLabel: "",
          tabDestination: null,
          detailDestination: null,
          postId: "",
          pushNotification: false,
          brand: "",
          selectAllLocations: true,
          locations: "",
          targetAllUsers: true,
          user_id: "",
        }}
        validate={(values) => {
          const errors = {};
          if (values.title === "") {
            errors.title = "This field is required";
          }
          if (values.description === "") {
            errors.description = "This field is required";
          }
          if (values.buttonLabel === "") {
            errors.buttonLabel = "This field is required";
          }
          if (!values.tabDestination) {
            errors.tabDestination = "This field is required";
          }
          if (
            (values.tabDestination === TabTypes.WEBLINK ||
              values.tabDestination === TabTypes.SCREENPATH) &&
            !values.detailDestination
          ) {
            errors.detailDestination = "This field is required";
          }
          if (!values.brand) {
            errors.brand = "This field is required";
          }
          return errors;
        }}
        onSubmit={async (values, { resetForm }) => {
          let navPath =
            values.tabDestination === TabTypes.SCREENPATH
              ? ""
              : values.tabDestination;
          if (values.detailDestination) {
            if (values.tabDestination === TabTypes.SCREENPATH) {
              navPath =
                values.detailDestination[0] === "/"
                  ? values.detailDestination
                  : `/${values.detailDestination}`;
            } else {
              navPath = `${navPath}/${values.detailDestination}`;
            }
          }
          const data = {
            title: values.title,
            description: values.description,
            button_lable: values.buttonLabel,
            navigation_path: navPath,
            send_notification: values.pushNotification,
            group_ids: values.selectAllLocations
              ? values.brand
              : values.locations,
          };
          if (!values.targetAllUsers) {
            data.target_user = values.user_id;
          }
          try {
            await api.post(apiEndpoints.createAnnouncement, data);
            dispatch(
              showSnackbar({
                message: "Announcement created successfully",
                type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
              })
            );
            await fetchData();
            resetForm();
            setClearLocations(true);
          } catch (e) {
            console.log(e);
            dispatch(
              showSnackbar({
                message: e,
                type: SNACKBAR_TYPES.SNACKBAR_ERROR,
              })
            );
          }
        }}
        isInitialValid={false}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Container padding="0" width="100%">
              <BrandSelection
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                clearLocations={clearLocations}
                readOnly={isBrandAdmin ? false : !values.targetAllUsers}
                setClearLocations={setClearLocations}
              />
            </Container>
            <Container padding="0">
              <Container padding="0" width="345px">
                <ContainerItem width="100%" padding={0}>
                  <Input
                    required
                    label="Announcement Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && errors.title ? errors.title : null}
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0 0 0">
                  <Input
                    required
                    label="Enter Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    minRows={4}
                    hint="NOTE: Character count limited to 60"
                    characterLimit={60}
                    hintStyles={{ color: palette.text.purple }}
                    error={
                      touched.description && errors.description
                        ? errors.description
                        : null
                    }
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0 0 0">
                  <Input
                    required
                    label="Button Label"
                    name="buttonLabel"
                    value={values.buttonLabel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.buttonLabel && errors.buttonLabel
                        ? errors.buttonLabel
                        : null
                    }
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding={0} margin="24px 0 0 0">
                  <ToggleButton
                    name="pushNotification"
                    label="Send as push notification? "
                    checked={values.pushNotification}
                    onChange={(checked) => {
                      setFieldValue("pushNotification", checked);
                    }}
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding={0} margin="24px 0 0 0">
                  <ToggleButton
                    name="targetAllUsers"
                    label="Target All Users? "
                    checked={values.targetAllUsers}
                    onChange={(checked) => {
                      setFieldValue("targetAllUsers", checked);
                      if (!checked && !isBrandAdmin) {
                        setFieldValue("brand", fitlvl);
                      }
                    }}
                  />
                </ContainerItem>
                {!values.targetAllUsers && (
                  <ContainerItem width="100%" padding="8px 0 0 0">
                    <Input
                      required
                      label="Email"
                      name="user_id"
                      value={values.user_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.user_id && errors.user_id
                          ? errors.user_id
                          : null
                      }
                    />
                  </ContainerItem>
                )}
                <ContainerItem padding="16px 0">
                  <Button
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    label="Publish"
                    type="submit"
                    variant="contained"
                    styles={{ width: "160px" }}
                  />
                </ContainerItem>
              </Container>
              <Container padding="0 0 0 80px" width="345px">
                <ContainerItem padding="0 0 16px 0" width="100%">
                  <Select
                    required
                    name="tabDestination"
                    value={values.tabDestination}
                    label="Select Tab Destination"
                    options={tabOptions}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setTabType(e.target.value);
                    }}
                    error={
                      errors.tabDestination && touched.tabDestination
                        ? errors.tabDestination
                        : null
                    }
                  />
                </ContainerItem>
                {values.tabDestination === TabTypes.SCREENPATH && (
                  <Box
                    onClick={() => {
                      window.open(
                        "https://docs.google.com/document/d/1ybugkogdCJefzWMyqqSPQZllRXR3T35GLjY04ixm_tQ/edit",
                        "blank"
                      );
                    }}
                  >
                    <Text
                      content="Document for list of app routes"
                      sx={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                )}
                {values.tabDestination &&
                  values.tabDestination !== TabTypes.DISCOVER && (
                    <ContainerItem padding="16px 0" width="100%">
                      {tabType === TabTypes.CHALLENGES ? (
                        <Select
                          name="detailDestination"
                          value={values.detailDestination}
                          label="Select Detail Destination "
                          options={destinationDetailsOptions}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={
                            errors.detailDestination &&
                            touched.detailDestination
                              ? errors.detailDestination
                              : null
                          }
                        />
                      ) : (
                        <Input
                          required={
                            (values.tabDestination === TabTypes.WEBLINK ||
                              values.tabDestination === TabTypes.SCREENPATH) &&
                            !values.detailDestination
                          }
                          name="detailDestination"
                          label={
                            tabType === TabTypes.WEBLINK
                              ? "Enter the link"
                              : tabType === TabTypes.SCREENPATH
                              ? "Enter the path"
                              : "Enter the post id"
                          }
                          hint={
                            tabType === TabTypes.WEBLINK
                              ? "Please enter the raw link directly. Do not start with weblink "
                              : undefined
                          }
                          hintStyles={{ color: palette.text.purple }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.detailDestination}
                          error={
                            errors.detailDestination &&
                            touched.detailDestination
                              ? errors.detailDestination
                              : null
                          }
                        />
                      )}
                    </ContainerItem>
                  )}
              </Container>
            </Container>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateAnnouncements;
