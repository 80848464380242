import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import moment from "moment";
import html2pdf from "html2pdf.js";
import InsightsIcon from "@mui/icons-material/Insights";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import { fetchChallenge } from "../challenges/tabs/manage-challenges/manageChallengesSlice";

import LoopIcon from "@mui/icons-material/Loop";

import {
  Button,
  Card,
  Container,
  ContainerItem,
  Dialog,
  Divider,
  Select,
  Text,
} from "../../components";
import { palette } from "../../theme";
import {
  deleteChallenge,
  selectCurrentChallenges,
  selectDeletedChallenges,
  selectPastChallenges,
  setSelectedChallange,
} from "../challenges/tabs/manage-challenges/manageChallengesSlice";
import { ReactComponent as EditIcon } from "../../assets/edit_icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash_icon.svg";
import Metric from "./metric";
import { capitalizeString, downloadJSONData } from "../../utils/utils";
import { Step1Body } from "../challenges/tabs/create-challenge/step1";
import ChallengeCard from "../challenges/tabs/manage-challenges/common/challengeCard";
import Participants from "./participants";
import { api, apiEndpoints } from "../../services/api";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";
import ParticipantsTable from "./participants/participantsTable";
import ApexChart from "./apexChart";

const ReportTemplate = ({
  download = false,
  setDownload,
  leaderboard,
  metrics,
  participants,
}) => {
  let { challengeId } = useParams();
  const pastChallenges = useSelector(selectPastChallenges);
  const [challenge, setChallenge] = useState();
  const [participantCount, setParticipantCount] = useState(0);
  const [image, setImage] = useState();
  const reportRef = useRef();
  const dispatch = useDispatch();

  const getGenderData = () => {
    const types = {};
    const participantsData = {};
    for (let p of participants) {
      const date = moment.utc(p.created_at).format("DD/MM/YYYY");
      if (participantsData[date]) {
        participantsData[date] = participantsData[date] + 1;
      } else {
        participantsData[date] = 1;
      }
      if (types[p.participant_user__gender]) {
        types[p.participant_user__gender] =
          types[p.participant_user__gender] + 1;
      } else {
        types[p.participant_user__gender] = 1;
      }
    }

    let challengeStartDate = moment.utc(challenge?.start_date);
    const challengeEndDate = moment.utc(challenge?.end_date);
    const newParticipantsData = {};

    while (challengeStartDate <= challengeEndDate) {
      newParticipantsData[challengeStartDate.format("DD/MM/YYYY")] =
        participantsData[challengeStartDate.format("DD/MM/YYYY")] ?? 0;

      challengeStartDate.add(1, "days");
    }
    return {
      gender: { categories: Object.keys(types), data: Object.values(types) },
      participants: {
        categories: Object.keys(newParticipantsData),
        data: Object.values(newParticipantsData),
      },
    };
  };

  const genderData = getGenderData();

  const toDataUrl = async function (url, callback) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };

      xhr.open("GET", url);
      xhr.setRequestHeader("crossdomain", true);
      xhr.setRequestHeader("Cache-Control", "no-cache");
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  const handleChallengeImage = async () => {
    const img = await toDataUrl(
      `${
        challenge.challenge_image
      }?cacheblock=true&timestamp=${new Date().toDateString()}`
    );

    setImage(img);
  };

  const downloadPDF = async () => {
    dispatch(
      showSnackbar({
        message: "Report is starting to download",
        type: SNACKBAR_TYPES.SNACKBAR_INFO,
      })
    );
    const reportElement = reportRef.current;

    const pdfOptions = {
      margin: 10,
      filename: `${challenge?.challenge_name}_FitLvl_Challenge_Report.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      await html2pdf().set(pdfOptions).from(reportElement).save();
      dispatch(
        showSnackbar({
          message: "Report downloaded successfully",
          type: SNACKBAR_TYPES.SNACKBAR_INFO,
        })
      );
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  useEffect(() => {
    pastChallenges.forEach((challenge) => {
      if (challengeId === challenge.challenge_id) {
        setChallenge(challenge);
        setParticipantCount(challenge.total_participants);
      }
    });
  }, [challengeId]);

  useEffect(() => {
    if (challenge) {
      handleChallengeImage();
    }
  }, [challenge]);

  useEffect(() => {
    if (download) {
      downloadPDF();
      setDownload(false);
    }
  }, [download]);

  if (!challenge) {
    return <></>;
  }

  return (
    <div ref={reportRef}>
      <h2>FitLvl Challenge Report</h2>
      <p>
        Challenge: <b>{challenge?.challenge_name}</b>
        <br />
        Challenge Dates:{" "}
        <b>{moment.utc(challenge?.start_date).format("MMM DD, YYYY")}</b>&nbsp;
        to&nbsp;
        <b>
          {moment.utc(challenge?.end_date).format("MMM DD, YYYY")}
          <br />
        </b>
        Customer:{" "}
        <b>
          {challenge?.group?.group_name}

          <br />
        </b>
        Metrics:{" "}
        <b>
          {Object.keys(challenge?.challenge_metadata)
            ?.map((m) => capitalizeString(m))
            ?.join(", ")}
          <br />
        </b>
        This Report Created on:
        <b> {moment.utc(challenge?.end_date).format("MMM DD, YYYY")}</b>
      </p>
      {image && (
        <p>
          <img
            style={{ width: 174, height: "216" }}
            src={image}
            alt={`${challenge?.challenge_name} Banner`}
          />
        </p>
      )}
      <p>
        <b>{challenge?.goal_description}</b>
        <br />
        {metrics[0]?.prizeDescription && (
          <span>
            Prize for {metrics[0].name} : <b>{metrics[0]?.prizeDescription}</b>
          </span>
        )}
        <br />
        {metrics[1]?.prizeDescription && (
          <span>
            Prize for {metrics[1].name} : <b>{metrics[1]?.prizeDescription}</b>
          </span>
        )}
      </p>

      {/* <p>
        Number of Users with fitness devices:{" "}
        <b style={{ color: "black" }}>93</b>
        <ul>
          <li>
            Apple: <b style={{ color: "black" }}>89</b>
          </li>
          <li>
            Fitbit:<b style={{ color: "black" }}> 4</b>
          </li>
        </ul>
        Total Referrals: <b style={{ color: "black" }}>2</b>
        <br />
        Total Cumulative Active Minutes:{" "}
        <b style={{ color: "black" }}>35,147 mins</b>
        <br />
        Total number of users who synced data during challenge:{" "}
        <b style={{ color: "black" }}>45</b> (27 synced some data & 18 synced
        0s)
        <br />
        Total number of days where users synced their data for more than 30min
        in a day: <b style={{ color: "black" }}>331 days</b>
      </p> */}

      <p>
        Number of Participants:{" "}
        <b style={{ color: "black" }}>{participantCount.toString()}</b>
      </p>
      <p style={{ marginBottom: "-10px", marginTop: "4px" }}>
        Leaderboard (showing top 10 finishers; more available via Export):
      </p>
      <ParticipantsTable
        data={leaderboard.rows.slice(0, 10)}
        tableColumns={leaderboard.columns}
        isTableLoading={false}
        readOnly
      />
      <div class="html2pdf__page-break"></div>
      <div style={{ marginTop: "32px" }}>
        <ApexChart
          type="bar"
          data={genderData.gender.data}
          categories={genderData.gender.categories.map((c) =>
            capitalizeString(c)
          )}
          height={300}
          width={300}
          title="Gender Graph"
        />
      </div>

      <div style={{ marginTop: "32px", marginLeft: "8px " }}>
        <ApexChart
          type="line"
          data={genderData.participants.data}
          categories={genderData.participants.categories}
          width={680}
          height={422}
          title="Participants Graph"
        />
      </div>
    </div>
  );
};

const ChallengeDetails = () => {
  let { challengeId } = useParams();
  const branchIOAPIKEY = process.env.REACT_APP_BRANCHIO_API_KEY;
  const branchIOAPISECRET = process.env.REACT_APP_BRANCHIO_API_KEY_SECRET;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [challenge, setChallenge] = useState();
  const [challengeOptions, setChallengeOptions] = useState([]);
  const [viewList, setViewList] = useState(false);
  const [deleteChallengeModal, setDeleteChallengeModal] = useState(false);
  const [isDeletingChallenge, setIsDeletingChallenge] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [canDeleteOrEdit, setCanEditOrDelete] = useState(false);
  const [leaderboard, setLeaderboard] = useState({ rows: [], columns: [] });
  const [downloadReport, setDownloadReport] = useState(false);
  const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(true);
  const [participants, setParticipants] = useState([]);

  const currentChallenges = useSelector(selectCurrentChallenges);
  const pastChallenges = useSelector(selectPastChallenges);
  const deletedChallenges = useSelector(selectDeletedChallenges);
  const challenges = [
    ...currentChallenges,
    ...pastChallenges,
    ...deletedChallenges,
  ];

  const isFutureChallenge = new Date(challenge?.start_date) > new Date();
  const env = process.env.REACT_APP_ENV;

  const handleDeleteChallenge = async (id) => {
    setIsDeletingChallenge((state) => !state);
    try {
      await api.delete(apiEndpoints.manageChallege(id));
      dispatch(
        showSnackbar({
          message: "Challenge deleted Successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      dispatch(deleteChallenge(id));
      navigate("/challenges?tab=manage");
    } catch (e) {
      dispatch(
        showSnackbar({
          message: typeof e === "string" ? e : JSON.stringify(e),
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    } finally {
      setIsDeletingChallenge((state) => !state);
    }
  };

  const handleChallengeChange = (e) => {
    const ch = challenges.find(
      (challenge) => challenge.challenge_id === e.target.value
    );
    if (ch) {
      setViewList(false);
      navigate(`/challenges/${ch.challenge_id}`);
    }
  };

  const updateChallengeReferralLink = async (link, id) => {
    const formData = new FormData();
    formData.append("challenge_id", id);
    formData.append("challenge_referral_link", link);
    await api.put(apiEndpoints.createChallenge, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const recalculateChallengeScore = async (id) => {
    dispatch(
      showSnackbar({
        message: "Recalculating Challenge Score",
        type: SNACKBAR_TYPES.SNACKBAR_INFO,
      })
    );

    await api.get(apiEndpoints.recalculateChallengeScore(id));

    dispatch(
      showSnackbar({
        message: "Recalculated Challenge Score Successfully",
        type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
      })
    );

    dispatch(
      fetchChallenge({ id: challenge.challenge_id, type: challenge.type })
    );
  };

  const updateBranchIO = async () => {
    const options = {
      method: "PUT",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        branch_key: branchIOAPIKEY,
        branch_secret: branchIOAPISECRET,
        data: {
          referringChallengeID: challenge.challenge_id,
          referringChallengeName: challenge.challenge_name,
          isCustomized: "true",
          brandName: challenge.group.group_name,
          brandID: challenge.group.group_id,
          challengeType: "per_week",
        },
      }),
    };

    const endpoint = "https://api2.branch.io/v1/url";

    // encode the challenge referral link to be used as a query parameter
    const encodedReferralLink = encodeURIComponent(
      challenge.challenge_referral_link
    );
    const finalEndpoint = `${endpoint}?url=${encodedReferralLink}`;

    fetch(finalEndpoint, options)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  const getChallengeParticipants = async (ch) => {
    // setIsLoading((prev) => !prev);
    const response = await api.get(
      apiEndpoints.manageParticipant(ch.challenge_id)
    );
    setParticipants(response);
    // setIsLoading((prev) => !prev);
  };

  useEffect(() => {
    if (!challenge?.challenge_referral_link) {
      window.branch.init(branchIOAPIKEY, (err, data) => {
        if (err) {
          console.error("Error initializing Branch", err);
          return;
        }

        const metrics = challenge?.challenge_metrics.split(",")[0];

        let ctype = "total_score";
        if (challenge?.challenge_metadata[metrics]?.min_count?.per_day) {
          ctype = "per_day";
        }
        if (challenge?.challenge_metadata[metrics]?.min_count?.per_week) {
          ctype = "per_week";
        }
        var branchLinkProperties = {
          campaign: "Challenge Referral",
          feature: "referrals",
          data: {
            referringChallengeID: challenge.challenge_id,
            referringChallengeName: challenge.challenge_name,
            brandName: challenge.group.group_name,
            brandID: challenge.group.group_id,
            challengeType: ctype,
          },
        };

        window.branch.link(branchLinkProperties, function (err, link) {
          if (!err) {
            setChallenge({ ...challenge, challenge_referral_link: link });
            updateChallengeReferralLink(link, challenge.challenge_id);
          }
          if (err) {
            console.log("final error", err);
          }
        });
      });
    }
  }, [challenge]);

  useEffect(() => {
    if (challenge) {
      getChallengeParticipants(challenge);
      const metaData = challenge.challenge_metadata;
      const metrics = Object.keys(metaData).map((key, index) => {
        const hasMinCount = Boolean(metaData[key].min_count);
        let hasPerDayGoal = false;
        let hasWeeklyGoal = false;
        if (hasMinCount) {
          hasPerDayGoal =
            hasMinCount && Boolean(metaData[key].min_count?.per_day);
          hasWeeklyGoal =
            hasMinCount && Boolean(metaData[key].min_count.per_week);
        }
        const hasPrize = Boolean(metaData[key].prize_description);

        const metric = {
          name: capitalizeString(key),
          hasPerDayGoal,
          hasWeeklyGoal,
          hasPrize,
        };
        if (hasPerDayGoal) {
          metric.perDayGoal = metaData[key].min_count.per_day;
          metric.goalDescription = metaData[key].goal_description;
        }
        if (hasWeeklyGoal) {
          metric.perDayGoal = metaData[key].min_count.per_week;
          metric.goalDescription = metaData[key].goal_description;
        }
        if (hasPrize) {
          metric.prizeDescription = metaData[key].prize_description;
        }
        return metric;
      });

      setMetrics(metrics);
      // updateBranchIO();
    }
  }, [challenge]);

  useEffect(() => {
    const options = [];
    let ch = null;
    [...currentChallenges, ...pastChallenges, ...deletedChallenges].forEach(
      (challenge) => {
        if (challengeId === challenge.challenge_id) {
          ch = { ...challenge };

          if (challenge.type !== "current") {
            setCanEditOrDelete(false);
          } else if (process.env.REACT_APP_DELETE_CHALLENGE_FLAG === "true") {
            setCanEditOrDelete(true);
          }
        }

        options.push({
          label: challenge.challenge_name,
          value: challenge.challenge_id,
          type: challenge.type,
        });
      }
    );
    setChallenge(ch);
    const newOptions = options.filter((o) => o.type === ch.type);
    setChallengeOptions(newOptions);
  }, [challengeId]);

  if (!challenge) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "24px 0 0 0",
        }}
      >
        <Text
          content="Challenge not Found"
          sx={{
            color: palette.text.black,
            fontSize: "16px",
            fontWeight: 700,
            width: "fit-content",
          }}
        />
      </div>
    );
  }

  return (
    <>
      <Container padding="0 0 40px 0" column>
        <ContainerItem width="100%" padding="0" margin="0 0 24px 0">
          <Container
            padding="0"
            width="990px"
            align="center"
            justify="flex-end"
          >
            <ContainerItem padding="0 24px 0 0">
              <Text
                content="Find a different challenge"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                }}
              />
            </ContainerItem>
            <ContainerItem padding="0" width="345px">
              <Select
                placeholder="Select"
                options={challengeOptions}
                onChange={handleChallengeChange}
                value={challenge?.challenge_id}
                name="challenge"
              />
            </ContainerItem>
          </Container>
        </ContainerItem>
        <ContainerItem padding="0">
          <Card
            title={challenge.challenge_name}
            headerColor={
              challenge.type === "current"
                ? palette.background.lime
                : challenge.type === "completed"
                ? palette.background.yellow
                : palette.background.crimson
            }
            subtitle={
              challenge.type === "current"
                ? "THIS CHALLENGE IS ACTIVE"
                : challenge.type === "completed"
                ? "THIS CHALLENGE HAS ENDED"
                : "THIS CHALLENGE IS DELETED"
            }
            actions={
              <Container padding="8px 24px">
                {challenge?.type === "current" && (
                  <ContainerItem textAlign="center" padding="0">
                    <Box
                      sx={{
                        cursor: isLeaderboardLoading ? "progress" : "pointer",
                        color: palette.text.black,
                      }}
                      onClick={() => {
                        let host = window.location.origin;
                        if (env === "dev") {
                          host = host + "/dev";
                        }
                        host = host + `/${challenge.challenge_referral_code}`;
                        window.open(host, "_blank");
                        navigate(`/${challenge.challenge_referral_code}`);
                      }}
                    >
                      <WorkspacePremiumRoundedIcon
                        sx={{
                          fontSize: "24px",
                        }}
                      />
                      <Text
                        content="Leaderboard"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color:
                            challenge.type === "deleted"
                              ? palette.text.white
                              : palette.text.black,
                          marginTop: "-6px",
                          marginRight: "6px",
                        }}
                      />
                    </Box>
                  </ContainerItem>
                )}
                {challenge?.type === "completed" && (
                  <ContainerItem textAlign="center" padding="0">
                    <Box
                      sx={{
                        cursor: isLeaderboardLoading ? "progress" : "pointer",
                        color: palette.text.black,
                      }}
                      onClick={() => {
                        if (!isLeaderboardLoading) {
                          setDownloadReport(true);
                        }
                      }}
                    >
                      <AssessmentIcon
                        sx={{
                          fontSize: "24px",
                        }}
                      />
                      <Text
                        content="Report"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color:
                            challenge.type === "deleted"
                              ? palette.text.white
                              : palette.text.black,
                          marginTop: "-6px",
                        }}
                      />
                    </Box>
                  </ContainerItem>
                )}
                {challenge?.type === "current" && (
                  <ContainerItem textAlign="center" padding="0">
                    <Box
                      sx={{
                        cursor: isLeaderboardLoading ? "progress" : "pointer",
                        color: palette.text.black,
                      }}
                      onClick={() => {
                        navigate(`insights`);
                      }}
                    >
                      <InsightsIcon
                        sx={{
                          fontSize: "24px",
                        }}
                      />
                      <Text
                        content="Insights"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color:
                            challenge.type === "deleted"
                              ? palette.text.white
                              : palette.text.black,
                          marginTop: "-6px",
                        }}
                      />
                    </Box>
                  </ContainerItem>
                )}
                {challenge?.type !== "deleted" && (
                  <ContainerItem textAlign="center" padding="0 0 0 10px">
                    <Box
                      sx={{
                        cursor: isLeaderboardLoading ? "progress" : "pointer",
                        color: palette.text.black,
                      }}
                      onClick={() => {
                        recalculateChallengeScore(challenge.challenge_id);
                      }}
                    >
                      <LoopIcon
                        sx={{
                          fontSize: "24px",
                        }}
                      />
                      <Text
                        content="Recalculate"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color:
                            challenge.type === "deleted"
                              ? palette.text.white
                              : palette.text.black,
                          marginTop: "-6px",
                        }}
                      />
                    </Box>
                  </ContainerItem>
                )}
                <ContainerItem textAlign="center" padding="0 12px 0 10px">
                  <Box
                    sx={{ cursor: "pointer", color: palette.text.black }}
                    onClick={() => {
                      const jsonData = {
                        ...challenge,
                        participants: [...challenge.participants],
                      };
                      delete jsonData.participants;
                      downloadJSONData(jsonData, challenge.challenge_name);
                    }}
                  >
                    <FileDownloadOutlinedIcon
                      sx={{
                        fontSize: "24px",
                      }}
                    />
                    <Text
                      content="Export"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color:
                          challenge.type === "deleted"
                            ? palette.text.white
                            : palette.text.black,
                        marginTop: "-6px",
                      }}
                    />
                  </Box>
                </ContainerItem>
                <ContainerItem textAlign="center" padding="0">
                  <Box
                    sx={{ cursor: canDeleteOrEdit ? "pointer" : "not-allowed" }}
                    onClick={() => {
                      if (canDeleteOrEdit) {
                        dispatch(setSelectedChallange(challenge));
                        navigate("/challenges?tab=create");
                      }
                    }}
                  >
                    <EditIcon />
                    <Text
                      content="edit"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color:
                          challenge.type === "deleted"
                            ? palette.text.white
                            : palette.text.black,
                      }}
                    />
                  </Box>
                </ContainerItem>
                <ContainerItem textAlign="center" padding="0 0 0 24px">
                  <Box
                    sx={{ cursor: canDeleteOrEdit ? "pointer" : "not-allowed" }}
                    onClick={() => {
                      if (canDeleteOrEdit) {
                        setDeleteChallengeModal(true);
                      }
                    }}
                  >
                    <TrashIcon />
                    <Text
                      content="delete"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color:
                          challenge.type === "deleted"
                            ? palette.text.white
                            : palette.text.black,
                      }}
                    />
                  </Box>
                </ContainerItem>
              </Container>
            }
          >
            <Step1Body challenge={challenge} readOnly />
            <div style={{ marginTop: "24px" }} />
            <Divider margin="0 -16px" color={palette.border.black} />
            <div style={{ marginTop: "32px" }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {metrics.map((metric, index) => (
                <Metric
                  metric={metric}
                  key={index}
                  index={index}
                  isDoubleMetric={metrics.length > 1}
                />
              ))}
            </Box>
            <Box
              sx={{
                margin: "32px auto 0 auto",
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text
                content="Live challenge preview "
                sx={{
                  fontWeight: 700,
                  width: "fit-content",
                  fontSize: "16px",
                  color: palette.text.black,
                  margin: "0 0 24px 0",
                }}
              />
              <ChallengeCard challenge={challenge} />
            </Box>
            <div style={{ marginTop: "48px" }} />
            <Divider margin="0 -16px" color={palette.border.black} />
            <div style={{ marginTop: "32px" }} />
            <div style={{ position: "absolute", left: "-9990px", bottom: 0 }}>
              {!isLeaderboardLoading && (
                <ReportTemplate
                  download={downloadReport}
                  setDownload={setDownloadReport}
                  leaderboard={leaderboard}
                  metrics={metrics}
                  challenge={challenge}
                  participants={participants}
                />
              )}
            </div>

            <Participants
              setIsTableLoading={setIsLeaderboardLoading}
              isTableLoading={isLeaderboardLoading}
              setLeaderboard={setLeaderboard}
              challenge={challenge}
              totalParticipants={challenge?.total_participants}
              viewList={viewList}
              setViewList={setViewList}
            />
          </Card>
        </ContainerItem>
      </Container>
      <Dialog
        isOpen={deleteChallengeModal}
        bottomActions={
          <Container padding="0" align="center">
            <Button
              label="Cancel"
              styles={{
                width: "auto",
                textAlign: "center",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={async () => {
                setDeleteChallengeModal(false);
              }}
            />

            <Button
              isLoading={isDeletingChallenge}
              label="Delete"
              styles={{ width: "auto", textAlign: "center" }}
              variant="contained"
              onClick={async () => {
                await handleDeleteChallenge(challenge.challenge_id);
              }}
            />
          </Container>
        }
      >
        <Container justify="space-around" padding="16px 0" margin="auto">
          <ContainerItem>
            <Text
              content={"Are you sure you want to delete the challenge?"}
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: palette.text.black,
              }}
            />
          </ContainerItem>

          <ContainerItem padding="8px">
            <Text
              content={
                isFutureChallenge
                  ? "This challenge is not started yet"
                  : "This challenge is actively running. If you delete this challenge, it will be disappear from the mobile app."
              }
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: palette.error.main,
                textAlign: "center",
              }}
            />
          </ContainerItem>
        </Container>
      </Dialog>
    </>
  );
};

export default ChallengeDetails;
