import { DialogActions } from "@mui/material";
import * as React from "react";

export const DialogFooter = (props) => {
  return (
    <DialogActions
      sx={{ padding: "0", textAlign: "center" }}
      data-cy="dialog-footer"
    >
      {props.children}
    </DialogActions>
  );
};
