import React from "react";
import { Container, ContainerItem, Text } from "../../../../components";
import ResultItem from "./resultItem";
import { palette } from "../../../../theme";
import { Box } from "@mui/material";

const PollResult = ({ result, totalVotes }) => {
  return (
    <Container padding="0" width="100%">
      <ContainerItem padding="8px 16px 0 16px">
        <Text
          content="Results:"
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            color: palette.text.black,
          }}
        />
      </ContainerItem>
      <Box
        sx={{
          padding: "16px 16px 0 16px",
          width: "100%",
          height: "240px",
          overflowY: "scroll",
          // overflowX: "hidden",`
        }}
      >
        {result.map((item, index) => {
          return <ResultItem row={item} index={index} key={item.label} />;
        })}
      </Box>
      <ContainerItem margin="0 0 16px 0" padding="8px 16px 0 16px">
        <Text
          content={`${totalVotes} votes | Final Results`}
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            color: palette.text.lightGray,
          }}
        />
      </ContainerItem>
    </Container>
  );
};

export default PollResult;
