import React from "react";
import Tabbedpage from "../../components/tabbed-page";
import CreateChallenge from "./tabs/create-challenge";
import ManageChallenge from "./tabs/manage-challenges";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSelectedChallange } from "./tabs/manage-challenges/manageChallengesSlice";

const Challenges = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  let editableChallenge = useSelector(selectSelectedChallange);
  const isEditing = Boolean(editableChallenge);

  const tabs = [
    {
      title: `${isEditing ? "Edit" : "Create a"} Challenge`,
      id: "create",
      component: CreateChallenge,
    },
    { title: "Manage Challenges", id: "manage", component: ManageChallenge },
  ];
  return (
    <div>
      <Tabbedpage
        activeTab={tab === "create" || tab === null ? 0 : 1}
        tabs={tabs}
      />
    </div>
  );
};

export default Challenges;
