import { Box } from "@mui/material";
import React from "react";
import { palette } from "../../theme";
import DashboardCard from "./dashboardCard";
import { apiEndpoints } from "../../services/api";
import { useState, useEffect } from "react";
import { api } from "../../services/api";
import { Loader } from "../../components";
import { useSelector } from "react-redux";
import {
  selectIsBrandAdmin,
  selectCurrentBrand,
} from "../../redux/auth/authSlice";

const Dashboard = () => {
  const isBrandAdmin = useSelector(selectIsBrandAdmin);
  const currentBrand = useSelector(selectCurrentBrand);
  const [allCounts, setAllCounts] = useState({
    challenge_count: 0,
    users_count: 0,
    gym_cards: 0,
    peleton_count: 0,
    mindbody_count: 0,
    apple_count: 0,
    fitbit_count: 0,
    not_participated_count: 0,
    iwatch_count: 0,
    android_count: 0,
    partially_onboarded_users: 0,
  });
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);

  var Tiles = [
    {
      title: "Challenges",
      bgColor: "rgba(83, 10, 234, 0.24)",
      textColor: palette.text.white,
      margin: "0 78px 60px 10px",
      to: "/challenges?tab=manage",
      top: "30%",
      value: allCounts.challenge_count,
    },
    {
      title: "Users in FitLvl",
      bgColor: "rgba(255, 59, 144, 0.24)",
      textColor: palette.text.white,
      margin: "0 78px 60px 0",
      to: "/manage-users",
      top: "30%",
      value: allCounts.users_count,
    },
    {
      title: "Gym Cards",
      bgColor: "rgba(196, 34, 236, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      // to: "/posts",
      top: "30%",
      value: allCounts.gym_cards,
    },
    {
      title: "Peleton Users",
      bgColor: "rgba(255, 167, 184, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=peleton",
      top: "30%",
      value: allCounts.peleton_count,
    },
    {
      title: "Mindbody Users",
      bgColor: "rgba(255, 177, 104, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=mindbody",
      top: "40%",
      value: allCounts.mindbody_count,
    },
    {
      title: "Apple Devices",
      bgColor: "rgba(255, 225, 67, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=apple",
      top: "40%",
      value: allCounts.apple_count,
    },
    {
      title: "Apple Watch Devices",
      bgColor: "rgba(255, 59, 144, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      top: "40%",
      value: allCounts.iwatch_count,
    },
    {
      title: "Fitbit Devices",
      bgColor: "rgba(184, 236, 73, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=fitbit",
      top: "40%",
      value: allCounts.fitbit_count,
    },
    {
      title: "Android Devices",
      bgColor: "rgba(255, 177, 104, 0.24)",

      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=google",
      top: "40%",
      value: allCounts.android_count,
    },
    {
      title: "User Never \n Participated in Challenges",
      bgColor: "rgba(42, 207, 138, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=not_participated",
      top: "40%",
      value: allCounts.not_participated_count,
    },
    {
      title: "Partially Onboarded Users",
      bgColor: "rgba(255, 59, 144, 0.24)",
      textColor: palette.text.black,
      margin: "0 78px 60px 0",
      to: "/manage-users?account_type=partially_onboarded",
      top: "40%",
      value: allCounts.partially_onboarded_users,
    },
  ];
  if (isBrandAdmin) {
    const ignore_tiles = [
      "Gym Cards",
      "User Never \n Participated in Challenges",
      "Partially Onboarded Users",
    ];
    Tiles = Tiles.filter((tile) => !ignore_tiles.includes(tile.title));
  }

  const fetchData = async () => {
    setIsDashboardLoading((state) => !state);
    if (isBrandAdmin && currentBrand) {
      var response = await api.get(
        apiEndpoints.getallCounts + `?group_id=${currentBrand}`
      );
    } else {
      var response = await api.get(apiEndpoints.getallCounts);
    }

    setAllCounts(response);
    setIsDashboardLoading((state) => !state);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isDashboardLoading) {
    return <Loader />;
  }
  return (
    <>
      <h1 style={{ top: "106px", left: "262px" }}>Home</h1>
      <Box
        sx={{
          width: "100%",
          mt: "40px",
          display: "flex",
          flexWrap: "wrap",
          gap: "24px",
          padding: "0px",
        }}
      >
        {Tiles.map((tile, index) => {
          return <DashboardCard key={index} {...tile} />;
        })}
      </Box>
    </>
  );
};

export default Dashboard;
