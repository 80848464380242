import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "../pages/login/loginSlice";
import userReducer from "./auth/authSlice";
import manageUsersReducer from "../pages/manage-users/manageUsersSlice";
import manageChallengeReducer from "../pages/challenges/tabs/manage-challenges/manageChallengesSlice";
import snackbarReducer from "../components/snackbar/snackbarSlice";
import postsReducer from "../pages/posts/postSlice";
import brandsReducer from "../pages/Brand/tabs/manage-brand/manageBrandSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    manageUsers: manageUsersReducer,
    manageChallenges: manageChallengeReducer,
    snackbar: snackbarReducer,
    posts: postsReducer,
    manageBrands: brandsReducer,
  },
  devTools: true,
});
