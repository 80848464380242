import React from "react";
import { Box } from "@mui/material";

const Label = ({
  label,
  labelColor,
  fontSize,
  fontWeight,
  required = false,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <span
        style={{
          textAlign: "left",
          fontWeight: fontWeight ?? 700,
          fontSize: fontSize ?? "16px ",
          color: labelColor ?? "black",
          marginBottom: "8px",
          width: "auto",
        }}
      >
        {label}
      </span>
      {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
    </Box>
  );
};

export default Label;
