import React from "react";
import Chart from "react-apexcharts";

const ApexChart = ({ type, data, categories, width, height, title }) => {
  const options = {
    chart: {
      id: "apex-Chat",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
      tickPlacement: "between",
      labels: {
        style: {
          padding: "0 4px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
          color: "#78909C",
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: "12px",
        colors: ["#000000"],
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    title: { text: title },
  };

  const series = [
    {
      data: data,
    },
  ];
  return (
    <Chart
      options={options}
      series={series}
      type={type}
      width={width ?? "500"}
      height={height ?? "auto"}
    />
  );
};

export default ApexChart;
