import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: null,
  access: null,
  accessExpiry: null,
  refresh: null,
  refreshExpiry: null,
  userType: null,
  isLoggedIn: false,
  openUpdatePasswordModal: false,
  currentBrand: null,
  isBrandAdmin: false,
};
const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.access = action.payload.access;
      state.accessExpiry = action.payload.accessExpiry;
      state.refresh = action.payload.refresh;
      state.refreshExpiry = action.payload.refreshExpiry;
      state.userName = action.payload.userName;
      state.userType = action.payload.userType;
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
    updateUser: (state, action) => {
      state = { ...state, ...action.payload };
    },
    setUpdatePasswordModal: (state, action) => {
      state.openUpdatePasswordModal = action.payload;
    },
    setCurrentBrand: (state, action) => {
      state.currentBrand = action.payload;
    },
    setIsBrandAdmin: (state, action) => {
      state.isBrandAdmin = action.payload;
    },
  },
});

export const {
  saveUser,
  updateUser,
  logout,
  setUpdatePasswordModal,
  setCurrentBrand,
  setIsBrandAdmin,
} = authSlice.actions;

export const selectAccessToken = (state) => state.user.access;
export const selectAccessExpiryDate = (state) => state.user.accessExpiry;
export const selectRefreshToken = (state) => state.user.refresh;
export const selectRefreshExpiryDate = (state) => state.user.refreshExpiry;
export const selectUserType = (state) => state.user.userType;
export const selectUserName = (state) => state.user.userName;
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectOpenUpdatePasswordModal = (state) =>
  state.user.openUpdatePasswordModal;
export const selectCurrentBrand = (state) => state.user.currentBrand;
export const selectIsBrandAdmin = (state) => state.user.isBrandAdmin;

export default authSlice.reducer;
