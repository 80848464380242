import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabNavigation from "./tabNavigation";
import TabContent from "./tabContent";
import { useSearchParams } from "react-router-dom";

const Tabbedpage = ({ tabs, activeTab, hideFirstTab }) => {
  const [currentTab, setCurrentTab] = useState(activeTab ?? 0);
  let [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const changeTab = (value) => {
    setCurrentTab(value);
    if (hideFirstTab) {
      setSearchParams("?tab=manage");
    } else {
      setSearchParams(value === 0 ? "?tab=create" : "?tab=manage");
    }
  };

  useEffect(() => {
    if (hideFirstTab) {
      changeTab(0);
    } else {
      changeTab(tab === "manage" ? 1 : 0);
    }
  }, [tab]);

  return (
    <div>
      <Box>
        <TabNavigation
          tabs={tabs}
          changeTab={changeTab}
          currentTab={currentTab}
        />
        <Box sx={{ m: "48px 0 16px 0" }}>
          <TabContent currentTab={currentTab} tabs={tabs} />
        </Box>
      </Box>
    </div>
  );
};

export default Tabbedpage;
