import { Box } from "@mui/material";
import React from "react";
import { palette } from "../../theme";
import { ReactComponent as FitlvlIcon } from "../../assets/leaderboard_fitlvl.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus_leaderboard.svg";
import { Text } from "../../components";
import moment from "moment";

const Header = ({ title, logo, startDate, endDate, groupName }) => {
  const getDateString = () => {
    let string = "";
    if (startDate && endDate) {
      const mStartDate = moment.utc(startDate);
      const mEndDate = moment.utc(endDate);

      console.log(mStartDate, mEndDate);

      if (mStartDate.month() === mEndDate.month()) {
        const startDateString = mStartDate.format("MMMM,DD").split(",");
        const endDateString = mEndDate.format("MMMM,DD").split(",");

        string = `${startDateString[0]} ${startDateString[1]} - ${endDateString[1]}`;
      } else {
        const startDateString = mStartDate.format("MMM,DD").split(",");
        const endDateString = mEndDate.format("MMM,DD").split(",");

        string = `${startDateString[0]} ${startDateString[1]} - ${endDateString[0]} ${endDateString[1]}`;
      }
    }
    return string;
  };

  const datestring = getDateString();
  return (
    <Box
      sx={{
        height: "75px",
        backgroundColor: palette.background.lime,
        padding: "4px 48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FitlvlIcon />
        <PlusIcon style={{ margin: "0 12px" }} />
        <img
          src={logo}
          alt={groupName}
          style={{
            width: "87px",
            height: "46px",
            objectFit: "contain",
          }}
        />
      </Box>
      <Text
        content={title}
        sx={{
          fontSize: "36px",
          fontWeight: 700,
          color: palette.text.black,
          fontFamily: "Work sans Bold",
        }}
      />
      <Text
        content={datestring}
        sx={{
          fontSize: "28px",
          fontWeight: 700,
          color: palette.primary.main,
          fontFamily: "Work sans Bold",
        }}
      />
    </Box>
  );
};

export default Header;
