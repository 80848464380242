import React from "react";
import { Box, Dialog as DialogContainer } from "@mui/material";
import DialogHeader from "./dialogHeader";
import DialogContent from "./dialogContent";
import { DialogFooter } from "./dialogFooter";

const Dialog = ({
  isOpen,
  handleClose,
  title,
  width,
  height,
  children,
  bottomActions,
  id,
  overflow: dialogOverflow,
}) => {
  const overflow = dialogOverflow ?? "auto";
  return (
    <DialogContainer
      open={isOpen}
      onClose={handleClose}
      sx={{
        ".MuiDialog-root": {
          width: width ?? "460px",
          maxWidth: width ?? "460px",
          overflow: overflow,
          height: height ?? "auto",
        },
        ".MuiDialog-paper": {
          width: width ?? "460px",
          maxWidth: width ?? "460px",
          overflow: overflow,
          height: height ?? "auto",
        },
      }}
    >
      <Box
        sx={{
          overflow: "auto",
          justifyContent: "space-between",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <DialogHeader width={width} title={title} handleClose={handleClose} />
        <DialogContent id={id}>{children}</DialogContent>
        <DialogFooter>
          <Box sx={{ margin: "16px auto", textAlign: "center" }}>
            {bottomActions}
          </Box>
        </DialogFooter>
      </Box>
    </DialogContainer>
  );
};

export default Dialog;
