import React from "react";
import { DialogContent as MuiDialogContent } from "@mui/material";

const DialogContent = (props) => {
  return (
    <MuiDialogContent
      id={props.id}
      sx={{
        overflow: "auto",
        padding: "0",
      }}
    >
      {props.children}
    </MuiDialogContent>
  );
};

export default DialogContent;
