import { Box } from "@mui/material";
import React from "react";
import { Avatar } from "../../../../../components";

const ParticipantCard = ({ name, profie_img, index }) => {
  return (
    <Box sx={{ marginLeft: index > 0 ? "-8px" : "0" }}>
      <Avatar content={name} profile_image={profie_img} />
    </Box>
  );
};

export default ParticipantCard;
