import React from "react";
import ContainerItem from "../common/containerItem";
import Text from "../common/text";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { palette } from "../../theme";

const NavItem = ({ navitem }) => {
  const navigate = useNavigate();
  const isActive = window.location.pathname.includes(navitem.id);
  return (
    <Box
      onClick={() => {
        if (navitem.target !== "_blank") {
          navigate(`${navitem.to}`);
        }
      }}
      sx={{
        backgroundColor: isActive
          ? palette.background.purple
          : palette.background.white,
        borderRadius: "30px",
        width: "180px",
        height: "44px",
        margin: "6px 0",
        paddingLeft: "8px",
        cursor: "pointer",
      }}
    >
      {navitem.target === "_blank" ? (
        <a
          href={navitem.to}
          rel="noreferrer"
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
            fontSize: "16px",
            padding: "11px 0 0 24px",
            paddingTop: "8px",
            color: isActive ? palette.text.white : palette.text.primary,
            textDecoration: "none",
          }}
          target="_blank"
        >
          {navitem.label}
        </a>
      ) : (
        <Text
          content={navitem.label}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
            padding: "11px 0 0 24px",
            color: isActive ? palette.text.white : palette.text.primary,
          }}
        />
      )}
    </Box>
  );
};

export default NavItem;
