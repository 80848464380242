import React, { useEffect } from "react";
import Tabbedpage from "../../components/tabbed-page";
// import ManageChallenge from "./tabs/manage-brand";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreateBrand from "./tabs/create-brand";
import ManageBrand from "./tabs/manage-brand";
import { useSelector } from "react-redux";
import { selectIsBrandAdmin } from "../../redux/auth/authSlice";

const Brands = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const isBrandAdmin = useSelector(selectIsBrandAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    if (isBrandAdmin && tab === "create") {
      navigate("/brand?tab=manage");
    }
  }, [isBrandAdmin]);

  const tabs = isBrandAdmin
    ? [{ title: "Manage Brands", id: "manage", component: ManageBrand }]
    : [
        { title: "Create a Brand", id: "create", component: CreateBrand },
        { title: "Manage Brands", id: "manage", component: ManageBrand },
      ];

  return (
    <div>
      <Tabbedpage
        hideFirstTab={isBrandAdmin}
        activeTab={isBrandAdmin ? 0 : tab === "create" || tab === null ? 0 : 1}
        tabs={tabs}
      />
    </div>
  );
};

export default Brands;
