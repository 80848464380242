import React from "react";
import { ContainerItem, Input, Select } from "../../../../components";
import {
  Autocomplete,
  TextField,
  createFilterOptions,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { palette } from "../../../../theme";

const timezonesOptions = [
  {
    label: "Eastern Standard Time: UTC -5:00/UTC -4:00",
    value: "America/New_York",
  },
  {
    label: "Central Standard Time: UTC -6:00/UTC -5:00",
    value: "America/Chicago",
  },
  {
    label: "Mountain Standard Time: UTC -7:00/UTC -6:00",
    value: "America/Denver",
  },
  {
    label: "Pacific Standard Time: UTC -8:00/UTC -7:00",
    value: "America/Los_Angeles",
  },
];

const Location = ({
  index,
  field,
  placeholder,
  fieldName,
  handleChange,
  handleBlur,
  values,
  locationSlugAutoCompleteOptions,
  locationSlugAutoCompleteOpen,
  fetchSlugs,
  setLocationSlugAutoCompleteOpen,
  errors,
  touched,
  currentIndex,
  setFieldValue,
  locationSlugOptionsLoading,
  slugOptions,
  setCurrentIndex,
  setLocationSlugOptionsLoading,
  setLocationSlugAutoCompleteOptions,
}) => {
  const filter = createFilterOptions();
  let timer = null;

  const debouncedFetchSlugs = (address) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fetchSlugs(address);
    }, 1000);
  };

  return (
    <ContainerItem width="100%" padding="8px 0 0 0">
      {field === "timezone" ? (
        <Select
          placeholder="Select Timezone here"
          name={fieldName}
          options={timezonesOptions}
          value={values["locations"][index]?.[field]}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          error={
            touched.locations?.[index]?.[field] && errors[fieldName]
              ? errors[fieldName]
              : null
          }
        />
      ) : field === "address" && values.integration_type !== "na" ? (
        <Tooltip
          title={
            values["locations"][index]?.address?.length > 33
              ? values["locations"][index]?.address
              : null
          }
          placement="right-start"
        >
          <Autocomplete
            id={index}
            name={fieldName}
            options={locationSlugAutoCompleteOptions ?? []}
            open={locationSlugAutoCompleteOpen && currentIndex === index}
            onOpen={() => {
              setLocationSlugAutoCompleteOpen(true);
              setCurrentIndex(index);
            }}
            onClose={() => {
              setLocationSlugAutoCompleteOpen(false);
            }}
            getOptionLabel={(option) => option.label ?? option}
            onChange={(event, newValue) => {
              let value = "";
              if (typeof newValue === "string") {
                value = newValue;
              } else if (newValue && newValue.value) {
                value = newValue.value;
              } else {
                value = newValue;
              }

              setFieldValue(`locations[${index}].address`, value);
              setFieldValue(
                `locations[${index}].location_slug`,
                slugOptions[value] ?? ""
              );
              if (value) {
                setLocationSlugAutoCompleteOptions((state) => {
                  const oldOptions = state;
                  oldOptions.push({
                    label: value,
                    value: value,
                  });
                  return oldOptions;
                });
              }
              setLocationSlugAutoCompleteOptions([]);
              // debounced api call to fetch slug - fetchSlugs(newValue.value, index, "location_slug")
              setLocationSlugAutoCompleteOpen(false);
            }}
            onInputChange={(event, newInputValue) => {
              debouncedFetchSlugs(newInputValue);
            }}
            freeSolo
            clearOnBlur
            selectOnFocus
            handleHomeEndKeys
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.label
              );
              if (inputValue && inputValue !== "" && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: `Add ${inputValue}`,
                });
              }

              return filtered;
            }}
            value={values["locations"][index]?.address}
            renderInput={(params) => (
              <TextField
                sx={{
                  "&:hover .MuiTextField-root": {
                    borderColor: palette.primary.main,
                  },
                  backgroundColor: palette.background.white,
                  color: "black",
                  width: "100%",
                  borderRadius: "10px",
                }}
                placeholder={"Select Address here"}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {currentIndex === index && locationSlugOptionsLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Tooltip>
      ) : (
        <Input
          placeholder={placeholder}
          name={fieldName}
          value={values["locations"][index]?.[field]}
          onChange={(e) => {
            handleChange(e);
            if (values.integration_type !== "na" && field === "name") {
              // setLocationSlugOptionsLoading((state) => true);
              setCurrentIndex((state) => index);
            }
          }}
          onBlur={handleBlur}
          error={
            touched.locations?.[index]?.[field] && errors[fieldName]
              ? errors[fieldName]
              : null
          }
        />
      )}
      {field === "location_slug" && values.locations[index].location_slug && (
        <div style={{ fontSize: "12px", margin: "8px 0 4px 0" }}>
          Is this booking url correct? {/* make this sentence short */}
          <a
            style={{ color: palette.primary.main }}
            href={`https://www.mindbodyonline.com/explore/locations/${values.locations[index].location_slug}`}
            target="_blank"
            rel="noreferrer"
          >{`https://www.mindbodyonline.com/explore/locations/${values.locations[index].location_slug}`}</a>
        </div>
      )}
    </ContainerItem>
  );
};

export default React.memo(Location);
