import React from "react";
import { Box, Drawer, Toolbar } from "@mui/material";

import { ReactComponent as FitlvlIcon } from "../../assets/fitlvl_logo.svg";
import NavList from "./navList";
import { navOptions } from "./navMenuOptions";
import { useNavigate } from "react-router-dom";
import Text from "../common/text";
import { capitalizeString } from "../../utils/utils";
import { useSelector } from "react-redux";
import { selectIsBrandAdmin, selectUserType } from "../../redux/auth/authSlice";

const Navbar = ({ drawerWidth }) => {
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const isBrandAdmin = useSelector(selectIsBrandAdmin);
  const env = process.env.REACT_APP_ENV;

  const announcementsIndex = navOptions.findIndex(
    (n) => n.id === "announcements"
  );

  if (isBrandAdmin && announcementsIndex > -1) {
    navOptions.splice(announcementsIndex, 1);
  }

  const drawer = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ color: "#000" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FitlvlIcon
            onClick={() => {
              navigate("/dashboard");
            }}
            style={{ cursor: "pointer" }}
          />
        </Toolbar>
        <Box>
          <NavList navItems={navOptions} />
        </Box>
      </Box>
      <Box sx={{ padding: "8px 16px", textAlign: "center" }}>
        <Text
          content={`Logged in as ${
            userType === "brand_admin" ? "Brand" : capitalizeString(userType)
          }`}
        />
        <Text
          sx={{ marginTop: "4px", marginLeft: "12px" }}
          content={`Server: ${env === "dev" ? "Dev" : "Prod"}`}
        />
      </Box>
    </Box>
  );
  return (
    <Box>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          color: "#000",
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Navbar;
