import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

import {
  Button,
  Container,
  ContainerItem,
  Input,
  Text,
} from "../../../components";
import ParticipantsTable from "./participantsTable";
import { api, apiEndpoints } from "../../../services/api";
import TabNavigation from "../../../components/tabbed-page/tabNavigation";
import AddParticipants from "./addParticipants";
import {
  capitalizeString,
  downloadAsCSVData,
  extractDate,
} from "../../../utils/utils";
import { palette } from "../../../theme";
import { convertObjectToString } from "../../../utils/utils";
import { fetchChallenge } from "../../challenges/tabs/manage-challenges/manageChallengesSlice";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../components/snackbar/snackbarTypes";

const Participants = ({
  challenge,
  totalParticipants,
  viewList,
  setViewList,
  setLeaderboard,
  isTableLoading,
  setIsTableLoading,
}) => {
  const [progressionData, setProgressionData] = useState({});
  const [totalCount, setTotalCount] = useState(totalParticipants);
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  const metrics = challenge.challenge_metrics.split(",");
  const isDoubleMetric = metrics.length > 1;

  const fetchProgression = async () => {
    let leaderboard_1 = null;
    let leaderboard_2 = null;
    const leaderboardMap = new Map();
    let result = [];
    let cols = [];

    const response = await api.get(
      apiEndpoints.getChallengeProgression(
        challenge.challenge_id,
        metrics[0],
        0,
        totalParticipants
      )
    );
    const { total_count, data: progression } = response;
    setTotalCount(total_count);
    leaderboard_1 = progression;

    if (isDoubleMetric) {
      const response = await api.get(
        apiEndpoints.getChallengeProgression(
          challenge.challenge_id,
          metrics[1],
          0,
          totalParticipants
        )
      );
      const { data: progression2 } = response;
      leaderboard_2 = progression2;
    }

    if (isDoubleMetric) {
      leaderboard_1.forEach((d) => {
        leaderboardMap.set(d.username, {
          user_id: d.user_id,
          username: d.username,
          [`${metrics[0]}_rank`]: d.rank,
          [`${metrics[0]}_score`]: d.user_score,
        });
      });

      leaderboard_2.forEach((d) => {
        leaderboardMap.set(d.username, {
          ...leaderboardMap.get(d.username),
          [`${metrics[1]}_rank`]: d.rank,
          [`${metrics[1]}_score`]: d.user_score,
        });

        cols = [
          { label: "Username", id: "username" },
          {
            label: capitalizeString(`${metrics[0]} score`),
            id: `${metrics[0]}_score`,
          },
          {
            label: capitalizeString(`${metrics[1]} score`),
            id: `${metrics[1]}_score`,
          },
        ];
      });

      result = Array.from(leaderboardMap.values());
    } else {
      const data = leaderboard_1.map((d) => {
        return {
          user_id: d.user_id,
          username: d.username,
          rank: d.rank,
          score: d.user_score,
          email: d.email,
        };
      });
      cols = [
        { label: "Username", id: "username" },
        { label: "Score", id: "score" },
      ];
      result = data;
    }

    setProgressionData(result);
    setColumns(cols);

    setLeaderboard({ rows: result, columns: cols });

    setIsTableLoading(false);
  };

  const deleteParticipant = async (username) => {
    try {
      await api.delete(apiEndpoints.manageParticipant, {
        challenge: challenge.challenge_id,
        target_user: username,
      });
      dispatch(
        showSnackbar({
          message: "Participant deleted successfully!",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      dispatch(
        fetchChallenge({ id: challenge.challenge_id, type: challenge.type })
      );
    } catch (e) {
      dispatch(
        showSnackbar({
          message: typeof e === "string" ? e : JSON.stringify(e),
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
      console.log(e);
    } finally {
    }
  };

  const handleExportCSV = async () => {
    downloadAsCSVData(
      progressionData,
      `${challenge.challenge_name} leaderboard`
    );
  };

  useEffect(() => {
    setTotalCount(totalParticipants);
  }, [totalParticipants]);

  useEffect(() => {
    // if (viewList || downloadReport) {
    fetchProgression();
    // }
  }, [challenge]);

  return (
    <>
      <Container padding="0" column>
        <ContainerItem padding="0px 0px 16px 0">
          <Text
            content="Challenge Participants"
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              color: palette.text.black,
            }}
          />
        </ContainerItem>
        <ContainerItem padding="8px 0">
          <AddParticipants
            challengeId={challenge.challenge_id}
            type={challenge?.type}
          />
        </ContainerItem>
        <ContainerItem padding="0 0 8px 0">
          <Input readOnly value={totalCount} label="Total Participants" />
        </ContainerItem>
        <ContainerItem flex align="center" padding="8px 0">
          <Text
            content="Participant List"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: palette.text.black,
              marginRight: "16px",
            }}
          />
          <Button
            label={viewList ? "Close List" : "View participants"}
            variant="outlined"
            onClick={() => {
              // setIsTableLoading(true);
              setViewList(!viewList);
            }}
          />
          {viewList && Object.keys(progressionData).length ? (
            <Button
              styles={{ marginLeft: "16px", fontWeight: 700, fontSize: "16px" }}
              variant="outlined"
              onClick={handleExportCSV}
              label="Export to CSV"
            />
          ) : null}
        </ContainerItem>
        {viewList ? (
          <ContainerItem padding="0">
            <ParticipantsTable
              metrics={metrics}
              tableColumns={columns}
              data={progressionData}
              deleteParticipant={deleteParticipant}
              totalCount={totalCount}
              isTableLoading={isTableLoading}
              fromDate={extractDate(new Date(challenge?.start_date))}
              toDate={extractDate(new Date(challenge?.end_date))}
              challengeName={challenge?.challenge_name}
            />
          </ContainerItem>
        ) : null}
      </Container>
    </>
  );
};

export default Participants;
