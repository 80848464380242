import React, { createContext, useState, useContext } from "react";
import { Snackbar as MuiSnackbar, Alert as MuiAlert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar, selectSnackbar } from "./snackbarSlice";

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(selectSnackbar);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearSnackbar());
  };
  
  return (
    <MuiSnackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={closeSnackbar}
      key={snackbar.message}
      message={snackbar.message}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert
        onClose={closeSnackbar}
        severity={snackbar.type}
        sx={{ width: "100%" }}
        elevation={6}
        variant="filled"
      >
        {snackbar.message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Snackbar;
