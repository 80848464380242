// Desc: This file contains all the api endpoints used in the application.
const apiEndpoints = {
  getUsersList: (account_type, groupId, searchTerm, offset = 0, limit = 10) => {
    let url = "/user/admin/usersList?";
    const params = [];

    if (account_type) {
      params.push(`account_type=${account_type}`);
    }
    if (groupId) {
      params.push(`group_id=${groupId}`);
    }

    if (searchTerm) {
      params.push(`search_key=${searchTerm}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    } else if (offset === 0) {
      params.push(`offset=${offset}`);
    }

    if (limit) {
      params.push(`limit=${limit}`);
    }

    return `${url}${params.join("&")}`;

    // url = `/user/admin/usersList?${account_type ? "":}`
    // if (account_type) {
    //   url = `${url}account_type=${account_type}`;
    // }
    // if (groupId) {
    //   url += `&group_id=${groupId}`;
    // }
    // return url;
  },
  getUserProfile: (id) => `/user/admin/userProfile?user_id=${id}`,
  getFitnessData: (
    id,
    fromDate,
    toDate,
    metric_type,
    offset,
    limit,
    groupBy,
    timezone
  ) => {
    let url = `/user-data/admin?user_id=${id}`;

    if (fromDate) url += `&from_date=${fromDate}`;
    if (toDate) url += `&to_date=${toDate}`;
    if (metric_type) url += `&metric_type=${metric_type}`;

    if (groupBy) {
      url += `&group_by=date&timezone=${timezone}`;
    } else {
      url += `&offset=${offset}&limit=${limit}`;
    }

    return url;
  },
  getCurrentChallenges: (id) =>
    id
      ? `challenges/admin?filter=current&group_id=${id}`
      : "challenges/admin?filter=current",
  manageChallege: (id) => `challenges/admin?challenge_id=${id}`,
  getPastChallenges: (id) =>
    id
      ? `challenges/admin?filter=completed&group_id=${id}`
      : "challenges/admin?filter=completed",
  getDeletedChallenges: (id) =>
    id
      ? `challenges/admin?filter=deleted&group_id=${id}`
      : "challenges/admin?filter=deleted",
  createChallenge: "challenges/",
  getChallengeProgression: (id, metric, offset, limit) =>
    `challenges/challenge-progression?challenge_id=${id}&metric_type=${metric}&include_total_count=true&offset=${offset}&limit=${limit}`,
  createAnnouncement: "/user/announcement",
  manageAnnouncements: (id) =>
    id ? `/user/admin/announcement?id=${id}` : "/user/admin/announcement",
  getallCounts: "user/admin/getAllCounts",
  manageParticipant: (id) =>
    id ? `challenges/participant?challenge_id=${id}` : "challenges/participant",
  checkUsername: (username) => `user/search/user?search_user=${username}`,
  managePosts: (id) => {
    if (id) return `user/admin/post?postId=${id}`;
    else return "user/admin/post";
  },
  deletePosts: (postId, feedId) =>
    `user/admin/post?postId=${postId}&feedId=${feedId}`,
  getPosts: (limit, offset, targetFeedId = null) => {
    let url = `user/admin/post?limit=${limit}&offset=${offset}`;
    if (targetFeedId) {
      url += `&target_feed_id=${targetFeedId}`;
    }
    return url;
  },
  pinPost: (id) =>
    id ? `user/admin/post/pinned?post_id=${id}` : `user/admin/post/pinned`,

  addGroup: "/groups/",
  editGroup: (id) => `/groups/?group_id=${id}`,
  getBrands: "/groups/permitted_groups?group_type=BRAND",
  getBrandLocations: (id) =>
    `/groups/permitted_groups?brand_id=${id}&group_type=LOCATION`,
  getBrandUsers: (id) => `/groups/users?group_id=${id}`,
  addUserToBrand: "/groups/join",
  deleteBrand: (id) => `/groups/?group_id=${id}`,
  campaignUsers: "/campaign/campaign-user",
  claims: "/campaign/claims",
  postMetaData: (postId, kind, id_lt) => {
    return `/user/post/metadata?postId=${postId}&kind=${kind}&limit=10${
      id_lt ? `&id_lt=${id_lt}` : ""
    }`;
  },
  deleteComment: (id) => `/user/post/metadata?reaction_id=${id}`,
  aliasCodeLookup: (code) =>
    `challenges/admin/aliasCodeLookup?alias_code=${code}`,
  createNotification: "/user/notifications",
  leaderboardChallenge: (id) => `/challenges/leaderboard?challenge_id=${id}`,
  igSettings: "/groups/ig",
  getLocationSlug: (address) => `/gym/gymSlugSuggestions?address=${address}`,
  igSync: (id) => `/groups/fetchInstagramPosts?group_id=${id}`,
  deleteUser: (email) => `/user/profile?target_user=${email}`,
  recalculateChallengeScore: (id) =>
    `/challenges/recalculateChallengeScore?challenge_id=${id}`,
  syncFitbit: (email) => `/user-data/fitbit/sync/user?target_user=${email}`,
  recalculateAllChallengeScore: (email) =>
    `/user/challenges/recalculate?target_user=${email}`,

  getGroupStores: (groupId) => `/groups/getGroupStores?group_id=${groupId}`,
  getStoreParticipants: (challenge_id, storeId) => {
    let url = `/groups/storeParticipants?challenge_id=${challenge_id}`;
    if (storeId) {
      url += `&store_id=${storeId}`;
    }
    return url;
  },
};

export default apiEndpoints;
