import React from "react";
import { Button, Container, ContainerItem, Text } from "../../../../components";
import { palette } from "../../../../theme";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentChallenges,
  selectDeletedChallenges,
  selectPastChallenges,
} from "../../../challenges/tabs/manage-challenges/manageChallengesSlice";

const ChallengePostCard = ({ challenge }) => {
  const navigate = useNavigate();
  const currentChallenges = useSelector(selectCurrentChallenges);
  const pastChallenges = useSelector(selectPastChallenges);
  const deletedChallenges = useSelector(selectDeletedChallenges);

  const type =
    [...currentChallenges, pastChallenges, deletedChallenges].find(
      (c) => c.challenge_id === challenge.challenge_id
    )?.type || "deleted";
  return (
    <Container width="100%" align="center">
      <ContainerItem height="auto" padding="0 4px 0 0" width="50%">
        <img
          style={{ width: "100%", height: "100%", borderRadius: "10px" }}
          src={challenge.challenge_image}
          alt={challenge.challenge_name}
        />
      </ContainerItem>
      <ContainerItem height="auto" padding="0 0 12px 4px" width="50%">
        <Text
          content={challenge.challenge_name}
          sx={{
            fontSize: "14px",
            fontWeight: "700",
            color: palette.text.gray,
            textAlign: "center",
          }}
        />
        <Text
          content={challenge.goal_description}
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            color: palette.text.gray,
            textAlign: "center",
            marginTop: "12px",
          }}
        />
        <Button
          label="View Challenge"
          onClick={() => {
            navigate(`/challenges/${challenge.challenge_id}`);
          }}
          variant="contained"
          styles={{
            backgroundColor: palette.background.intenseRed,
            color: palette.text.white,
            height: "40px",
            width: "132px",
            fontSize: "14px",
            fontWeight: 700,
            borderRadius: "6px",
            padding: "6px",
            margin: "12px 0 0 0",
          }}
        />
      </ContainerItem>
    </Container>
  );
};

export default ChallengePostCard;
