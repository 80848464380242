import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { api, apiEndpoints } from "../../services/api";
import {
  fetchBrands,
  selectBrands,
  setBrandSelected,
} from "../Brand/tabs/manage-brand/manageBrandSlice";
import { palette } from "../../theme";
import {
  Button,
  Card,
  Container,
  ContainerItem,
  Dialog,
  Divider,
  Input,
  Label,
  Loader,
  Select,
  Table,
  Text,
} from "../../components";
import { ReactComponent as EditIcon } from "../../assets/edit_icon_white.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash_icon_white.svg";
import { ReactComponent as PlaceholderImage } from "../../assets/placeholderImage.svg";
import { showSnackbar } from "../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../components/snackbar/snackbarTypes";
import LocationForm from "./locationForm";
import FacebookIcon from "@mui/icons-material/Facebook";
import {
  capitalizeString,
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../utils/utils";

const BrandDetails = () => {
  let { brandId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const canDeleteOrEdit = true;

  const [brand, setBrand] = useState();
  const [brandOptions, setBrandOptions] = useState([]);
  const [brandLocations, setBrandLocations] = useState([]);
  const [addUserInput, setAddUserInput] = useState();
  const [brandUsers, setBrandUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [errorAdding, setErrorAdding] = useState(false);
  const [isDeletingBrand, setIsDeletingBrand] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showAddLocationForm, setShowAddLocationForm] = useState(false);
  const [brandFacebookAuthData, setBrandFacebookAuthData] = useState();
  const [locationSlugs, setLocationSlugs] = useState([]);
  const [locationAddresses, setLocationAddresses] = useState([]);
  const brands = useSelector(selectBrands);

  const fetchBrandLocations = useCallback(async (id) => {
    const locations = await api.get(apiEndpoints.getBrandLocations(id));
    setBrandLocations(locations);
    if (brand?.is_gym) {
      setLocationSlugs(locations.map((l) => l?.meta_data?.location_slug));
    }
    setLocationSlugs(locations.map((l) => l?.meta_data?.location_slug));
    setLocationAddresses(locations.map((l) => l?.meta_data?.address));
  }, []);

  const fetchGroupUsers = useCallback(async (id) => {
    const users = await api.get(apiEndpoints.getBrandUsers(id));
    setBrandUsers(users);
  }, []);

  const fetchData = async (id) => {
    setIsLoading((state) => !state);
    await fetchBrandLocations(id);
    await fetchGroupUsers(id);
    setIsLoading((state) => !state);
  };

  const handleBrandChange = (e) => {
    navigate(`/brand/${e.target.value}`);
  };

  const userTableColumns = [
    {
      label: "Name",
      id: "name",
      format: (row) => `${row.first_name} ${row.last_name}`,
    },
    { label: "Username", id: "username" },
  ];

  const addUserToBrand = async (user) => {
    setIsAddingUser((state) => !state);
    try {
      await api.post(apiEndpoints.addUserToBrand, {
        group_id: brandId,
        target_user: user,
      });
      await fetchGroupUsers(brandId);
      dispatch(
        showSnackbar({
          message: "User Added to Brand successfully",
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      setAddUserInput("");
    } catch (e) {
      setErrorAdding("Invalid username or email");
    }
    setIsAddingUser((state) => !state);
  };

  const handleDeleteBrand = async (id, type) => {
    try {
      await api.delete(apiEndpoints.deleteBrand(id));
      dispatch(
        showSnackbar({
          message: `${
            type === "BRAND" ? "Brand" : "Location"
          } Deleted Successfully`,
          type: SNACKBAR_TYPES.SNACKBAR_SUCCESS,
        })
      );
      navigate("/brand?tab=manage");
      dispatch(fetchBrands());
    } catch (e) {
      dispatch(
        showSnackbar({
          message: typeof e === "string" ? e : JSON.stringify(e),
          type: SNACKBAR_TYPES.SNACKBAR_ERROR,
        })
      );
    }
  };

  const handleAction = (type) => {
    dispatch(
      setBrandSelected({
        ...brand,
        locations: brandLocations,
        users: brandUsers,
      })
    );
    if (type === "edit") {
      navigate("/brand?tab=create");
    } else if (type === "viewChallenges") {
      navigate("/challenges?tab=manage");
    }
  };

  useEffect(() => {
    const options = [];
    brands.forEach((b) => {
      if (b.group_id === brandId) {
        setBrand(b);
      }
      options.push({ label: b.group_name, value: b.group_id });
    });
    setBrandOptions(options);
    fetchData(brandId);

    const fbauthData = getFromLocalStorage(`${brandId}_FBAuthData`);
    setBrandFacebookAuthData(fbauthData);
  }, [brandId]);

  return (
    <>
      <Container padding="0 0 40px 0" margin="16px 0 0 0" column>
        <ContainerItem width="100%" padding="0" margin="0 0 24px 0">
          <Container
            padding="0"
            width="990px"
            align="center"
            justify="flex-end"
          >
            <ContainerItem padding="0 24px 0 0">
              <Text
                content="Find a different Brand"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                }}
              />
            </ContainerItem>
            <ContainerItem padding="0" width="345px">
              <Select
                placeholder="Select"
                options={brandOptions}
                onChange={handleBrandChange}
                value={brandId}
                name="brand"
              />
            </ContainerItem>
          </Container>
        </ContainerItem>
        <ContainerItem padding="0">
          <Card
            title={brand?.group_name}
            headerColor={palette.background.blue}
            titleColor={palette.text.white}
            actions={
              <Container padding="8px 24px">
                <ContainerItem padding="8px 24px">
                  <Box
                    onClick={() => {
                      handleAction("viewChallenges");
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Text
                      content="View Challenges"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: palette.text.white,
                        textDecoration: "underline",
                      }}
                    />
                  </Box>
                </ContainerItem>
                <ContainerItem textAlign="center" padding="0 24px 0 0">
                  <Box
                    sx={{
                      cursor: "pointer",
                      color: palette.text.white,
                    }}
                    onClick={() => {
                      //
                    }}
                  >
                    <FacebookIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                    <Text
                      content="facebook "
                      sx={{
                        marginTop: "-2px",
                        fontSize: "12px",
                        fontWeight: "400",
                        color: palette.text.white,
                      }}
                    />
                  </Box>
                </ContainerItem>
                <ContainerItem textAlign="center" padding="0">
                  <Box
                    sx={{ cursor: canDeleteOrEdit ? "pointer" : "not-allowed" }}
                    onClick={() => {
                      if (canDeleteOrEdit) {
                        handleAction("edit");
                      }
                    }}
                  >
                    <EditIcon />
                    <Text
                      content="edit"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: palette.text.white,
                      }}
                    />
                  </Box>
                </ContainerItem>
                <ContainerItem textAlign="center" padding="0 0 0 24px">
                  <Box
                    sx={{ cursor: canDeleteOrEdit ? "pointer" : "not-allowed" }}
                    onClick={() => {
                      if (canDeleteOrEdit) {
                        setOpenDeleteModal(true);
                      }
                    }}
                  >
                    <TrashIcon />
                    <Text
                      content="delete"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: palette.text.white,
                      }}
                    />
                  </Box>
                </ContainerItem>
              </Container>
            }
          >
            <Container width="100%" padding="8px 0">
              <Container width="345px" padding="0" column>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="group_name"
                    label="Brand Name"
                    value={brand?.group_name}
                    readOnly
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="contact_name"
                    label="Brand Primary Contact Name"
                    value={brand?.meta_data?.["contact_name"]}
                    readOnly
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="contact_email"
                    label="Brand Primary Contact Email"
                    value={brand?.group_email}
                    readOnly
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="contact_number"
                    label="Brand Primary Contact Number"
                    value={brand?.group_contact}
                    readOnly
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="website"
                    label="Brand Website"
                    value={brand?.meta_data?.["website_url"]}
                    readOnly
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="app_link"
                    label="App Link"
                    value={brand?.app_link}
                    readOnly
                  />
                </ContainerItem>
                <ContainerItem width="100%" padding="8px 0">
                  <Input
                    name="integration_type"
                    label="Gym Software Provider"
                    value={capitalizeString(
                      brand?.meta_data?.["integration_type"]
                    )}
                    readOnly
                  />
                </ContainerItem>
              </Container>
              <Container padding="0 0 0 120px" width="345px">
                <ContainerItem width="100%" padding="0" margin="8px 0 0 0">
                  <Container padding="0" align="center" justify="space-between">
                    <ContainerItem padding="0">
                      <Label label={"Logo"} />
                    </ContainerItem>
                  </Container>
                  <Container width="330px" height="242px" margin=" 12px 0 0 0">
                    {brand?.group_logo ? (
                      <img
                        src={brand?.group_logo}
                        alt="Preview"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                          maxHeight: "300px",
                        }}
                      />
                    ) : (
                      <PlaceholderImage
                        style={{
                          backgroundColor: palette.background.white,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Container>
                </ContainerItem>
              </Container>
              <Divider color={palette.border.black} margin="24px 0" />
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <Container column width="100%" padding="0">
                    <ContainerItem padding="0" width="50%" flex>
                      <Text
                        content="Location Details"
                        sx={{
                          fontWeight: 600,
                          fontSize: "20px",
                          color: palette.text.black,
                          marginBottom: "8px",
                        }}
                      />
                      <AddCircleOutlineOutlinedIcon
                        style={{
                          cursor: "pointer",
                          marginTop: "2px",
                          marginLeft: "24px",
                          color: palette.text.black,
                        }}
                        onClick={() => {
                          setShowAddLocationForm(true);
                        }}
                      />
                    </ContainerItem>
                    <ContainerItem padding="8px 0">
                      <Input
                        name="noOfLocations"
                        label="Number of Brand Locations"
                        value={brandLocations.length}
                        readOnly
                      />
                    </ContainerItem>
                    {showAddLocationForm && (
                      <ContainerItem width="100%" padding="8px 0">
                        <LocationForm
                          isGym={brand.is_gym}
                          brandName={brand.group_name}
                          fetchLocations={() => {
                            fetchBrandLocations(brandId);
                          }}
                          formType="add"
                          onCancel={() => {
                            setShowAddLocationForm(false);
                          }}
                          brandId={brand.group_id}
                          otherLocationSlugs={locationSlugs}
                          otherLocationAddresses={locationAddresses}
                        />
                      </ContainerItem>
                    )}
                    {brandLocations.map((l, i) => {
                      return (
                        <LocationForm
                          isGym={brand.is_gym}
                          handleDeleteGroup={handleDeleteBrand}
                          brandName={brand.group_name}
                          index={i + 1}
                          location={l}
                          key={l.group_id}
                          fetchLocations={() => {
                            fetchBrandLocations(brandId);
                          }}
                          otherLocationSlugs={
                            brand.is_gym
                              ? locationSlugs.filter(
                                  (ls) => ls !== l.meta_data?.location_slug
                                )
                              : null
                          }
                          otherLocationAddresses={locationAddresses.filter(
                            (la) => la !== l?.meta_data?.address
                          )}
                          formType="edit"
                        />
                      );
                    })}
                  </Container>
                  <Container
                    column
                    width="100%"
                    margin="40px 0 0 0"
                    padding="0"
                  >
                    <Text
                      content="Users Affiliated With This Brand"
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: palette.text.black,
                      }}
                    />
                    <ContainerItem
                      flex
                      align="center"
                      width="445px"
                      padding="16px 0"
                    >
                      <Input
                        name="addUser"
                        label="Add a user to this brand"
                        labelFontWeight={400}
                        value={addUserInput}
                        onChange={(e) => {
                          setErrorAdding("");
                          setAddUserInput(e.target.value);
                        }}
                        error={errorAdding}
                      />
                      <Button
                        isLoading={isAddingUser}
                        disabled={!addUserInput?.length}
                        label="Add to Brand"
                        variant="outlined"
                        styles={{
                          marginLeft: "24px",
                          width: "160px",
                          marginTop: "28px",
                        }}
                        onClick={() => {
                          addUserToBrand(addUserInput);
                        }}
                      />
                    </ContainerItem>
                    <Box sx={{ mt: "24px" }} />
                    <ContainerItem padding="8px 0">
                      <Input
                        name="totalUsers"
                        label="Total Users Affiliated With This Brand"
                        value={brandUsers.length ?? 0}
                        readOnly
                      />
                    </ContainerItem>
                  </Container>
                  <Container padding="8px 0 0 0">
                    <ContainerItem
                      flex
                      padding="0"
                      align="center"
                      width="345px"
                      justify="between"
                    >
                      <Text
                        content="Users List"
                        sx={{
                          fontWeight: 700,
                          fontSize: "16px",
                          color: palette.text.black,
                        }}
                      />
                      <Button
                        label={showUsers ? "Close List" : "View Users"}
                        variant="outlined"
                        onClick={() => {
                          setShowUsers(!showUsers);
                        }}
                      />
                    </ContainerItem>
                    {showUsers && (
                      <ContainerItem padding="0">
                        <Table
                          rows={brandUsers}
                          pageCount={10}
                          columns={userTableColumns}
                          noSort
                        />
                      </ContainerItem>
                    )}
                  </Container>
                </>
              )}
            </Container>
          </Card>
        </ContainerItem>
      </Container>
      <Dialog
        isOpen={openDeleteModal}
        bottomActions={
          <Container padding="0" align="center">
            <Button
              label="Cancel"
              styles={{
                width: "auto",
                textAlign: "center",
                marginRight: "16px",
              }}
              variant="outlined"
              onClick={async () => {
                setOpenDeleteModal(false);
              }}
            />

            <Button
              isLoading={isDeletingBrand}
              label="Delete"
              styles={{ width: "auto", textAlign: "center" }}
              variant="contained"
              onClick={async () => {
                setIsDeletingBrand((state) => !state);

                await handleDeleteBrand(brand.group_id, "BRAND");
                setIsDeletingBrand((state) => !state);
              }}
            />
          </Container>
        }
      >
        <Container justify="space-around" padding="16px 0" margin="auto">
          <ContainerItem>
            <Text
              content={"Are you sure you want to delete this brand?"}
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: palette.text.black,
              }}
            />
          </ContainerItem>

          <ContainerItem padding="0">
            <Text
              content={
                "By deleting this brand, all users who are currently following it will no longer follow the brand. Additionally, users will no longer have access to view your challenges or community posts associated with this brand."
              }
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: palette.error.main,
                textAlign: "center",
                padding: "0 8px",
              }}
            />
          </ContainerItem>
        </Container>
      </Dialog>
    </>
  );
};

export default React.memo(BrandDetails);
