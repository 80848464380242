import { Box } from "@mui/material";
import React from "react";
import Switch from "react-switch";
import Label from "./label";
import Text from "./text";
import { capitalizeString } from "../../utils/utils";
import { palette } from "../../theme";

const ToggleButton = ({
  required,
  name,
  label,
  onChange,
  checked,
  disabled,
  readOnly,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: readOnly ? "column" : "row",
        alignItems: readOnly ? "flex-start" : "center",
        cursor: disabled ? "not-allowed" : "default",
      }}
    >
      {label ? <Label label={label} required={required} /> : null}
      {readOnly ? (
        <Text
          content={checked ? "Yes" : "No"}
          sx={{ fontSize: "16px", fontWeight: 400, color: palette.text.black }}
        />
      ) : (
        <div style={{ marginLeft: "16px" }}>
          <Switch
            disabled={disabled}
            name={name}
            onColor={palette.background.green}
            offColor={palette.background.grey2}
            onChange={onChange}
            checked={checked}
          />
        </div>
      )}
    </Box>
  );
};

export default ToggleButton;
