import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Text from "../common/text";

const Actions = ({ options, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell sx={{ width: "auto" }}>
        <MoreVertIcon sx={{ cursor: "pointer" }} onClick={handleClick} />
      </TableCell>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {options.map((o, index) => {
          let disable = false;
          if (o.disable) {
            disable = o.disable(row);
          }
          return (
            <MenuItem
              disabled={disable}
              sx={{
                cursor: disable ? "not-allowed" : "pointer",
              }}
              key={index}
              onClick={() => {
                if (disable) return;
                o.onClick(row);
                handleClose();
              }}
            >
              {o.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const TableBody = ({
  rows,
  onClick,
  page,
  rowsPerPage,
  columns,
  order,
  orderBy,
  noSort,
  clickable,
  menuOptions,
}) => {
  if (!rows.length) {
    return <Text content="No data found." sx={{ width: "200px" }} />;
  }

  if (!noSort) {
    rows = rows.sort((a, b) => {
      if (order === "asc") {
        if (typeof a[orderBy] === "number") {
          return a[orderBy] - b[orderBy];
        }
        return a[orderBy]?.localeCompare(b[orderBy]);
      } else {
        if (typeof b[orderBy] === "number") {
          return b[orderBy] - a[orderBy];
        }
        return b[orderBy]?.localeCompare(a[orderBy]);
      }
    });
  }

  return (
    <MuiTableBody>
      {rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <TableRow
            key={row.username}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              cursor: clickable ? "pointer" : "default",
            }}
          >
            {columns.map((column, columnIndex) => {
              return (
                <TableCell
                  onClick={() => {
                    if (clickable) {
                      onClick(row);
                    }
                  }}
                  key={columnIndex}
                  sx={{ width: "30%" }}
                  align="left"
                >
                  {column.format ? column.format(row) : row[column.id]}
                </TableCell>
              );
            })}

            {menuOptions && (
              <Actions options={menuOptions} row={row} key={row.username} />
            )}
          </TableRow>
        ))}
    </MuiTableBody>
  );
};

export default React.memo(TableBody);
