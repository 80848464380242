import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { selectBrands } from "./manageBrandSlice";
import {
  Card,
  Container,
  ContainerItem,
  Input,
  Select,
  Text,
} from "../../../../components";
import { palette } from "../../../../theme";
import { ReactComponent as PlaceholderImage } from "../../../../assets/placeholderChallengeBanner.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const FILTERS = {
  GYM: "gym",
  NON_GYM: "nonGym",
  ALL: "all",
  MINDBODY: "mindbody",
  MARIANATEK: "marianatek",
};

const Brand = ({ brand }) => {
  const [borderRadius, setBorderRadius] = useState(0);

  const imageRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (imageRef.current) {
      const imgElement = imageRef.current;
      imgElement.onload = () => {
        const image = imageRef.current;
        if (image) {
          const imageWidth = image.clientWidth;
          const imageHeight = image.clientHeight;
          const radius = imageWidth > 100 && imageHeight > 90 ? "10px" : 0;

          setBorderRadius(radius);
        }
      };
    }
  }, [imageRef.current]);

  return (
    <Box
      key={brand.group_id}
      sx={{
        width: "280px",
        height: "97px",
        borderRadius: "10px",
        margin: "0 16px 16px 0",
        backgroundColor: palette.background.white,
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(`/brand/${brand.group_id}`);
      }}
    >
      <Container height="100%" padding="0">
        <ContainerItem
          flex
          align="center"
          padding="0"
          width="112px"
          height="97px"
          justify="center"
        >
          {brand.group_logo ? (
            <img
              ref={imageRef}
              id={brand.group_name}
              key={brand.group_id}
              loading="lazy"
              src={brand.group_logo}
              alt={brand.group_name}
              style={{
                maxWidth: "112px",
                maxHeight: "97px",
                objectFit: "cover",
                borderTopLeftRadius: borderRadius,
                borderBottomLeftRadius: borderRadius,
              }}
            />
          ) : (
            <PlaceholderImage
              style={{
                width: "112px",
                height: "auto",
                margin: "10px 0",
                borderRadius: "10px",
                textAlign: "center",
              }}
            />
          )}
        </ContainerItem>
        <ContainerItem flex padding="0 8px" column width="150px">
          <ContainerItem padding="20px 0">
            <Text
              content={brand.group_name}
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: palette.text.black,
              }}
            />
          </ContainerItem>
          <ContainerItem padding=" 0">
            <Text
              content={`Created: ${moment(brand.created_at).format(
                "MMM D, YYYY"
              )}`}
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                color: palette.text.black,
              }}
            />
          </ContainerItem>
        </ContainerItem>
      </Container>
    </Box>
  );
};

const ManageBrand = () => {
  const brands = useSelector(selectBrands);
  const [filteredBrands, setFilteredBrands] = useState(brands);
  const [filter, setFilter] = useState(FILTERS.ALL);
  const [searchString, setSearchString] = useState("");

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    const filterFunctions = {
      [FILTERS.ALL]: () => true,
      [FILTERS.GYM]: (b) => b.is_gym,
      [FILTERS.NON_GYM]: (b) => !b.is_gym,
      [FILTERS.MARIANATEK]: (b) =>
        b.meta_data?.integration_type === FILTERS.MARIANATEK,
      [FILTERS.MINDBODY]: (b) =>
        b.meta_data?.integration_type === FILTERS.MINDBODY,
    };

    const filterBrands = (brands, filter) => {
      const filterFunction = filterFunctions[filter];
      return brands.filter(filterFunction);
    };

    const fBrands = filterBrands(brands, filter);

    setFilteredBrands(fBrands);
  }, [filter]);

  return (
    <>
      <Container
        padding="0 0 16px 0"
        width="990px"
        align="center"
        justify="flex-end"
      >
        <ContainerItem padding="0" margin=" 0 16px 0 0">
          <Input
            name="searchString"
            placeholder="Search..."
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value.toLowerCase());
            }}
          />
        </ContainerItem>
        <ContainerItem padding="0 24px 0 0">
          <Text
            content="Brand Type"
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              whiteSpace: "nowrap",
            }}
          />
        </ContainerItem>
        <ContainerItem padding="0" width="160px">
          <Select
            placeholder="Select"
            options={[
              { label: "All", value: FILTERS.ALL },
              { label: "Gym", value: FILTERS.GYM },
              { label: "Non Gym", value: FILTERS.NON_GYM },
              { label: "Mariana Tek", value: FILTERS.MARIANATEK },
              { label: "Mindbody", value: FILTERS.MINDBODY },
            ]}
            onChange={onChange}
            value={filter}
            name="brandType"
          />
        </ContainerItem>
      </Container>
      <Card
        title="Brands"
        headerColor={palette.primary.main}
        contentPadding="20px 20px 20px 60px"
        titleColor={palette.text.white}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {filteredBrands
            .filter((b) =>
              b.group_name.toLowerCase().includes(searchString.toLowerCase())
            )
            .map((brand) => {
              return <Brand key={brand.group_id} brand={brand} />;
            })}
        </Box>
      </Card>
    </>
  );
};

export default ManageBrand;
