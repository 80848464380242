import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Box, Tooltip } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";

import {
  Container,
  ContainerItem,
  Card,
  Select,
  Input,
  ToggleButton,
  Label,
  InputDate,
  Hint,
  Button,
  Divider,
  Text,
} from "../../../../components";
import { palette } from "../../../../theme";
import { ReactComponent as PlaceholderImage } from "../../../../assets/placeholderImage.svg";
import {
  getUTCDate,
  getAspectRatioOfImageFromImageFileOrImageUrl,
} from "../../../../utils/utils";
import BrandSelection from "../../../common/brandSelection";
import { showSnackbar } from "../../../../components/snackbar/snackbarSlice";
import { SNACKBAR_TYPES } from "../../../../components/snackbar/snackbarTypes";
import { api, apiEndpoints } from "../../../../services/api";

const CHARACTERLIMIT = 160;

export const Step1Body = React.memo(
  ({
    setSelectedBannerImage,
    previewBannerImage,
    setPreviewBannerImage,
    handleStep1Data,
    setIsStep1Valid,
    challenge,
    readOnly = false,
    isEditing,
    globalEdit,
    clearLocations,
    setClearLocations,
    isWeeklyChallenge,
  }) => {
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const hiddenFileInput = React.useRef(null);
    const options = [{ label: "Leaderboard", value: "leader_board" }];
    const now_utc = getUTCDate();
    const [privateChallenge, setPrivateChallenge] = useState(false);
    const [initialValues, setInitialValues] = useState({
      brand: "",
      selectAllLocations: true,
      locations: "",
      challenge_type: "",
      challenge_name: "",
      start_date: "",
      end_date: "",
      default_challenge: false,
      is_private: false,
      goal_description: "",
      banner: null,
      challenge_referral_link: "",
      challenge_referral_alias_code: "",
    });
    const [isEditingBanner, setIsEditingBanner] = useState(false);
    const [canEditStartDate, setCanEditStartDate] = useState(false);
    const [aliasCode, setAliasCode] = useState("");
    const [isAliasCodeValid, setIsAliasCodeValid] = useState(false);
    const [startDate, setStartDate] = useState();
    const [aspectRatio, setAspectRatio] = useState();

    const handleBannerImageUpload = (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile && selectedFile.type.includes("image")) {
        setSelectedBannerImage(selectedFile);
        getAspectRatioOfImageFromImageFileOrImageUrl(
          selectedFile,
          function (ar) {
            setAspectRatio(ar);
            handleStep1Data({ aspect_ratio: ar });
          }
        );
        // setAspectRatio(asRatio);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewBannerImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
        setIsEditingBanner(true);
      } else {
        setSelectedBannerImage(null);
        setPreviewBannerImage(null);
        console.error("Invalid file selected. Please choose an image file.");
      }
    };

    const handleChangeOverrided = (handleChange, e) => {
      handleChange(e);
      handleStep1Data({
        [e.target.name]: e.target.value.trim(),
      });
    };

    useEffect(() => {
      if (challenge && Object.keys(challenge).length) {
        const challengeType =
          challenge?.challenge_metadata[
            Object.keys(challenge?.challenge_metadata)[0]
          ].challenge_type;

        const start_date = challenge?.start_date?.slice(0, 10);
        const end_date = challenge?.end_date?.slice(0, 10);

        setInitialValues({
          challenge_type: challengeType,
          challenge_name: challenge?.challenge_name,
          start_date: start_date,
          end_date: end_date,
          default_challenge: challenge.default_challenge ?? false,
          is_private: challenge.is_private ?? false,
          goal_description: challenge?.goal_description,
          banner: challenge.challenge_image,
          selectAllLocations: true,
          brand: isEditing ? challenge?.challenge_name : "",
          challenge_referral_code: challenge.challenge_referral_code,
          challenge_referral_link: challenge?.challenge_referral_link,
          challenge_referral_alias_code:
            challenge?.challenge_referral_alias_code,
        });

        setPrivateChallenge(challenge?.is_private);

        if (start_date && new Date(start_date) > new Date()) {
          setCanEditStartDate(true);
        }
        if (!readOnly)
          getAspectRatioOfImageFromImageFileOrImageUrl(
            challenge.challenge_image,
            function (asRatio) {
              setAspectRatio(asRatio);
              handleStep1Data({ aspect_ratio: asRatio });
            }
          );

        setIsAliasCodeValid(true);

        if (!readOnly) {
          formRef.current?.validateForm();
        }
      }
    }, [challenge]);

    useEffect(() => {
      const delay = 500; // Adjust the delay time as needed
      let flag = true;
      if (isEditing) {
        flag = flag && aliasCode !== challenge?.challenge_referral_alias_code;
      }
      const timerId = setTimeout(() => {
        if (aliasCode && flag) {
          api.get(apiEndpoints.aliasCodeLookup(aliasCode)).then((data) => {
            if (data.message) {
              formRef.current.setFieldError(
                "challenge_referral_alias_code",
                "Alias not available"
              );
              setIsAliasCodeValid(false);
              setIsStep1Valid(false);
            } else {
              setIsAliasCodeValid(true);
            }
            formRef.current.validateForm();
          });
        }
      }, delay);

      if (aliasCode === challenge?.challenge_referral_alias_code) {
        setIsAliasCodeValid(true);
        formRef.current.validateForm();
      }

      return () => {
        clearTimeout(timerId);
      };
    }, [aliasCode]);

    useEffect(() => {
      if (isWeeklyChallenge && startDate) {
        let end_date = new Date(startDate);
        end_date.setDate(startDate.getDate() + 27);
        formRef.current.setFieldValue("end_date", end_date);
        handleStep1Data({ end_date: end_date });
      }
    }, [isWeeklyChallenge, startDate]);

    return (
      <Formik
        validateOnMount={isEditing}
        validateOnBlur
        validateOnChange
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.challenge_type) {
            errors.challenge_type = "This field is required";
          }
          if (!values.challenge_name) {
            errors.challenge_name = "This field is required";
          }
          if (!values.start_date) {
            errors.start_date = "This field is required";
          }
          if (!values.end_date) {
            errors.end_date = "This field is required";
          }
          if (!values.goal_description) {
            errors.goal_description = "This field is required";
          }
          if (!values.banner) {
            errors.banner = "This field is required";
          }

          if (!values.brand) {
            errors.brand = "This field is required";
          }

          if (!values.selectAllLocations && !values.locations) {
            errors.locations = "This field is required";
          }

          if (isEditing && !values.challenge_referral_alias_code) {
            errors.challenge_referral_alias_code = "This field is required";
          }

          if (values?.challenge_referral_alias_code?.length > 20) {
            errors.challenge_referral_alias_code =
              "Alias cannot be greater than 20 characters";
          }

          if (!isAliasCodeValid && values?.challenge_referral_alias_code) {
            errors.challenge_referral_alias_code = "Alias not available";
          }

          if (Object.keys(errors).length) {
            setIsStep1Valid(false);
          } else {
            setIsStep1Valid(true);
          }

          return errors;
        }}
        onSubmit={(values, errors, { setSubmitting }) => {}}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container column width="100%">
              {!isEditing && !readOnly && (
                <Container padding="0" width="100%">
                  <BrandSelection
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={(name, value) => {
                      setFieldValue(name, value);
                      handleStep1Data({ [name]: value });
                    }}
                    handleChange={(e) => {
                      handleChangeOverrided(handleChange, e);
                    }}
                    handleBlur={handleBlur}
                    readOnly={readOnly}
                    clearLocations={clearLocations}
                    setClearLocations={setClearLocations}
                  />
                </Container>
              )}
              <Container padding="8px 0">
                <Container width="345px" padding="0" column>
                  <ContainerItem width="100%" padding="8px 0">
                    <Select
                      required
                      readOnly={readOnly || isEditing}
                      label="Select a challenge mode"
                      name="challenge_type"
                      options={options}
                      labelColor={palette.text.black}
                      onChange={(e) => {
                        handleChangeOverrided(handleChange, e);
                      }}
                      onBlur={handleBlur}
                      value={values.challenge_type}
                      error={
                        touched.challenge_type && errors.challenge_type
                          ? errors.challenge_type
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      required
                      readOnly={readOnly}
                      name="challenge_name"
                      label="Give this challenge a title "
                      type="text"
                      onChange={(e) => {
                        handleChangeOverrided(handleChange, e);
                      }}
                      onBlur={handleBlur}
                      value={values.challenge_name}
                      labelColor={palette.text.black}
                      error={
                        touched.challenge_name && errors.challenge_name
                          ? errors.challenge_name
                          : null
                      }
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    {!readOnly && (
                      <>
                        <Label label="Select a date range" />
                        <Hint
                          hintStyles={{
                            display: "block",
                            color: palette.text.hotPink,
                            margin: "8px 0 24px 0",
                          }}
                          hint="NOTE: Date cannot be changed after challenge starts"
                        />
                      </>
                    )}
                    <Box sx={{ margin: "8px 0 16px 0" }}>
                      <InputDate
                        required
                        label="Start Date"
                        labelStyles={{
                          fontWeight: readOnly || isEditing ? "700" : "400",
                          fontSize: "16px",
                        }}
                        readOnly={isEditing ? !canEditStartDate : readOnly}
                        name="start_date"
                        value={values.start_date}
                        minDate={now_utc}
                        maxDate={values.end_date}
                        handleDate={(date) => {
                          setFieldValue("start_date", date);
                          setStartDate(date);
                          handleStep1Data({ start_date: date });
                        }}
                        error={
                          touched.start_date && errors.start_date
                            ? errors.start_date
                            : null
                        }
                      />
                    </Box>
                    <Box>
                      <InputDate
                        required
                        label="End Date"
                        labelStyles={{
                          fontWeight: readOnly || isEditing ? "700" : "400",
                          fontSize: "16px",
                        }}
                        readOnly={
                          isWeeklyChallenge && isEditing ? true : readOnly
                        }
                        name="end_date"
                        value={values.end_date}
                        minDate={
                          isEditing
                            ? now_utc
                            : values.start_date !== ""
                            ? values.start_date
                            : now_utc
                        }
                        handleDate={(date) => {
                          setFieldValue("end_date", date);
                          handleStep1Data({ end_date: date });
                        }}
                        error={
                          touched.end_date && errors.end_date
                            ? errors.end_date
                            : null
                        }
                      />
                    </Box>
                  </ContainerItem>
                  <ContainerItem padding="12px 0 8px 0">
                    <ToggleButton
                      readOnly={
                        readOnly
                          ? readOnly
                          : isEditing
                          ? !globalEdit
                          : isEditing
                      }
                      name="is_private"
                      checked={privateChallenge}
                      onChange={(value) => {
                        setPrivateChallenge(value);
                        setFieldValue("is_private", value);
                        handleStep1Data({ is_private: value });
                      }}
                      label="Set challenge to private? "
                    />
                  </ContainerItem>
                  <ContainerItem padding="12px 0 8px 0">
                    <ToggleButton
                      readOnly={
                        readOnly
                          ? readOnly
                          : isEditing
                          ? !globalEdit
                          : isEditing
                      }
                      disabled={privateChallenge}
                      name="default_challenge"
                      checked={
                        privateChallenge ? false : values.default_challenge
                      }
                      onChange={(value) => {
                        setFieldValue("default_challenge", value);
                        handleStep1Data({ default_challenge: value });
                      }}
                      label="Set challenge to auto join?  "
                    />
                  </ContainerItem>
                  {readOnly && values.challenge_referral_link && (
                    <ContainerItem
                      width="100%"
                      padding="8px 0"
                      flex
                      align="center"
                    >
                      <Input
                        readOnly={readOnly}
                        name="challenge_referral_link"
                        label="Challenge Referral Link"
                        type="text"
                        value={values.challenge_referral_link}
                        labelColor={palette.text.black}
                      />
                      <Tooltip title="Copy Link" placement="top-end">
                        <ContentCopyIcon
                          sx={{
                            marginTop: "28px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              values.challenge_referral_link
                            );
                            dispatch(
                              showSnackbar({
                                message: "Link copied",
                                type: SNACKBAR_TYPES.SNACKBAR_INFO,
                              })
                            );
                          }}
                        />
                      </Tooltip>
                    </ContainerItem>
                  )}
                </Container>
                <Container padding="0 0 0 120px" width="465px">
                  {readOnly && (
                    <ContainerItem width="100%" padding="8px 0">
                      <Input
                        readOnly={readOnly}
                        name="challenge_referral_code"
                        label="Challenge Referral Code"
                        type="text"
                        value={values.challenge_referral_code}
                        labelColor={palette.text.black}
                      />
                    </ContainerItem>
                  )}
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      readOnly={readOnly}
                      name="challenge_referral_alias_code"
                      label="Alias for Challenge Referral Code "
                      type="text"
                      onChange={(e) => {
                        setAliasCode(e.target.value.trim());
                        e.target.value = e.target.value.trim().toUpperCase();
                        handleChangeOverrided(handleChange, e);
                      }}
                      onBlur={handleBlur}
                      value={values.challenge_referral_alias_code}
                      labelColor={palette.text.black}
                      error={
                        touched.challenge_referral_alias_code &&
                        (errors.challenge_referral_alias_code ||
                          isAliasCodeValid)
                          ? errors.challenge_referral_alias_code
                          : null
                      }
                      sx={{
                        ".MuiOutlinedInput-root": {
                          textTransform: "uppercase",
                        },
                      }}
                    />
                  </ContainerItem>
                  <ContainerItem width="100%" padding="8px 0">
                    <Input
                      required
                      readOnly={readOnly}
                      name="goal_description"
                      label="Give this challenge a description "
                      type="text"
                      multiline
                      minRows={6}
                      labelColor={palette.text.black}
                      hint="NOTE: Character count limited to 160"
                      hintStyles={{ color: palette.text.purple }}
                      value={values.goal_description}
                      onChange={(e) => {
                        handleChangeOverrided(handleChange, e);
                      }}
                      characterLimit={CHARACTERLIMIT}
                      onBlur={handleBlur}
                      error={
                        touched.goal_description && errors.goal_description
                          ? errors.goal_description
                          : null
                      }
                    />
                  </ContainerItem>

                  <ContainerItem width="100%" padding="0" margin="8px 0 0 0">
                    <Container
                      padding="0"
                      align="center"
                      justify="space-between"
                      width="320px"
                    >
                      <ContainerItem padding="0" flex column>
                        <Label
                          required
                          label={readOnly ? "Photo" : "Add a photo"}
                        />
                        {!readOnly && (
                          <Hint
                            hint=".png .webp .jpg .jpeg are supported"
                            hintStyles={{ color: palette.text.purple }}
                          />
                        )}
                      </ContainerItem>
                      {!readOnly ? (
                        <ContainerItem padding="0">
                          <Button
                            label="Upload Photo"
                            variant="contained"
                            onClick={() => {
                              hiddenFileInput.current.click();
                            }}
                          />
                          <input
                            name="banner"
                            type="file"
                            accept=".png, .jpg, .webp, .jpeg"
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleBannerImageUpload(e);
                              setFieldValue("banner", e.target.files[0].name);
                            }}
                          />
                        </ContainerItem>
                      ) : null}
                    </Container>
                    <Container
                      width="330px"
                      height="242px"
                      margin=" 12px 0 0 0"
                    >
                      {previewBannerImage || challenge?.challenge_image ? (
                        <img
                          src={
                            isEditingBanner
                              ? previewBannerImage
                              : challenge
                              ? challenge?.challenge_image
                              : previewBannerImage
                          }
                          alt="Preview"
                          style={{
                            width: "345px",
                            height: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                            borderRadius: "10px",
                          }}
                        />
                      ) : (
                        <PlaceholderImage
                          style={{
                            backgroundColor: palette.background.white,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                            cursor: readOnly ? "default" : "pointer",
                          }}
                          onClick={() => {
                            if (!readOnly) {
                              hiddenFileInput.current.click();
                            }
                          }}
                        />
                      )}
                    </Container>
                  </ContainerItem>
                </Container>
              </Container>
            </Container>
          </form>
        )}
      </Formik>
    );
  }
);

const Step1 = (props) => {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const onUpload = (e) => {
    const file = e.target.files?.[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (ev) => {
      try {
        const jsonData = JSON.parse(ev.target.result);
        props.onImport(jsonData);
      } catch (e) {
        dispatch(
          showSnackbar({
            message: "Invalid JSON File",
            type: SNACKBAR_TYPES.SNACKBAR_ERROR,
          })
        );
      }
      // setFiles(e.target.result);
    };
  };
  return (
    <Card
      headerColor={palette.background.purple}
      title="Primary Details"
      titleColor={palette.text.white}
      actions={
        !props.isEditing && (
          <Box
            sx={{
              cursor: "pointer",
              color: palette.text.black,
              textAlign: "center",
              mx: "16px",
            }}
            onClick={() => {
              hiddenFileInput.current.click();
            }}
          >
            <FileUploadOutlinedIcon
              sx={{ fontSize: "24px", color: palette.text.white }}
            />
            <Text
              content="Import Challenge"
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: palette.text.white,
                marginTop: "-6px",
              }}
            />
            <input
              name="banner"
              type="file"
              accept=".json"
              ref={hiddenFileInput}
              style={{ display: "none" }}
              onChange={onUpload}
            />
          </Box>
        )
      }
    >
      <Step1Body {...props} />
    </Card>
  );
};

export default React.memo(Step1);
