import { Box, Avatar as MuiAvatar } from "@mui/material";
import React, { useState } from "react";
import Text from "./text";
import { palette } from "../../theme";

const Avatar = ({ content = " ", profile_image, noBorder, sx, bgcolor }) => {
  const [imageError, setImageError] = useState(false);

  const stringToColor = (string) => {
    if (bgcolor) {
      return bgcolor;
    }
    if (string === " ") return palette.background.lightGrey;
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  return (
    // <>
    //   {profile_image && !imageError ? (
    //     <img
    //       src={profile_image}
    //       alt={content}
    //       style={{
    //         width: "32px",
    //         margin: "auto",
    //         height: "32px",
    //         borderRadius: "50%",
    //         border: "3px solid white",
    //         boxSizing: "border-box",
    //       }}
    //       onError={handleImageError}
    //     />
    //   ) : (
    //     <Box
    //       sx={{
    //         backgroundColor: "#a700e2",
    //         border: "3px solid white",
    //         borderRadius: "50%",
    //         width: "32px",
    //         margin: "auto",
    //         height: "32px",
    //         textAlign: "center",
    //         // pt: "1px",
    //         // pb: "1px",
    //         textTransform: "uppercase",
    //         boxSizing: "border-box",
    //       }}
    //     >
    //       <Text
    //         content={letter}
    //         sx={{ color: "white", padding: "4px 0 0 9px" }}
    //       />
    //     </Box>
    //   )}
    // </>
    <MuiAvatar
      sx={{
        bgcolor: stringToColor(content),
        width: "28px",
        height: "28px",
        margin: "auto",
        border: noBorder ? "none" : "2px solid white",
        fontSize: "14px",
        ...sx,
      }}
      src={profile_image}
      alt={content}
    >
      {content[0].toUpperCase()}
    </MuiAvatar>
  );
};

export default Avatar;
