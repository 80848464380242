import React from "react";

const FirebaseAnalytics = () => {
  return (
    <iframe
      title="Analytics"
      width="800"
      height="800"
      src="https://lookerstudio.google.com/embed/u/0/reporting/6c61460b-fff0-4ad9-aa3d-942b037324b8/page/p_bb7fsdf96c"
      frameborder="0"
      style={{ border: 0 }}
      allowfullscreen
    ></iframe>
  );
};

export default FirebaseAnalytics;
