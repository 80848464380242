import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Text } from "../../components";

const DashboardCard = ({
  title,
  value,
  bgColor,
  margin,
  textColor,
  top,
  to,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        whiteSpace: "pre-line",
        width: "266.5px",
        height: "180px",
        borderRadius: "8px",
        padding: "16px 24px",
        textAlign: "left",
        backgroundColor: bgColor,
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(to);
      }}
    >
      <Text
        content={title}
        sx={{
          width: "218px",
          fontWeight: 600,
          fontSize: "20px",
          color: "#231F20",
          margin: "12px 0px",
          padding: "0 0",
          position: "relative",
          top: "16px",
          textAlign: "left",
          left: "24px",
        }}
      />
      <Text
        content={value.toString()}
        sx={{
          width: "23px",
          fontWeight: 500,
          fontSize: "48px",
          color: "#231F20",
          margin: "12px 0px",
          padding: "0 0",
          position: "relative",
          top:
            title == "User Never \n Participated in Challenges" ? "20%" : "30%",
          textAlign: "left",
          left: "24px",
        }}
      />
    </Box>
  );
};

export default DashboardCard;
