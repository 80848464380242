import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { palette } from "../../theme";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";
import { getAuth, applyActionCode } from "firebase/auth";
import fire from "../../services/firebase";

import { Card, Text, Loader } from "../../components";

const VerifyEmail = ({ apiKey, oobCode }) => {
  const [verificationResult, setVerificationResult] = useState();

  useEffect(() => {
    document.title = "FitLvl - Verify Email";
    verifyEmail(oobCode);
  }, [oobCode]);

  const verifyEmail = async (oobCode) => {
    let error = false;
    if (!oobCode) error = true;
    // if (apiKey && apiKey !== process.env.REACT_APP_FIREBASE_API_KEY) {
    //   error = true;
    // }

    console.log(error, "error");

    if (error) {
      setVerificationResult({
        message: "Invalid verification link",
      });
      return;
    }

    const auth = getAuth(fire);

    try {
      await applyActionCode(auth, oobCode);
      setVerificationResult({
        message: "Email verified successfully",
      });
    } catch (error) {
      console.log(error);
      if (error.code === "auth/expired-action-code") {
        setVerificationResult({
          message: "The verification link has expired",
        });
      } else if (error.code === "auth/invalid-action-code") {
        setVerificationResult({
          message: "The verification link is invalid",
        });
      } else if (error.code === "auth/user-disabled") {
        setVerificationResult({
          message: "The user is disabled",
        });
      } else if (error.code === "auth/user-not-found") {
        setVerificationResult({
          message: "The user was not found",
        });
      } else {
        setVerificationResult({
          message: "An error occurred while verifying the email",
        });
      }
    }
  };

  return (
    <Box
      sx={{
        width: "340px",
        marginTop: "40px",
      }}
    >
      <Card width="100%" contentPadding="20px">
        {verificationResult ? (
          <Box
            sx={{
              height: "400px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {verificationResult.message === "Email verified successfully" ? (
              <VerifiedIcon
                sx={{
                  color: "green",
                  fontSize: "100px",
                }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "red",
                  fontSize: "100px",
                }}
              />
            )}

            <Text
              content={
                verificationResult.message === "Email verified successfully"
                  ? "Success"
                  : "Error"
              }
              sx={{
                fontSize: "36px",
                color: palette.text.black,
                margin: "40px 0",
              }}
            />
            <Text
              content={verificationResult.message}
              sx={{
                fontSize: "24px",
                fontWeight: 600,
                textAlign: "center",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              height: "400px",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box>
              <Loader />
            </Box>
            <Text
              content="Please wait while we verify your email..."
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                textAlign: "center",
                marginTop: "24px",
              }}
            />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default VerifyEmail;
